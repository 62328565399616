import { defaultAnimation } from '../data/defaults/animations.types.defaults'
import { LinearEaseStyle } from '../data/styles/ease.styles'
import { translateX, translateY } from '../data/styles/groups/transform.styles'
import { createAnimation } from './creators.helpers'

export const animationCrawlStatic = (maskObject: AnySceneObjectT | undefined) => {
    let animation: AnimationI = createAnimation(maskObject?.id ?? '', 0, defaultAnimation)
    animation = {
        ...animation,
        duration: 0, // No duration needed for setting a static property
        tween: {
            name: 'setTranslateX',
            type: 'set',
            from: [
                {
                    ...translateX,
                    value: 0,
                },
            ],
            to: [],
            isDynamic: false,
        },
    }
    return animation
}

export const animationCrawlStaggered = (
    maskObject: AnySceneObjectT | undefined,
    crawlSpeed: number,
    fromValue: number,
    toValue: number,
    slide?: 'slideToRight' | 'slideToLeft'
): AnimationI => {
    let animation: AnimationI = createAnimation(
        maskObject ? maskObject.id : '',
        0,
        defaultAnimation
    )
    animation = {
        ...animation,
        duration: crawlSpeed,
        tween: {
            name: slide ?? 'slideToRight',
            type: 'fromTo',
            from: [
                {
                    ...translateX,
                    value: 0, //tickerWidth
                },
            ],
            to: [
                {
                    ...translateX,
                    value: 0, //-textsWidth
                },
            ],
            isDynamic: false,
        },
    }

    return animation
}

export const animationCrawlContinuous = (
    maskObject: AnySceneObjectT | undefined,
    crawlSpeed: number,
    fromValue: number,
    toValue: number,
    slide?: 'slideToRight' | 'slideToLeft'
): AnimationI => {
    let animation: AnimationI = createAnimation(
        maskObject ? maskObject.id : '',
        0,
        defaultAnimation
    )
    animation = {
        ...animation,
        duration: crawlSpeed,
        tween: {
            name: slide ?? 'slideToRight',
            type: 'fromTo',
            from: [
                {
                    ...translateX,
                    value: 0, //tickerWidth
                },
            ],
            to: [
                {
                    ...translateX,
                    value: 0, //-textsWidth,
                },
            ],
            isDynamic: false,
        },
    }

    return animation
}

export const animationScrollCopyDown = (
    maskObject: AnySceneObjectT | undefined,
    duration: number, //duration, scrollTime
    delay: number, //position
    fromValue: number,
    toValue: number
): AnimationI => {
    let animation: AnimationI = createAnimation(
        maskObject ? maskObject.id : '',
        0,
        defaultAnimation
    )
    animation = {
        ...animation,
        duration: duration,
        delay: delay,
        tween: {
            name: 'slideToBot',
            type: 'fromTo',
            from: [
                {
                    ...translateY,
                    value: fromValue, //tickerWidth
                    unit: '%',
                },
            ],
            to: [
                {
                    ...translateY,
                    value: toValue, //-textsWidth,
                    unit: '%',
                },
            ],
            isDynamic: false,
        },
    }

    return animation
}

export const animationScrollSet = (maskObject: AnySceneObjectT | undefined, value: number) => {
    let animation: AnimationI = createAnimation(maskObject?.id ?? '', 0, defaultAnimation)
    animation = {
        ...animation,
        duration: 0, // No duration needed for setting a static property
        delay: 0,
        tween: {
            name: 'setScrollTranslateY',
            type: 'set',
            from: [
                {
                    ...translateY,
                    unit: '%',
                    value: value,
                },
            ],
            to: [],
            isDynamic: false,
        },
    }
    return animation
}

export const animationScrollTranslateY = (
    maskObject: AnySceneObjectT | undefined,
    duration: number, //duration, scrollTime
    delay: number, //position
    fromValue: number,
    toValue: number
): AnimationI => {
    let animation: AnimationI = createAnimation(
        maskObject ? maskObject.id : '',
        0,
        defaultAnimation
    )

    animation = {
        ...animation,
        duration: duration,
        delay: delay,
        ease: LinearEaseStyle,
        tween: {
            name: 'setScrollTranslateY',
            type: 'fromTo',
            from: [
                {
                    ...translateY,
                    value: fromValue, //tickerWidth
                    unit: '%',
                },
            ],
            to: [
                {
                    ...translateY,
                    value: toValue, //-textsWidth,
                    unit: '%',
                },
            ],
            isDynamic: false,
        },
    }

    return animation
}
