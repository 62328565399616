import { PayloadAction } from '@reduxjs/toolkit'
import { updateObjectStyle } from '../../../../helpers/object.helpers'
import {
    recreateScrollDownAnimations,
    recreateScrollUpAnimations,
    recreateScrollUpDownAnimations,
} from '../../../../helpers/scrollTicker.helpers'
import { ObjectsStateT } from '../../objects.slice'
import { objectNodeUpdate } from '../object/objectNodeUpdate'
import { PayloadObjT } from '../object/objectTypes'

export const resizeScrollTickerStylesBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<PayloadObjT>
) => {
    const id = action.payload.id
    const widthValue = action.payload.value.find(
        (x: { property: string }) => x.property === 'width'
    ).value
    const heightValue = action.payload.value.find(
        (x: { property: string }) => x.property === 'height'
    ).value

    state.value = {
        ...state.value,
        [id]: objectNodeUpdate(state.value[id], action.payload),
    }
    const object = state.value[id] as TickerI
    const maskObject = object.childIds[0] && (state.value[object.childIds[0]] as TickerI)

    if (maskObject) {
        const textObjects = maskObject.childIds.map((id) => state.value[id] as TextI)
        state.value = {
            ...state.value,
            [maskObject.id]: {
                ...state.value[maskObject.id],
                styles:
                    updateObjectStyle(state.value[maskObject.id], 'width', widthValue) ??
                    state.value[maskObject.id].styles,
            },
        }
        state.value = {
            ...state.value,
            [maskObject.id]: {
                ...state.value[maskObject.id],
                styles:
                    updateObjectStyle(
                        state.value[maskObject.id],
                        'height',
                        maskObject.tickerType !== 'scroll-up-down'
                            ? heightValue * maskObject.childIds.length
                            : heightValue
                    ) ?? state.value[maskObject.id].styles,
            },
        }
        textObjects.forEach((textObject, index) => {
            state.value = {
                ...state.value,
                [textObject.id]: {
                    ...state.value[textObject.id],
                    styles:
                        updateObjectStyle(state.value[textObject.id], 'height', heightValue) ??
                        state.value[textObject.id].styles,
                },
            }
            state.value = {
                ...state.value,
                [textObject.id]: {
                    ...state.value[textObject.id],
                    styles:
                        updateObjectStyle(state.value[textObject.id], 'width', widthValue) ??
                        state.value[textObject.id].styles,
                },
            }
            state.value = {
                ...state.value,
                [textObject.id]: {
                    ...state.value[textObject.id],
                    styles:
                        (object.tickerType === 'scroll-up-down'
                            ? updateObjectStyle(state.value[textObject.id], 'top', heightValue)
                            : updateObjectStyle(
                                  state.value[textObject.id],
                                  'top',
                                  heightValue * index
                              )) ?? state.value[textObject.id].styles,
                },
            }
        })

        const animations: Record<string, AnimationI> = {}

        if (object.tickerType === 'scroll-up') {
            recreateScrollUpAnimations(state.value[maskObject.id] as TickerI).forEach(
                (animation) => {
                    animations[animation.id] = animation
                }
            )
        }
        if (object.tickerType === 'scroll-down') {
            recreateScrollDownAnimations(state.value[maskObject.id] as TickerI).forEach(
                (animation) => {
                    animations[animation.id] = animation
                }
            )
        }
        if (object.tickerType === 'scroll-up-down') {
            recreateScrollUpDownAnimations(state.value[maskObject.id] as TickerI, state)
        }
        if (object.tickerType !== 'scroll-up-down') {
            state.value = {
                ...state.value,
                [maskObject.id]: {
                    ...state.value[maskObject.id],
                    animations: animations,
                },
            }
        }
    }
    state.updatedAt = Date.now()
}
