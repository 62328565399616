import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import gsap from 'gsap'
import GSDevTools from 'gsap/GSDevTools'
import { set } from 'lodash'
import * as tweenly from 'tweenly'
import { Asset } from 'tweenly/dist/fetchAsset'
import { imageSrc } from '../../data/styles/simple/display.styles'
import useDynamicRefs from '../../hooks/useDynamicRefs'
import { AppThunkT } from '../store'

import { RefObject } from 'react'
import { sumArray } from '../../helpers/array.helpers'
import {
    CRAWL_CONTINUES_DURATION,
    CRAWL_STAGGERED_DURATION,
} from '../../helpers/crawlTicker.helpers'
import { roundNumber } from '../../helpers/number.helpers'
import { EMPTY_IMAGE_SRC, preloadTween, processTween } from '../../helpers/object.helpers'
import { selectUndoable } from '../../helpers/selector.helpers'
import { selectAllAnimations } from './objects.slice/animation/selectAllAnimations'
import { initSubTimelineAction } from './subTimelines.slice'
gsap.registerPlugin(GSDevTools)

type StateT = {
    value: any | null
    time: number
}

const initialState: StateT = {
    value: null,
    time: 0,
}

type PayloadT = {
    gsapTimeline: GSAPTimeline | null
}

export const masterTimelineSlice = createSlice({
    name: 'masterTimeline',
    initialState,
    reducers: {
        updMasterTimelineAction: (state: StateT, action: PayloadAction<PayloadT>) => {
            state.value = action.payload.gsapTimeline
        },
        setTimeToSeek: (state: StateT, action: PayloadAction<number>) => {
            state.time = action.payload
        },
    },
})

const serializeAnimationStateForPreview = (animationStates: SimpleStyleT[]): GSAPTweenVars => {
    const result: GSAPTweenVars = {}
    animationStates.forEach((animationState) => {
        set(
            result,
            animationState.property,
            animationState.unit === '%'
                ? animationState.value + animationState.unit
                : animationState.value
        )
    })
    return result
}

const serializeEaseForPreview = (ease: EaseT): string => {
    let result: string = ''
    if (ease) {
        result = ease.easeStyle + '.' + ease.easeType
        const strength = ease.easeStrengthValues.find((s) => s.strength === ease.easeStrength)
        if (strength) result += strength.value
    }
    return result
}

const initTweenForPreview = (
    animation: AnimationI,
    getRef: (key: string) => RefObject<unknown> | undefined
): GSAPTween => {
    let gsapTween: GSAPTween = gsap.to({}, {})
    // create animation
    if (animation.tween.type === 'fromTo')
        gsapTween = gsap.fromTo(
            getRef(animation.objectId)?.current as GSAPTweenTarget,
            // @ts-ignore
            serializeAnimationStateForPreview(animation.tween.from),
            {
                ...serializeAnimationStateForPreview(animation.tween.to),
                duration: animation.duration,
                ease: serializeEaseForPreview(animation.ease),
            },
            animation.delay
        )
    else if (animation.tween.type === 'from')
        gsapTween = gsap.from(
            getRef(animation.objectId)?.current as GSAPTweenTarget,
            // @ts-ignore
            {
                ...serializeAnimationStateForPreview(animation.tween.from),
                duration: animation.duration,
                ease: serializeEaseForPreview(animation.ease),
            },
            animation.delay
        )
    else if (animation.tween.type === 'to')
        gsapTween = gsap.to(
            getRef(animation.objectId)?.current as GSAPTweenTarget,
            // @ts-ignore
            {
                ...serializeAnimationStateForPreview(animation.tween.to),
                duration: animation.duration,
                ease: serializeEaseForPreview(animation.ease),
            },
            animation.delay
        )
    else if (animation.tween.type === 'set') {
        let from = animation.tween.from
        if (animation.tween.name === 'sequence') {
            from = processTween(animation.tween.from)
            // Preload all images.
            preloadTween(from)
        }
        gsapTween = gsap.set(
            getRef(animation.objectId)?.current as GSAPTweenTarget,
            serializeAnimationStateForPreview(from)
        )
    }

    // if animation is repeating, set repeats
    if (animation.repeat > 0) gsapTween.repeat(animation.repeat)
    return gsapTween
}

//to do objects, animations and timeline drill as props
export const updateMasterTimeline =
    (timeToSeek: number, refreshAllStyles?: () => void): AppThunkT =>
    async (dispatch, getState) => {
        const { objects } = selectUndoable(getState())
        const animations = selectAllAnimations(Object.values(objects.value))
        const { timeline, assets } = getState()
        const subTimelines = getState().subTimelines.value

        const [getRef] = useDynamicRefs()

        if (objects.value === null || Object.keys(objects.value).length === 0) {
            dispatch(updMasterTimelineAction({ gsapTimeline: null }))
            return null
        }

        const newMasterTimeline: GSAPTimeline = gsap.timeline({
            paused: true,
            repeat: Number(timeline.value.repeat),
            id: 'master',
        })

        const zeroAnimation = animations.find(
            (a) => a && a.delay === 0 && a.tween.name === 'sequence'
        )

        //set empty image src for active object on position 0 on timeline when zeroAnimation not exist
        const selectedIds = getState().activeObject.selected
        if (!zeroAnimation) {
            selectedIds.forEach((id) => {
                const currentObject = objects.value[id]
                if (currentObject.type === 'sequence') {
                    const emptyTween = gsap.set(
                        getRef(id)?.current as GSAPTweenTarget,
                        serializeAnimationStateForPreview([imageSrc(EMPTY_IMAGE_SRC)])
                    )
                    newMasterTimeline.add(emptyTween, 0)
                }
            })
        }

        // Group by objectId animations with subTimeline === true
        const groupedAnimations: { [key: string]: AnimationI[] } = animations
            .filter((animation) => animation.subTimeline === true)
            .reduce((acc, animation) => {
                if (!animation.parentId) {
                    const { objectId } = animation
                    if (!acc[objectId]) {
                        acc[objectId] = []
                    }
                    acc[objectId].push(animation)
                } else {
                    const { parentId } = animation
                    if (!acc[parentId]) {
                        acc[parentId] = []
                    }
                    acc[parentId].push(animation)
                }
                return acc
            }, {} as { [key: string]: AnimationI[] })

        // Iterate over each group and create subTimeline
        for (const objectId of Object.keys(groupedAnimations)) {
            const animationGroup = groupedAnimations[objectId]
            subTimelines[objectId]?.clear()

            //[NOTE]: pri prvotnom nacitani vytvori dva krat gsap subtimelien s dvomi rozlicnymi referenciami aj napriek tomu ze ukladam iba jednu do subtimeline.slice
            // problem bude pravdepdobne nastavat preto lebo gsap si drzi v DOM-e vlastnu referenciu aj napriek tomu ze obsluha danej subtimeline by mala byt v subtimeline.slice
            // vidiet to ked sa prida parameter paused: true,
            let newTickerTimeline: GSAPTimeline = gsap.timeline({
                repeat: -1,
                id: objectId,
            })

            // Create gsapTween from each animations and add to tickerTimeline

            for (const animation of animationGroup) {
                if (!animation.subTimelineType?.startsWith('crawl')) {
                    const gsapTween: GSAPTween = initTweenForPreview(animation, getRef)
                    newTickerTimeline.add(gsapTween, animation.delay)
                }
            }
            if (animationGroup[0].subTimelineType === 'scrollDown') {
                const maskObject = objects.value[objectId] as TickerI
                const SCROLL_TIME = maskObject.duration
                const textList1Node = document.getElementById(objectId)
                const oldCopy = document.getElementById(objectId + '-copy')
                if (oldCopy) {
                    oldCopy.remove()
                }
                const numOfTextElements = document.getElementById(objectId)?.children.length ?? 1
                const textListHeightPerc = 100 / numOfTextElements
                if (textList1Node) {
                    const parent = textList1Node.parentNode
                    if (parent) {
                        const textList2Node = textList1Node.cloneNode(true) as HTMLElement
                        textList2Node.id = objectId + '-copy'

                        parent.appendChild(textList2Node)
                        // move last out
                        newTickerTimeline.fromTo(
                            `#${textList2Node.id}`,
                            {
                                transform: `translateY(${
                                    Number(-100) + Number(numOfTextElements * textListHeightPerc)
                                }%)`,
                            },
                            {
                                transform: `translateY(${
                                    Number(-100) +
                                    Number((Number(numOfTextElements) + 1) * textListHeightPerc)
                                }%)`,
                                ease: 'none',
                                duration: SCROLL_TIME,
                            },
                            0
                        )
                        // move last to the top
                        newTickerTimeline.set(`#${textList2Node.id}`, {
                            transform: 'translateY(-100%)',
                        })
                    }
                }
            } else if (animationGroup[0].subTimelineType === 'scrollUp') {
                const maskObject = objects.value[objectId] as TickerI
                const SCROLL_TIME = maskObject.duration
                const textList1Node = document.getElementById(objectId)
                const oldCopy = document.getElementById(objectId + '-copy')
                if (oldCopy) {
                    oldCopy.remove()
                }
                const numOfTextElements = document.getElementById(objectId)?.children.length ?? 1
                const textListHeightPerc = 100 / numOfTextElements
                if (textList1Node) {
                    const parent = textList1Node.parentNode
                    if (parent) {
                        const textList2Node = textList1Node.cloneNode(true) as HTMLElement
                        textList2Node.id = objectId + '-copy'
                        parent.appendChild(textList2Node)

                        newTickerTimeline.fromTo(
                            `#${textList2Node.id}`,
                            { transform: `translateY(${-100 + 1 * textListHeightPerc}%)` },
                            {
                                transform: `translateY(${-100 + 0 * textListHeightPerc}%)`,
                                ease: 'none',
                                duration: SCROLL_TIME,
                            },
                            0
                        )

                        newTickerTimeline.set(`#${textList2Node.id}`, {
                            transform: `translateY(${0 + 1 * textListHeightPerc}%)`,
                        })
                    }
                }
            } else if (animationGroup[0].subTimelineType === 'crawlStaggered') {
                const maskObject = objects.value[objectId] as TickerI
                let childTexts: string[] = []
                for (const childId of maskObject.childIds) {
                    const textObject = objects.value[childId] as TextI
                    childTexts.push(textObject.text)
                }
                const joinedTexts = await tweenly.processField(
                    childTexts.join(';') + ';',
                    assets.data as Asset[]
                )
                let splittedTexts: string[] = []
                if (joinedTexts) {
                    splittedTexts = joinedTexts.split(';').filter((text) => text.length > 0)
                }
                let splittedTextsIds: Record<string, string> = {}
                splittedTexts.forEach(
                    (splText, index) => (splittedTextsIds[maskObject.childIds[index]] = splText)
                )
                let sumWidth: number[] = []
                let lastWidth: number = 0
                let newWidths: Record<string, number> = {}
                for (const childId of maskObject.childIds) {
                    const element = document.getElementById(childId) as HTMLElement
                    const newText = splittedTextsIds[childId]
                    if (newText) {
                        element.textContent = newText
                        newWidths[childId] = element.clientWidth
                        sumWidth.push(element.clientWidth)
                    }
                }
                const maskObjectElement = document.getElementById(maskObject.id)
                const maskWidth = maskObjectElement?.clientWidth ?? 0
                const textsWidth = sumArray(sumWidth)
                const duration: number =
                    maskObject && maskObject?.duration
                        ? maskObject?.duration
                        : CRAWL_STAGGERED_DURATION
                const crawlSpeed = Number((maskWidth + textsWidth) / duration)

                const tickerObjectElement =
                    maskObject.parentId &&
                    (document.getElementById(maskObject.parentId) as HTMLElement)
                if (tickerObjectElement) {
                    newTickerTimeline.fromTo(
                        `#${maskObject.id}`,
                        { transform: `translateX(${tickerObjectElement.clientWidth}px)` },
                        {
                            transform: `translateX(-${textsWidth}px)`,
                            ease: 'none',
                            duration: crawlSpeed,
                        },
                        0
                    )
                }
                for (const childId of maskObject.childIds) {
                    if (lastWidth === 0) {
                        newTickerTimeline.set(
                            `#${childId}`,
                            {
                                transform: `translateX(${0}px)`,
                            },
                            0
                        )
                        lastWidth = newWidths[childId]
                    } else {
                        newTickerTimeline.set(
                            `#${childId}`,
                            {
                                transform: `translateX(${lastWidth}px)`,
                            },
                            0
                        )
                        lastWidth += newWidths[childId]
                    }
                }
            } else if (animationGroup[0].subTimelineType === 'crawlContinuous') {
                const maskObject = objects.value[objectId] as TickerI
                const parentObject = maskObject.parentId
                    ? document.getElementById(maskObject.parentId)
                    : null
                const parentObjectWidth = parentObject?.clientWidth ? parentObject?.clientWidth : 0
                const textList1Node = document.getElementById(objectId)

                let childTexts: string[] = []
                for (const childId of maskObject.childIds) {
                    const textObject = objects.value[childId] as TextI
                    childTexts.push(textObject.text)
                }
                const joinedTexts = await tweenly.processField(
                    childTexts.join(';') + ';',
                    assets.data as Asset[]
                )
                let splittedTexts: string[] = []
                if (joinedTexts) {
                    splittedTexts = joinedTexts.split(';').filter((text) => text.length > 0)
                }
                let splittedTextsIds: Record<string, string> = {}
                splittedTexts.forEach(
                    (splText, index) => (splittedTextsIds[maskObject.childIds[index]] = splText)
                )
                let sumWidth: number[] = []
                let lastWidth: number = 0
                let newWidths: Record<string, number> = {}
                for (const childId of maskObject.childIds) {
                    // for get widths we need clone every text element processField return newText with translate text from assets,
                    // then set textContent for clone element and get clientWidth
                    const textObject = objects.value[childId] as TextI
                    let width = 0
                    const element = document.getElementById(childId) as HTMLElement
                    const clone = element.cloneNode(true) as HTMLElement
                    clone.id = 'clone'
                    const newText = splittedTextsIds[childId]
                    if (newText) {
                        clone.textContent = newText
                    }
                    const parent =
                        textObject.parentId &&
                        (document.getElementById(textObject.parentId) as HTMLElement)
                    if (parent) {
                        parent.appendChild(clone)
                        width = clone.clientWidth
                        newWidths[textObject.id] = clone.clientWidth
                        parent.removeChild(clone)
                    }
                    sumWidth.push(width)
                }
                const textsWidth = sumArray(sumWidth)
                const duration: number =
                    maskObject && maskObject?.duration
                        ? maskObject?.duration
                        : CRAWL_CONTINUES_DURATION
                const crawlSpeed = Number(textsWidth / duration)
                const delay: number = crawlSpeed
                //animation for main crawl mask object
                newTickerTimeline.fromTo(
                    `#${maskObject.id}`,
                    { transform: `translateX(0px)` },
                    {
                        transform: `translateX(-${textsWidth}px)`,
                        ease: 'none',
                        duration: crawlSpeed,
                    },
                    0
                )
                if (textList1Node) {
                    const parent = textList1Node.parentNode
                    const oldCopy = document.querySelectorAll(
                        `[class^="item-container-copy ${objectId}"]`
                    )

                    if (parent) {
                        if (oldCopy) {
                            oldCopy.forEach((element) => {
                                parent.removeChild(element)
                            })
                        }
                        const numberOfCopiesTextWrapper =
                            Math.round(parentObjectWidth / textsWidth) + 1
                        for (let i = 0; i <= numberOfCopiesTextWrapper; i++) {
                            const textListNode = textList1Node.cloneNode(true) as HTMLElement
                            textListNode.id = objectId + '-copy-' + i
                            textListNode.className = `item-container-copy ${textList1Node.id} copy-${i}`
                            parent.appendChild(textListNode)
                            // newTickerTimeline.set(`#${textListNode.id}`, {
                            //     transform: `translateX(${textsWidth * (i + 1)}px)`,
                            // })
                            newTickerTimeline.fromTo(
                                `#${textListNode.id}`,
                                { transform: `translateX(${textsWidth * (i + 1)}px)` },
                                {
                                    transform: `translateX(${textsWidth * i}px)`,
                                    ease: 'none',
                                    duration: crawlSpeed,
                                },
                                0
                            )
                            newTickerTimeline.fromTo(
                                `#${textListNode.id}`,
                                { transform: `translateX(${textsWidth * i}px)` },
                                {
                                    transform: `translateX(${textsWidth * i - textsWidth}px)`,
                                    ease: 'none',
                                    duration: crawlSpeed,
                                },
                                delay
                            )
                        }
                    }
                }
                for (const childId of maskObject.childIds) {
                    if (lastWidth === 0) {
                        newTickerTimeline.set(
                            `#${childId}`,
                            {
                                transform: `translateX(${0}px)`,
                            },
                            0
                        )
                        lastWidth = newWidths[childId]
                    } else {
                        newTickerTimeline.set(
                            `#${childId}`,
                            {
                                transform: `translateX(${lastWidth}px)`,
                            },
                            0
                        )
                        lastWidth += newWidths[childId]
                    }
                }
            }
            // Initialized subTimeline
            dispatch(
                initSubTimelineAction({
                    id: objectId,
                    timeline: newTickerTimeline,
                })
            )
        }

        // @ts-ignore
        animations
            .filter((animation) => !animation.subTimeline)
            .forEach((animation: AnimationI) => {
                const gsapTween: GSAPTween = initTweenForPreview(animation, getRef)
                newMasterTimeline.add(gsapTween, animation.delay)

                if (animation.tween.name === 'sequence') {
                    const nextDelay = roundNumber(animation.delay + animation.duration, 2)
                    const nextAnimation = animations.find(
                        (a) =>
                            a &&
                            a.objectId === animation.objectId &&
                            roundNumber(a.delay, 2) === nextDelay &&
                            a.tween.name === 'sequence'
                    )

                    if (!nextAnimation) {
                        const emptyTween = gsap.set(
                            getRef(animation.objectId)?.current as GSAPTweenTarget,
                            serializeAnimationStateForPreview([imageSrc(EMPTY_IMAGE_SRC)])
                        )
                        newMasterTimeline.add(emptyTween, nextDelay)
                    }
                }
            })

        if (timeline.value.labels) {
            Object.values(timeline.value.labels).forEach((label: TimelineLabelI) => {
                newMasterTimeline.addLabel(label.title, label.time)
            })
        }

        GSDevTools.create({
            id: 'master',
            hideGlobalTimeline: true,
            animation: 'master',
            container: '#controls-wrapper',
            persist: false,
            keyboard: false, // [NOTE] why not allow keyboard shortcut ?
        })

        if (timeline.value.freezeTime !== null) {
            newMasterTimeline.pause(timeline.value.freezeTime, false)
        } else {
            newMasterTimeline.pause(timeToSeek, false)
        }

        dispatch(updMasterTimelineAction({ gsapTimeline: newMasterTimeline }))
        refreshAllStyles?.()
    }

export const { updMasterTimelineAction, setTimeToSeek } = masterTimelineSlice.actions
export default masterTimelineSlice.reducer
