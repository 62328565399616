import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import VisibilityRoundedIcon from '@mui/icons-material/Visibility'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOff'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/system'
import { t } from 'i18next'
import { selectUndoable } from '../../../helpers/selector.helpers'
import { useObjectActions } from '../../../hooks/useObjectActions'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import {
    selectObjectById,
    updateObjectVisibilityStylesAction,
} from '../../../store/slices/objects.slice'
import { AppStateT } from '../../../store/store'
import ObjectAnimationSelection from './ObjectAnimationSelection'
import ObjectsTreeNodeMenu from './ObjectsTreeNodeMenu'

interface Props {
    object: AnySceneObjectT
    numberOfObjects: number
    isHiding: boolean
    isLocking: boolean
    isExpanded: boolean
}

const ObjectsTreeNode = ({ object, numberOfObjects, isHiding, isLocking, isExpanded }: Props) => {
    const dispatch = useAppDispatch()
    const state: AppStateT = useAppSelector((state) => state)

    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)
    const theme = useTheme()

    const isVisible = !object.isHidden
    const isUnlockLocked = !object.isLocked
    const isCollapsed = object.type === 'ticker' ? false : !object.isExpanded
    const { updateObject } = useObjectActions()

    const handleVisibility = () => {
        dispatch(updateObjectVisibilityStylesAction({ object, isHidden: !object.isHidden }))
    }
    const handleLock = () => {
        updateObject(object.id, 'isLocked', !object.isLocked)
    }

    const handleExpand = () => {
        updateObject(object.id, 'isExpanded', !object.isExpanded)
    }

    const renderChild = (childId: string) => {
        let childObject = selectObjectById(selectUndoable(state).objects, childId)
        if (childObject?.parentId === object?.id && (!isExpanded || isCollapsed))
            return (
                <Box key={childObject.id} sx={{ display: 'none' }}>
                    <ObjectsTreeNode
                        object={childObject}
                        key={childId}
                        numberOfObjects={object.childIds.length}
                        isHiding={isHiding || !isVisible}
                        isLocking={isLocking || !isUnlockLocked}
                        isExpanded={isExpanded || !isCollapsed}
                    />
                </Box>
            )
        else {
            return (
                <Box key={childObject.id}>
                    <ObjectsTreeNode
                        object={childObject}
                        key={childId}
                        numberOfObjects={object.childIds.length}
                        isHiding={isHiding || !isVisible}
                        isLocking={isLocking || !isUnlockLocked}
                        isExpanded={isExpanded || !isCollapsed}
                    />
                </Box>
            )
        }
    }
    if (!object) return <></>

    return (
        <Box sx={{ paddingLeft: 2, width: '16rem' }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    marginBottom: 1,
                    padding: 1,
                    borderRadius: 1,
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Grid item md={1} id="editor-step-3">
                    {!isCollapsed ? (
                        <Tooltip title={!object.childIds.length ? '' : t('editor:collapse')}>
                            <span>
                                <IconButton
                                    onClick={handleExpand}
                                    disabled={
                                        object.type === 'ticker'
                                            ? true
                                            : object.childIds.length === 0
                                            ? true
                                            : false
                                    }
                                >
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    ) : (
                        <Tooltip title={t('editor:expand')}>
                            <IconButton onClick={handleExpand}>
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
                <ObjectsTreeNodeMenu
                    object={object}
                    activeObjectId={selectedObjectIds[0]}
                    numberOfObjects={numberOfObjects}
                />
                <Grid item md={1}>
                    <Tooltip title={object.isLocked ? t('editor:unlock') : t('editor:lock')}>
                        <span>
                            <IconButton onClick={handleLock} disabled={isLocking}>
                                {isUnlockLocked && !isLocking ? (
                                    <LockOpenIcon fontSize="small" />
                                ) : (
                                    <LockIcon fontSize="small" />
                                )}
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item md={1}>
                    <Tooltip title={isVisible ? t('editor:hide') : t('editor:show')}>
                        <span>
                            <IconButton onClick={handleVisibility} disabled={isHiding}>
                                {isVisible && !isHiding ? (
                                    <VisibilityRoundedIcon fontSize="small" />
                                ) : (
                                    <VisibilityOffRoundedIcon fontSize="small" />
                                )}
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <ObjectAnimationSelection object={object} invisible={!isVisible || isHiding} />
                </Grid>
            </Grid>
            {object.type !== 'ticker' && object.childIds.map(renderChild)}
        </Box>
    )
}

export default ObjectsTreeNode
