import saveAs from 'file-saver'
import JSONfn from 'json-fn'
import { defaultTimeline } from '../data/defaults/timeline.types.defaults'
import { FileResponse, getFile } from '../helpers/ajax.helpers'
import { getMetaTag, readFile } from '../helpers/file.helpers'
import { FileProcessor } from '../helpers/fileProcessor.helpers'
import { selectUndoable } from '../helpers/selector.helpers'
import { AssetT } from '../store/slices/assets.slice'
import { setLoading, setPercentLoading } from '../store/slices/loading.slice'
import { selectObjects } from '../store/slices/objects.slice'
import { selectAllAnimations } from '../store/slices/objects.slice/animation/selectAllAnimations'
import { AppStateT } from '../store/store'
import { useCanvas } from './useCanvas'
import { useAppDispatch, useAppSelector } from './useRedux'

// const GSAPlibrary: string = '<script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.9.1/gsap.min.js"></script>'
// const jQueryLibrary: string = `<script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>`

const useHTMLFileContent = () => {
    const dispatch = useAppDispatch()
    const state: AppStateT = useAppSelector((state) => state)
    const { canvasResolution: canvas } = useCanvas()
    const allObjects: AnySceneObjectT[] = useAppSelector((state) =>
        selectObjects(selectUndoable(state).objects)
    )

    const timeline: TimelineI = useAppSelector((state) => state.timeline.value)
    const animations: AnimationI[] = selectAllAnimations(allObjects)

    const processFile = (basePath: string = window.location.origin, files: FileResponse[] = []) => {
        const fileProcessor = new FileProcessor(
            allObjects,
            animations,
            state.assets.data,
            state.editor.value.settings.timeline,
            timeline,
            canvas,
            state.graphic.data
        )
        return fileProcessor.processFile(basePath, files)
    }

    const loadingCallback = (value: number) => {
        dispatch(setPercentLoading(value))
    }

    const processZip = async () => {
        const fileProcessor = new FileProcessor(
            allObjects,
            animations,
            state.assets.data,
            state.editor.value.settings.timeline,
            timeline,
            canvas,
            state.graphic.data
        )
        return fileProcessor.processZip(loadingCallback)
    }

    const downloadFile = async (id: string, graphicName: string) => {
        dispatch(setLoading(true))
        const data = await getFile(id)
        saveAs(data, `${graphicName}.html`)
        dispatch(setLoading(false))
    }

    const downloadZip = async (id: string, graphicName: string) => {
        const file = await readFile(await getFile(id))

        const assets: AssetT[] = JSONfn.parse(getMetaTag(file, 'assets'))
        const canvas: CanvasI = JSONfn.parse(getMetaTag(file, 'canvas'))
        const allObjects: AnySceneObjectT[] = JSONfn.parse(getMetaTag(file, 'objects'))

        let animationState = getMetaTag(file, 'animations')
        let animations: AnimationI[] =
            animationState.length > 0
                ? JSONfn.parse(animationState)
                : selectAllAnimations(allObjects)

        const timelineState = getMetaTag(file, 'timeline')
        const timeline: TimelineI =
            timelineState.length > 0 ? JSONfn.parse(timelineState) : defaultTimeline

        const fileProcessor = new FileProcessor(
            allObjects,
            animations,
            assets,
            state.editor.value.settings.timeline,
            timeline,
            canvas,
            state.graphic.data
        )

        dispatch(setLoading(true))
        saveAs(await fileProcessor.processZip(loadingCallback), `${graphicName}.zip`)
        dispatch(setLoading(false))
    }

    return { processFile, processZip, downloadFile, downloadZip }
}

export default useHTMLFileContent
