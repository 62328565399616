import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { objectNodeUpdate } from './objectNodeUpdate'
import { UpdateObjectsStyle } from './objectTypes'

export const updateObjectsStyleProperty = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateObjectsStyle>
) => {
    const objects = action.payload.objects
    const property = action.payload.property
    const value = action.payload.value
    objects.forEach((object) => {
        const style: SimpleStyleT | undefined = object.styles.find(
            (style) => style.property === property
        )
        const restStyles: SimpleStyleT[] = object.styles.filter(
            (style) => style.property !== property
        )
        if (!style) return
        if (style.value === value && style.property === property) {
            return
        } else {
            const updatedStyles: SimpleStyleT[] = [
                ...restStyles,
                {
                    ...style,
                    value: value,
                },
            ]
            state.value = {
                ...state.value,
                [object.id]: objectNodeUpdate(state.value[object.id], {
                    id: object.id,
                    property: 'styles',
                    value: updatedStyles,
                }),
            }
        }
    })
    state.updatedAt = Date.now()
}
