import { ObjectsStateT } from '../store/slices/objects.slice'
import { animationScrollSet, animationScrollTranslateY } from './subtimeline.helpers'

export const recreateScrollUpAnimations = (maskObject: TickerI): AnimationI[] => {
    const numberOfChild = maskObject.childIds.length
    const textListHeightPerc = 100 / numberOfChild

    const SCROLL_TIME = maskObject.duration ?? 5
    const SCROLL_PAUSE = maskObject.delay ?? 2
    const maskAnimations: AnimationI[] = []

    for (let i = 0; i < numberOfChild; i++) {
        const currentAnimation = animationScrollTranslateY(
            maskObject,
            SCROLL_TIME,
            i * SCROLL_TIME + i * SCROLL_PAUSE,
            -100 + (numberOfChild - i + 1) * textListHeightPerc,
            -100 + (numberOfChild - i) * textListHeightPerc
        )
        maskAnimations.push({
            ...currentAnimation,
            subTimeline: true,
            subTimelineType: 'scrollUp',
        })

        const pauseAnimation = animationScrollTranslateY(
            maskObject,
            SCROLL_PAUSE,
            (i + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
            -100 + (numberOfChild - i) * textListHeightPerc,
            -100 + (numberOfChild - i) * textListHeightPerc
        )
        maskAnimations.push({
            ...pauseAnimation,
            subTimeline: true,
            subTimelineType: 'scrollUp',
        })
    }
    return maskAnimations
}

export const recreateScrollDownAnimations = (maskObject: TickerI): AnimationI[] => {
    const numberOfChild = maskObject.childIds.length
    const textListHeightPerc = 100 / numberOfChild

    const SCROLL_TIME = maskObject.duration ?? 5
    const SCROLL_PAUSE = maskObject.delay ?? 2
    const maskAnimations: AnimationI[] = []

    for (let i = 0; i < numberOfChild; i++) {
        const currentAnimation = animationScrollTranslateY(
            maskObject,
            SCROLL_TIME,
            i * SCROLL_TIME + i * SCROLL_PAUSE,
            -100 + i * textListHeightPerc,
            -100 + (i + 1) * textListHeightPerc
        )
        maskAnimations.push({
            ...currentAnimation,
            subTimeline: true,
            subTimelineType: 'scrollDown',
        })

        const pauseAnimation = animationScrollTranslateY(
            maskObject,
            SCROLL_PAUSE,
            (i + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
            -100 + (i + 1) * textListHeightPerc,
            -100 + (i + 1) * textListHeightPerc
        )
        maskAnimations.push({
            ...pauseAnimation,
            subTimeline: true,
            subTimelineType: 'scrollDown',
        })
    }
    return maskAnimations
}

export const recreateScrollUpDownAnimations = (maskObject: TickerI, state: ObjectsStateT) => {
    const SCROLL_TIME = maskObject.duration ?? 2
    const SCROLL_PAUSE = maskObject.delay ?? 5
    const numberOfChild = state.value[maskObject.id].childIds.length

    let textAnimations: AnimationI[] = []

    for (let i = 0; i < numberOfChild; i++) {
        const textObject = Object.values(state.value).find(
            (obj) => obj.id === maskObject.childIds[i]
        )

        if (textObject) {
            const currentSetAnimation = animationScrollSet(textObject, 0)
            textAnimations.push({
                ...currentSetAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUpDown',
                parentId: maskObject.id,
            })
            // // tl.set(`#${childId}`, { transform: `translateY(${-i * 100}%)` }, 0)
            const currentUpFromToAnimation = animationScrollTranslateY(
                textObject,
                SCROLL_TIME,
                i * 2 * SCROLL_TIME + i * SCROLL_PAUSE,
                0,
                -100
            )
            textAnimations.push({
                ...currentUpFromToAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUpDown',
                parentId: maskObject.id,
            })
            // // pause
            const pauseAnimation = animationScrollTranslateY(
                textObject,
                SCROLL_PAUSE,
                (i * 2 + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
                -100,
                -100
            )
            textAnimations.push({
                ...pauseAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUpDown',
                parentId: maskObject.id,
            })
            // move current by one text down
            const currentDownAnimation = animationScrollTranslateY(
                textObject,
                SCROLL_TIME,
                (i * 2 + 1) * SCROLL_TIME + (i + 1) * SCROLL_PAUSE,
                -100,
                0
            )
            textAnimations.push({
                ...currentDownAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUpDown',
                parentId: maskObject.id,
            })
            const finalTextAnimations: Record<string, AnimationI> = {}

            textAnimations.forEach((animation) => {
                finalTextAnimations[animation.id] = animation
            })
            state.value = {
                ...state.value,
                [textObject.id]: {
                    ...state.value[textObject.id],
                    animations: finalTextAnimations,
                },
            }
        }
        textAnimations = []
    }
    return state.value
}
