import { sumArray } from './array.helpers'
import { getElementWidth } from './html.helpers'
import { animationCrawlContinuous, animationCrawlStaggered } from './subtimeline.helpers'

export const CRAWL_CONTINUES_DURATION = 100
export const CRAWL_STAGGERED_DURATION = 100

export const recreateCrawlContinuousAnimations = (
    object: TickerI,
    maskObject: TickerI,
    textObjects: TextI[],
    temporaryTextsWidth?: number
): AnimationI => {
    const textsWidth =
        temporaryTextsWidth || sumArray(textObjects.map((x) => getElementWidth(x.id)))

    const duration =
        maskObject && maskObject?.duration ? maskObject?.duration : CRAWL_CONTINUES_DURATION
    const crawlSpeed = Number(textsWidth / duration)

    const crawlAnimation = animationCrawlContinuous(maskObject, crawlSpeed, 0, -Number(textsWidth))
    return { ...crawlAnimation, subTimeline: true, subTimelineType: 'crawlContinuous' }
}

export const recreateCrawlStaggeredAnimations = (
    object: TickerI,
    maskObject: TickerI,
    textObjects: TextI[],
    temporaryTextsWidth?: number
): AnimationI => {
    const textsWidth =
        temporaryTextsWidth || sumArray(textObjects.map((x) => getElementWidth(x?.id)))
    const tickerWidth: number = object.styles.find((style) => style.property === 'width')?.value
    const duration: number =
        maskObject && maskObject?.duration ? maskObject?.duration : CRAWL_STAGGERED_DURATION
    const crawlSpeed = Number((tickerWidth + textsWidth) / duration)

    const crawlAnimation = animationCrawlStaggered(
        maskObject,
        crawlSpeed,
        tickerWidth,
        -Number(textsWidth)
    )
    return { ...crawlAnimation, subTimeline: true, subTimelineType: 'crawlStaggered' }
}
