import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { AddTickerAnimationsT } from '../animation/animationTypes'

export const addSubTimelineAnimationBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<AddTickerAnimationsT>
) => {
    const objectId = action.payload.objectId
    const maskAnimations = action.payload.animations
    const maskObject = state.value[objectId] as TickerI
    const currentTextAnimations = action.payload.textAnimations
    const updatedAt = action.payload.updatedAt ? action.payload.updatedAt : 0
    const duration = action.payload.duration
        ? action.payload.duration
        : maskObject.duration
        ? maskObject.duration
        : 0
    const delay = action.payload.delay
        ? action.payload.delay
        : maskObject.delay
        ? maskObject.delay
        : 0

    const animations: Record<string, AnimationI> = {}

    maskAnimations.forEach((animation) => {
        animations[animation.id] = animation
    })
    const textAnimations: Record<string, AnimationI> = {}

    currentTextAnimations &&
        currentTextAnimations.forEach((animation) => {
            textAnimations[animation.id] = animation
        })

    state.value = {
        ...state.value,
        [objectId]: {
            ...state.value[objectId],
            animations: animations,
            duration: duration,
            delay: delay,
        } as TickerI,
    }
    state.updatedAt = updatedAt
}
