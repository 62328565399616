import { PayloadAction } from '@reduxjs/toolkit'
import {
    recreateCrawlContinuousAnimations,
    recreateCrawlStaggeredAnimations,
} from '../../../../helpers/crawlTicker.helpers'
import {
    recreateScrollDownAnimations,
    recreateScrollUpAnimations,
    recreateScrollUpDownAnimations,
} from '../../../../helpers/scrollTicker.helpers'
import { ObjectsStateT } from '../../objects.slice'

export type DeleteTicker = {
    object: TickerI
    maskObject: TickerI
    textObject: TextI
    textObjects: TextI[]
}
export const deleteTickerTextBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<DeleteTicker>
) => {
    const object = action.payload.object
    const maskObject = action.payload.maskObject
    const textObject = action.payload.textObject
    const textObjects = action.payload.textObjects

    let updatedCrawlTextObjects: TextI[] = []
    let newWidths: Record<string, number> = {}

    if (object.tickerType.startsWith('crawl')) {
        textObjects.forEach((textObject) => {
            const element = document.getElementById(textObject.id) as HTMLElement
            const clone = element.cloneNode(true) as HTMLElement
            clone.id = 'clone'
            const parent =
                textObject.parentId && (document.getElementById(textObject.parentId) as HTMLElement)
            if (parent) {
                parent.appendChild(clone)
                newWidths[textObject.id] = clone.clientWidth
                parent.removeChild(clone)
            }
        })
    }

    if (state.value[textObject.id]) {
        delete state.value[textObject.id]
    }

    state.value = {
        ...state.value,
        [maskObject.id]: {
            ...maskObject,
            childIds: maskObject.childIds.filter((childId) => childId !== textObject.id),
        },
    }

    let crawlAnimation: AnimationI | null = null
    let scrollAnimation: AnimationI[] = []
    const modifiedTextObjects = textObjects.filter((obj) => obj.id !== textObject.id)

    if (object.tickerType.startsWith('crawl')) {
        // let lastObject: AnySceneObjectT | null = null
        // modifiedTextObjects
        //     .sort((a, b) => a.index - b.index)
        //     .forEach((obj) => {
        //         if (lastObject === null) {
        //             const updatedStyles = obj.styles.map((style) => {
        //                 if (style.property === 'left') {
        //                     return {
        //                         ...style,
        //                         value: 0,
        //                     }
        //                 }
        //                 return style
        //             })
        //             updatedCrawlTextObjects.push({ ...obj, styles: updatedStyles })
        //             lastObject = { ...obj, styles: updatedStyles }
        //         }
        //         if (obj.id !== lastObject.id) {
        //             const lastObjectDiv = document.getElementById(lastObject.id)
        //             if (lastObjectDiv) {
        //                 const lastObjectClientWidth = newWidths[lastObject.id]
        //                 const lastObjectLeft = lastObject.styles.find(
        //                     (style) => style.property === 'left'
        //                 )?.value
        //                 if (lastObjectClientWidth !== undefined) {
        //                     const updatedStyles = obj.styles.map((style) => {
        //                         if (style.property === 'left') {
        //                             return {
        //                                 ...style,
        //                                 value: 0,
        //                                 // Number(lastObjectLeft) + Number(lastObjectClientWidth),
        //                             }
        //                         }
        //                         return style
        //                     })
        //                     updatedCrawlTextObjects.push({ ...obj, styles: updatedStyles })
        //                     //set this modified object as lastObject for next iteration
        //                     lastObject = { ...obj, styles: updatedStyles }
        //                 }
        //             }
        //         }
        //     })
        // //update state,value with updatedObject with new left styles
        // state.value = updatedCrawlTextObjects.reduce((newState, object) => {
        //     return {
        //         ...newState,
        //         [object.id]: {
        //             ...state.value[object.id],
        //             styles: object.styles,
        //         },
        //     }
        // }, state.value)
    }
    if (object.tickerType === 'scroll-down' || object.tickerType === 'scroll-up') {
        let lastObject: AnySceneObjectT | null = null
        let height: number = 0
        modifiedTextObjects
            .sort((a, b) => a.index - b.index)
            .forEach((obj) => {
                if (lastObject === null) {
                    const updatedStyles = obj.styles.map((style) => {
                        if (style.property === 'top') {
                            return {
                                ...style,
                                value: 0,
                            }
                        }
                        return style
                    })
                    updatedCrawlTextObjects.push({ ...obj, styles: updatedStyles })
                    lastObject = { ...obj, styles: updatedStyles }
                    height = lastObject?.styles.find((style) => style.property === 'height')?.value
                }
                if (obj.id !== lastObject.id) {
                    const lastObjectDiv = document.getElementById(lastObject.id)
                    if (lastObjectDiv) {
                        const id: string = lastObject.id
                        const div = document.getElementById(id)
                        const offHeight = div?.offsetHeight
                        const top: number = lastObject?.styles.find(
                            (style) => style.property === 'top'
                        )?.value

                        if (lastObject !== undefined) {
                            const updatedStyles = obj.styles.map((style) => {
                                if (style.property === 'top') {
                                    return {
                                        ...style,
                                        value: Number(top) + Number(offHeight),
                                    }
                                }
                                return style
                            })
                            updatedCrawlTextObjects.push({ ...obj, styles: updatedStyles })
                            //set this modified object as lastObject for next iteration
                            lastObject = { ...obj, styles: updatedStyles }
                        }
                    }
                }
            })
        const maskHeightStyle: SimpleStyleT | undefined = maskObject.styles.find(
            (style) => style.property === 'height'
        )

        if (!maskHeightStyle) {
            console.error('No maskHeight Style found in mask object')
            return
        }

        const restMaskStyles: SimpleStyleT[] = maskObject.styles.filter(
            (style) => style.property !== 'height'
        )
        const updatedMaskStyles: SimpleStyleT[] = [
            ...restMaskStyles,
            {
                ...maskHeightStyle,
                value: Number(maskHeightStyle.value) - Number(height),
            },
        ]
        state.value = {
            ...state.value,
            [maskObject.id]: { ...state.value[maskObject.id], styles: updatedMaskStyles },
        }
        //update state,value with updatedObject with new left styles
        state.value = updatedCrawlTextObjects.reduce((newState, object) => {
            return {
                ...newState,
                [object.id]: {
                    ...state.value[object.id],
                    styles: object.styles,
                },
            }
        }, state.value)
    }

    if (object) {
        switch (object.tickerType) {
            case 'crawl-continuous':
                crawlAnimation = recreateCrawlContinuousAnimations(
                    state.value[object.id] as TickerI,
                    state.value[maskObject.id] as TickerI,
                    modifiedTextObjects
                )
                break
            case 'crawl-staggered':
                crawlAnimation = recreateCrawlStaggeredAnimations(
                    state.value[object.id] as TickerI,
                    state.value[maskObject.id] as TickerI,
                    modifiedTextObjects
                )
                break
            case 'scroll-up':
                scrollAnimation = recreateScrollUpAnimations(state.value[maskObject.id] as TickerI)
                break
            case 'scroll-down':
                scrollAnimation = recreateScrollDownAnimations(
                    state.value[maskObject.id] as TickerI
                )
                break
            case 'scroll-up-down':
                recreateScrollUpDownAnimations(state.value[maskObject.id] as TickerI, state)
                break
            default:
                break
        }
    }

    if (object.tickerType.startsWith('crawl') && crawlAnimation) {
        const animations: Record<string, AnimationI> = {}
        animations[crawlAnimation.id] = crawlAnimation

        state.value = {
            ...state.value,
            [maskObject.id]: {
                ...state.value[maskObject.id],
                animations: animations,
            },
        }
    }
    if (object.tickerType.startsWith('scroll') && scrollAnimation.length !== 0) {
        const animations: Record<string, AnimationI> = {}

        scrollAnimation.forEach((animation) => {
            animations[animation.id] = animation
        })

        state.value = {
            ...state.value,
            [maskObject.id]: {
                ...state.value[maskObject.id],
                animations: animations,
            },
        }
    }
    state.updatedAt = Date.now()
}
