import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { Button, FormControl, Grid, InputLabel, Select } from '@mui/material'
import { t } from 'i18next'
import { useDispatch } from 'react-redux'
import { sumArray } from '../../../../helpers/array.helpers'
import {
    CRAWL_CONTINUES_DURATION,
    CRAWL_STAGGERED_DURATION,
} from '../../../../helpers/crawlTicker.helpers'
import { getElementWidth } from '../../../../helpers/html.helpers'
import { addSubTimelineAnimationAction } from '../../../../store/slices/objects.slice'
import ExpandableSection from '../../../common/ExpandableSection'
import { TickerInput } from './TickerInput'
interface Props {
    object: TickerI
    maskObject: TickerI | undefined
    textObjects: TextI[]
    playTimeline: () => void
    pauseTimeline: () => void
}
const CrawlTools = ({ object, maskObject, textObjects, playTimeline, pauseTimeline }: Props) => {
    const dispatch = useDispatch()
    const duration =
        maskObject && maskObject.duration
            ? maskObject.duration
            : maskObject?.tickerType === 'crawl-continuous'
            ? CRAWL_CONTINUES_DURATION
            : CRAWL_STAGGERED_DURATION

    const animation = maskObject && Object.values(maskObject?.animations)[0]
    const handleDurationOnChange = (e: any) => {
        const textsWidth = sumArray(textObjects.map((x) => getElementWidth(x?.id)))
        const crawlSpeed = Number(textsWidth) / Number(e.target.value)
        if (animation)
            dispatch(
                addSubTimelineAnimationAction({
                    animations: [
                        {
                            ...animation,
                            duration: crawlSpeed,
                        },
                    ],
                    textAnimations: null,
                    duration: Number(e.target.value),
                    objectId: animation.objectId,
                    updatedAt: Date.now(),
                })
            )
    }
    const handleDirectionOnChange = (e: any) => {
        // to do ... add crawlDirection into maskObject so you can recreate animation by settings and by type of crawl animation(staggered || continuous)
    }
    const crawlDirection = ['right-to-left', 'left-to-right']
    return (
        <ExpandableSection
            expanded={true}
            title={t('editor:tickers.crawlTitle')}
            component={
                <Grid container direction="row" spacing={1}>
                    <Grid item md={6}>
                        <TickerInput
                            inputValue={duration}
                            handleOnChange={handleDurationOnChange}
                            name={t('editor:tickers.crawlSpeedName')}
                            label={t('editor:tickers.crawlSpeed')}
                            unit={false}
                        />
                    </Grid>
                    {/* TO DO - delete visibility from grid AFTER implementation CrawlDirection */}
                    <Grid item md={6} visibility={'hidden'}>
                        <FormControl>
                            <InputLabel>{t('editor:tickers.crawlDirectionSelect')}</InputLabel>
                            <Select
                                native
                                name={t('editor:tickers.crawlDirectionSelectName')}
                                value={crawlDirection[0]}
                                onChange={handleDirectionOnChange}
                                disabled={true}
                                autoWidth
                            >
                                {crawlDirection.map((inputOption: string, index: number) => (
                                    <option key={index} value={inputOption}>
                                        {inputOption}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        <Button
                            startIcon={<PlayCircleIcon />}
                            color="success"
                            variant="text"
                            onClick={() => playTimeline()}
                        >
                            {t('play')}
                        </Button>
                        <Button
                            startIcon={<PauseCircleIcon />}
                            color="error"
                            variant="text"
                            onClick={() => pauseTimeline()}
                        >
                            {t('stop')}
                        </Button>
                    </Grid>
                </Grid>
            }
        />
    )
}

export default CrawlTools
