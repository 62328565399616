import { Collections, ElectricalServices, Image } from '@mui/icons-material'
import { t } from 'i18next'
import { BsFileEarmarkPlayFill } from 'react-icons/bs'
import { FaFont } from 'react-icons/fa'

export const FILTER_CREATED_BY = [
    {
        name: 'pageHeader.filterDefaultValue',
        getResultFilter: (userId?: string) => ({}),
    },
    {
        name: 'pageHeader.filterCreatedByMe',
        getResultFilter: (userId?: string) => ({ userId: userId }),
    },
    {
        name: 'pageHeader.filterCreatedByOthers',
        getResultFilter: (userId?: string) => ({ userId: { $not: { $eq: userId } } }),
    },
]

export const FILTER_ATTRIBUTE = [
    {
        name: 'pageHeader.filterDefaultValue',
        getResultFilter: () => ({}),
    },
    {
        name: 'pageHeader.filterStatusDrafts',
        getResultFilter: () => ({ isFinal: false, isPublished: false }),
    },
    {
        name: 'pageHeader.filterStatusFinal',
        getResultFilter: () => ({ isFinal: true }),
    },
    {
        name: 'pageHeader.filterStatusPublished',
        getResultFilter: () => ({ isPublished: true }),
    },
    {
        name: 'pageHeader.filterStatusUnpublished',
        getResultFilter: () => ({ isPublished: false }),
    },
    {
        name: 'pageHeader.filterStatusDownloaded',
        getResultFilter: () => ({ isExported: true }),
    },
]

export const FILTER_TYPE = [
    {
        name: 'all',
        getResultFilter: () => ({}),
        icon: undefined,
        tooltipTitle: t('graphics:filterList.allTooltip'),
    },
    {
        name: 'graphic',
        getResultFilter: () => ({ type: { $exists: false } }),
        icon: BsFileEarmarkPlayFill,
        tooltipTitle: t('graphics:filterList.graphicTooltip'),
    },
    {
        name: 'image',
        getResultFilter: () => ({ type: { $eq: 'image' } }),
        icon: Image,
        tooltipTitle: t('graphics:filterList.imageTooltip'),
    },
    {
        name: 'sequence',
        getResultFilter: () => ({ type: { $eq: 'sequence' } }),
        icon: Collections,
        tooltipTitle: t('graphics:filterList.sequenceTooltip'),
    },
    {
        name: 'font',
        getResultFilter: () => ({ type: { $eq: 'font' } }),
        icon: FaFont,
        tooltipTitle: t('graphics:filterList.fontTooltip'),
    },
    {
        name: 'json|xml|text',
        getResultFilter: () => ({ type: { $in: ['json', 'xml', 'text'] } }),
        icon: ElectricalServices,
        tooltipTitle: t('graphics:filterList.dataSourceTooltip'),
    },
] as const

export type FilterType = (typeof FILTER_TYPE)[number]['name']
