import { useMemo } from 'react'
import { sumArray } from '../helpers/array.helpers'
import { CRAWL_CONTINUES_DURATION, CRAWL_STAGGERED_DURATION } from '../helpers/crawlTicker.helpers'
import { getElementWidth } from '../helpers/html.helpers'
import { selectUndoable } from '../helpers/selector.helpers'
import {
    animationCrawlContinuous,
    animationCrawlStaggered,
    animationScrollTranslateY,
} from '../helpers/subtimeline.helpers'
import { addSubTimelineAnimationAction, selectObjectById } from '../store/slices/objects.slice'
import { useAppDispatch, useAppSelector } from './useRedux'

export const useTickerTimeline = (
    object: TickerI,
    textObjects: AnySceneObjectT[],
    actualTimeline: GSAPTimeline
) => {
    const dispatch = useAppDispatch()

    const objectsState = useAppSelector((state) => selectUndoable(state).objects)
    const maskObject = selectObjectById(objectsState, object.childIds[0]) as TickerI

    const SCROLL_TIME = useMemo(() => (maskObject.duration ? maskObject.duration : 2), [maskObject])
    const SCROLL_PAUSE = useMemo(() => (maskObject.delay ? maskObject.delay : 5), [maskObject])

    const crawlContinuous = () => {
        const textsWidth = sumArray(textObjects.map((x) => getElementWidth(x?.id)))
        const duration =
            maskObject && maskObject?.duration ? maskObject?.duration : CRAWL_CONTINUES_DURATION
        const crawlSpeed = Number(textsWidth / duration)
        const crawlAnimation = animationCrawlContinuous(maskObject, crawlSpeed, 0, -textsWidth)

        dispatch(
            addSubTimelineAnimationAction({
                animations: [
                    {
                        ...crawlAnimation,
                        subTimeline: true,
                        subTimelineType: 'crawlContinuous',
                    },
                ],
                textAnimations: null,
                duration: duration,
                objectId: crawlAnimation.objectId,
                updatedAt: Date.now(),
            })
        )
    }

    const crawlStaggered = () => {
        const textsWidth = sumArray(textObjects.map((x) => getElementWidth(x.id)))
        const tickerWidth = getElementWidth(object.id)
        const duration: number =
            maskObject && maskObject?.duration ? maskObject?.duration : CRAWL_STAGGERED_DURATION
        const crawlSpeed = Number(textsWidth / duration)

        const animation = animationCrawlStaggered(maskObject, crawlSpeed, tickerWidth, -textsWidth)

        dispatch(
            addSubTimelineAnimationAction({
                animations: [
                    {
                        ...animation,
                        subTimeline: true,
                        subTimelineType: 'crawlStaggered',
                    },
                ],
                textAnimations: null,
                duration: maskObject && maskObject?.duration ? maskObject?.duration : 10,
                objectId: animation.objectId,
                updatedAt: Date.now(),
            })
        )
    }

    //no used, rerender needed when rerender scene (change some property of wrapper)
    const scrollDown = () => {
        if (maskObject) {
            const numberOfChild = maskObject.childIds.length + 1
            const textListHeightPerc = 100 / numberOfChild
            const maskAnimations: AnimationI[] = []

            for (let i = 0; i < numberOfChild; i++) {
                const currentAnimation = animationScrollTranslateY(
                    maskObject,
                    SCROLL_TIME,
                    i * SCROLL_TIME + i * SCROLL_PAUSE,
                    -100 + i * textListHeightPerc,
                    -100 + (i + 1) * textListHeightPerc
                )
                maskAnimations.push({
                    ...currentAnimation,
                    subTimeline: true,
                    subTimelineType: 'scrollDown',
                })

                const pauseAnimation = animationScrollTranslateY(
                    maskObject,
                    SCROLL_PAUSE,
                    (i + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
                    -100 + (i + 1) * textListHeightPerc,
                    -100 + (i + 1) * textListHeightPerc
                )
                maskAnimations.push({
                    ...pauseAnimation,
                    subTimeline: true,
                    subTimelineType: 'scrollDown',
                })
            }
            dispatch(
                addSubTimelineAnimationAction({
                    animations: maskAnimations,
                    duration: maskObject.duration,
                    delay: maskObject.delay,
                    textAnimations: null,
                    objectId: maskObject.id,
                    updatedAt: Date.now(),
                })
            )
        }
    }
    //no used, rerender needed when rerender scene (change some property of wrapper)
    const scrollUp = () => {
        if (maskObject) {
            const numberOfChild = maskObject.childIds.length + 1
            // const numOfTextElements = document.getElementById(textListId).children.length
            const textListHeightPerc = 100 / numberOfChild

            const maskAnimations: AnimationI[] = []

            for (let i = 0; i < numberOfChild; i++) {
                const currentAnimation = animationScrollTranslateY(
                    maskObject,
                    SCROLL_TIME,
                    i * SCROLL_TIME + i * SCROLL_PAUSE,
                    -100 + (numberOfChild - i + 1) * textListHeightPerc,
                    -100 + (numberOfChild - i) * textListHeightPerc
                )
                maskAnimations.push({
                    ...currentAnimation,
                    subTimeline: true,
                    subTimelineType: 'scrollUp',
                })

                const pauseAnimation = animationScrollTranslateY(
                    maskObject,
                    SCROLL_PAUSE,
                    (i + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
                    -100 + (numberOfChild - i) * textListHeightPerc,
                    -100 + (numberOfChild - i) * textListHeightPerc
                )
                maskAnimations.push({
                    ...pauseAnimation,
                    subTimeline: true,
                    subTimelineType: 'scrollUp',
                })
            }
            dispatch(
                addSubTimelineAnimationAction({
                    animations: maskAnimations,
                    duration: maskObject.duration,
                    delay: maskObject.delay,
                    textAnimations: null,
                    objectId: maskObject.id,
                    updatedAt: Date.now(),
                })
            )
        }
    }

    const clearTimeline = () => {
        actualTimeline?.clear()
    }
    const playTimeline = () => {
        actualTimeline?.play()
    }
    const pauseTimeline = () => {
        actualTimeline?.pause()
    }
    const stopAndResetTimeline = () => {
        actualTimeline?.pause().seek(0)
    }

    return {
        clearTimeline,
        playTimeline,
        pauseTimeline,
        stopAndResetTimeline,
        crawlContinuous,
        crawlStaggered,
        scrollDown,
        scrollUp,
    }
}
