import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined'
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import Grid from '@mui/material/Grid'
import { useObjectActions } from '../../../hooks/useObjectActions'
import AddButton from '../../common/AddButton'
import CrawlTickerButton from './CrawlTickerButton'
import LoadImageButton from './LoadImageButton'
import LoadSequenceButton from './LoadSequenceButton'
import ScrollTickerButton from './ScrollTickerButton'

const ObjectsTreeActions = () => {
    const { addItem, addText, addVideoPanel, videoPanelExists } = useObjectActions()

    return (
        <Grid sx={{ mb: 0.5 }} id="editor-step-1">
            <AddButton
                text="rectangle"
                handleAddObject={addItem}
                icon={<RectangleOutlinedIcon fontSize="small" />}
            />
            <AddButton
                text="text"
                handleAddObject={addText}
                icon={<TextFieldsOutlinedIcon fontSize="small" />}
            />
            <LoadImageButton />
            <LoadSequenceButton />
            <AddButton
                disabled={videoPanelExists}
                text="video panel"
                handleAddObject={addVideoPanel}
                icon={<VideocamOutlinedIcon fontSize="small" />}
            />
            <CrawlTickerButton />
            <ScrollTickerButton />
        </Grid>
    )
}

export default ObjectsTreeActions
