import Grid from '@mui/material/Grid'
import { selectUndoable } from '../../../../helpers/selector.helpers'
import { useAppSelector } from '../../../../hooks/useRedux'
import { selectObjectById } from '../../../../store/slices/objects.slice'
import { TIMELINE_ROW } from '../../../../style/sizing'
import EmptyPanel from '../../../common/EmptyPanel'
import { TimelineRowWrapper } from './TimelineRowWrapper'

interface Props {
    objects: AnySceneObjectT[]
    timelineRowWidth: number
    isHidingObject: boolean
    isLockingObject: boolean
}

const TimelineRows = ({ objects, timelineRowWidth, isHidingObject, isLockingObject }: Props) => {
    const editor = useAppSelector((state) => state.editor)
    const rowsExpanded: boolean = editor.value.settings.timelineRowsExpanded
    const stateObjects = useAppSelector((state) => selectUndoable(state).objects)

    const timelineRowAreaWidth: number = TIMELINE_ROW.headingWidth
    const timelineAreaWidth = timelineRowWidth + timelineRowAreaWidth

    if (!objects || objects.length === 0)
        return <EmptyPanel text1="Timeline" text2="Animate the objects" />
    else
        return (
            <Grid
                py={0}
                sx={{
                    width: timelineAreaWidth + 'px',
                    marginBottom: TIMELINE_ROW.paddingBottom,
                }}
            >
                {objects.map((object: AnySceneObjectT, index: number) => {
                    const isParentTicker =
                        selectObjectById(stateObjects, object.parentId)?.type === 'ticker'
                    if (isParentTicker) {
                        return null
                    }
                    return (
                        <TimelineRowWrapper
                            stateObjects={stateObjects}
                            object={object}
                            index={index}
                            timelineRowWidth={timelineRowWidth}
                            isHidingObject={isHidingObject}
                            isLockingObject={isLockingObject}
                            rowsExpanded={rowsExpanded}
                            timelineAreaWidth={timelineAreaWidth}
                        />
                    )
                })}
            </Grid>
        )
}

export default TimelineRows
