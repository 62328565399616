import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { Button, Grid } from '@mui/material'
import { t } from 'i18next'
import { useDispatch } from 'react-redux'
import { updateTickerAnimationAction } from '../../../../store/slices/objects.slice'
import ExpandableSection from '../../../common/ExpandableSection'
import { TickerInput } from './TickerInput'
interface Props {
    object: TickerI
    maskObject: TickerI | undefined
    textObjects: TextI[]
    playTimeline: () => void
    pauseTimeline: () => void
}
const ScrollTools = ({ object, maskObject, textObjects, playTimeline, pauseTimeline }: Props) => {
    const dispatch = useDispatch()
    const duration = maskObject && maskObject.duration ? maskObject.duration : 0

    const delay = maskObject && maskObject.delay ? maskObject.delay : 0

    const handleOnChange = (e: any) => {
        if (maskObject) {
            dispatch(
                updateTickerAnimationAction({
                    property: e.target.name,
                    value: e.target.value,
                    objectId: maskObject.id,
                    updatedAt: Date.now(),
                })
            )
        }
    }
    return (
        <ExpandableSection
            expanded={true}
            title={t('editor:tickers.scrollTitle')}
            component={
                <Grid container direction="row" spacing={1}>
                    <Grid item md={6}>
                        <TickerInput
                            inputValue={duration}
                            handleOnChange={handleOnChange}
                            name={t('editor:tickers.durationName')}
                            label={t('editor:tickers.duration')}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TickerInput
                            inputValue={delay}
                            handleOnChange={handleOnChange}
                            name={t('editor:tickers.delayName')}
                            label={t('editor:tickers.delay')}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Button
                            startIcon={<PlayCircleIcon />}
                            color="success"
                            variant="text"
                            onClick={() => playTimeline()}
                        >
                            {t('play')}
                        </Button>
                        <Button
                            startIcon={<PauseCircleIcon />}
                            color="error"
                            variant="text"
                            onClick={() => pauseTimeline()}
                        >
                            {t('stop')}
                        </Button>
                    </Grid>
                </Grid>
            }
        />
    )
}

export default ScrollTools
