import { MAX_INPUT_NUM } from "../../../constants"

const positiveUnitaryInput: StyleInputT = {
	type: "number",
	props: {
		inputProps: {
			min: 0,
			max: MAX_INPUT_NUM,
			step: 1,
		},
	},
}

export const fontSize: SimpleStyleT = {
	property: "fontSize",
	value: 30,
	unit: "px",
	input: {
		...positiveUnitaryInput,
		label: "font size",
	},
	render: (value, unit, type) =>
		type === "css"
			? { property: "font-size", value: value + unit }
			: { property: "fontSize", value: value + unit },
}

export const fontFamily: SimpleStyleT = {
	property: "fontFamily",
	value: "Arial",
	input: {
		type: "select",
		props: [
			"Arial",
			"Book Antiqua",
			"Courier New",
			"Georgia",
			"Helvetica",
			"Impact",
			"Lucida Console",
			"Palatino Linotype",
			"Tahoma",
			"Times New Roman",
			"Verdana",
		],
		label: "font family",
	},
	render: (value, unit = undefined, type) =>
		type === "css" ? { property: "font-family", value } : { property: "fontFamily", value },
}

export const fontStyle: SimpleStyleT = {
	property: "fontStyle",
	value: "normal",
	input: {
		type: "select",
		props: ["normal", "italic"],
		label: "font style",
	},
	render: (value, unit = undefined, type) =>
		type === "css" ? { property: "font-style", value } : { property: "fontStyle", value },
}

export const fontWeight: SimpleStyleT = {
    property: "fontWeight",
    value: "normal",
    input: {
        type: "select",
        props: ["normal", "bold"],
        label: "font weight",
    },
    render: (value, unit = undefined, type) =>
        type === "css" ? { property: "font-weight", value } : { property: "fontWeight", value },
}

export const textTransform: SimpleStyleT = {
	property: "textTransform",
	value: "none",
	input: {
		type: "select",
		props: ["none", "inherit", "capitalize", "uppercase", "lowercase"],
		label: "text transform",
	},
	render: (value, unit = undefined, type) =>
		type === "css"
			? { property: "text-transform", value }
			: { property: "textTransform", value },
}

export const textAlign: SimpleStyleT = {
	property: "textAlign",
	value: "left",
	input: {
		type: "select",
		props: ["left", "center", "right"],
		label: "text align",
	},
	render: (value, unit = undefined, type) =>
		type === "css" ? { property: "text-align", value } : { property: "textAlign", value },
}

export const direction: SimpleStyleT = {
	property: "direction",
	value: "ltr",
	input: {
		type: "select",
		props: ["ltr", "rtl"],
	},
	render: (value) => ({ property: "direction", value }),
}

export const whiteSpace: SimpleStyleT = {
	property: "whiteSpace",
	value: "nowrap",
	input: {
		type: "select",
		props: ["normal", "nowrap"],
		label: "wrap text",
	},
	render: (value, unit = undefined, type) =>
		type === "css" ? { property: "white-space", value } : { property: "whiteSpace", value },
}

export const lineHeight: SimpleStyleT = {
    property: "lineHeight",
    value: 1,
    input: {
        type: "number",
        props: {
            inputProps: {
                min: 0,
                max: MAX_INPUT_NUM,
                step: 0.1,
            },
        },
	label: "line height",
    },
    render: (value, unit, type) => 
		type === "css"
		? { property: "line-height", value }
		: { property: "lineHeight", value },
}