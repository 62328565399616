import gsap from 'gsap'
import {
    defaultCrawlContinuousTicker,
    defaultCrawlStaggeredTicker,
    defaultScrollDownTicker,
    defaultScrollTickerText,
    defaultScrollUpDownTicker,
    defaultScrollUpTicker,
    defaultTickerCrawlContinuousTextWrapperItem,
    defaultTickerCrawlStaggeredTextWrapperItem,
    defaultTickerCrawlText,
    defaultTickerScrollDownTextWrapperItem,
    defaultTickerScrollUpDownTextWrapperItem,
    defaultTickerScrollUpTextWrapperItem,
} from '../data/defaults/objects.types.defaults'
import { CRAWL_CONTINUES_DURATION, CRAWL_STAGGERED_DURATION } from '../helpers/crawlTicker.helpers'
import { createObject } from '../helpers/creators.helpers'
import { selectUndoable } from '../helpers/selector.helpers'
import {
    animationCrawlContinuous,
    animationCrawlStaggered,
    animationScrollSet,
    animationScrollTranslateY,
} from '../helpers/subtimeline.helpers'
import { clearSelectedAnimationsAction } from '../store/slices/activeAnimation.slice'
import { setActiveObjectAction } from '../store/slices/activeObject.slice'
import { createTickerStructureAction, selectObjects } from '../store/slices/objects.slice'
import { initSubTimelineAction } from '../store/slices/subTimelines.slice'
import { AppStateT } from '../store/store'
import { useAppDispatch, useAppSelector } from './useRedux'

const SCROLL_TIME = 2
const SCROLL_PAUSE = 5

export const useTickerCreateAction = () => {
    const dispatch = useAppDispatch()
    const objects: AnySceneObjectT[] = useAppSelector((state: AppStateT) =>
        selectObjects(selectUndoable(state).objects)
    )

    const addCrawlContinuousTicker = (parentId: string | null) => {
        let object: TickerI = createObject(
            objects,
            defaultCrawlContinuousTicker,
            parentId
        ) as TickerI
        let maskObject: TickerI = createObject(
            objects,
            defaultTickerCrawlContinuousTextWrapperItem,
            object.id
        ) as TickerI
        let textObject: TextI = createObject(
            objects,
            defaultTickerCrawlText,
            maskObject.id
        ) as TextI
        const newTickerTimeline: GSAPTimeline = gsap.timeline({
            paused: true,
            repeat: -1,
        })

        const textsWidth = 185 //const width of one text: Loren Ipsum
        const duration = CRAWL_CONTINUES_DURATION

        const crawlSpeed = Number(textsWidth / duration)
        const animationFirst = animationCrawlContinuous(maskObject, crawlSpeed, 0, -textsWidth)

        dispatch(initSubTimelineAction({ id: maskObject.id, timeline: newTickerTimeline }))

        dispatch(
            createTickerStructureAction({
                object: {
                    ...object,
                },
                maskObject: { ...maskObject, duration: duration },
                maskAnimations: [
                    {
                        ...animationFirst,
                        subTimeline: true,
                        subTimelineType: 'crawlContinuous',
                    },
                ],
                textObject,
                textAnimations: null,
                updatedAt: Date.now(),
            })
        )

        dispatch(clearSelectedAnimationsAction())
        dispatch(setActiveObjectAction({ id: object.id }))
    }

    const addCrawlStaggeredTicker = (parentId: string | null) => {
        let object: TickerI = createObject(
            objects,
            defaultCrawlStaggeredTicker,
            parentId
        ) as TickerI //ticker wrapper
        let maskObject: TickerI = createObject(
            objects,
            defaultTickerCrawlStaggeredTextWrapperItem,
            object.id
        ) as TickerI
        let textObject: TextI = createObject(
            objects,
            defaultTickerCrawlText,
            maskObject.id
        ) as TextI
        const newTickerTimeline: GSAPTimeline = gsap.timeline({
            paused: true,
            repeat: -1,
        })

        const textsWidth = 185 //const width of one text: Loren Ipsum
        const tickerWidth = 1920 //const width of root object: TickerI
        const duration = CRAWL_STAGGERED_DURATION
        const crawlSpeed = Number((tickerWidth + textsWidth) / duration)

        const animation = animationCrawlStaggered(maskObject, crawlSpeed, tickerWidth, -textsWidth)

        dispatch(initSubTimelineAction({ id: maskObject.id, timeline: newTickerTimeline }))

        dispatch(
            createTickerStructureAction({
                object: {
                    ...object,
                },
                maskObject: { ...maskObject, duration: duration },
                maskAnimations: [
                    {
                        ...animation,
                        subTimeline: true,
                        subTimelineType: 'crawlStaggered',
                        duration: duration,
                    },
                ],
                textObject,
                textAnimations: null,
                updatedAt: Date.now(),
            })
        )

        //clear all selected animations
        dispatch(clearSelectedAnimationsAction())
        dispatch(setActiveObjectAction({ id: object.id }))
    }

    const addScrollDownTicker = (parentId: string | null) => {
        let object: TickerI = createObject(objects, defaultScrollDownTicker, parentId) as TickerI
        let maskObject: TickerI = createObject(
            objects,
            defaultTickerScrollDownTextWrapperItem,
            object.id
        ) as TickerI

        let textObject: TextI = createObject(
            objects,
            defaultScrollTickerText,
            maskObject.id
        ) as TextI

        const newTickerTimeline: GSAPTimeline = gsap.timeline({
            paused: true,
            repeat: -1,
        })

        const numOfTextElements = document.getElementById(maskObject.id)?.children.length ?? 1
        const textListHeightPerc = 100 / numOfTextElements
        const maskAnimations: AnimationI[] = []

        for (let i = 0; i < numOfTextElements; i++) {
            const currentAnimation = animationScrollTranslateY(
                maskObject,
                SCROLL_TIME,
                i * SCROLL_TIME + i * SCROLL_PAUSE,
                -100 + i * textListHeightPerc,
                -100 + (i + 1) * textListHeightPerc
            )
            maskAnimations.push({
                ...currentAnimation,
                subTimeline: true,
                subTimelineType: 'scrollDown',
            })

            const pauseAnimation = animationScrollTranslateY(
                maskObject,
                SCROLL_PAUSE,
                (i + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
                -100 + (i + 1) * textListHeightPerc,
                -100 + (i + 1) * textListHeightPerc
            )
            maskAnimations.push({
                ...pauseAnimation,
                subTimeline: true,
                subTimelineType: 'scrollDown',
            })
        }

        dispatch(
            createTickerStructureAction({
                object: {
                    ...object,
                },
                maskObject: { ...maskObject, duration: SCROLL_TIME, delay: SCROLL_PAUSE },
                maskAnimations: maskAnimations,
                textObject,
                textAnimations: null,
                updatedAt: Date.now(),
            })
        )

        dispatch(initSubTimelineAction({ id: maskObject.id, timeline: newTickerTimeline }))

        dispatch(clearSelectedAnimationsAction())
        dispatch(setActiveObjectAction({ id: object.id }))
    }
    const addScrollUpTicker = (parentId: string | null) => {
        let object: TickerI = createObject(objects, defaultScrollUpTicker, parentId) as TickerI
        let maskObject: TickerI = createObject(
            objects,
            defaultTickerScrollUpTextWrapperItem,
            object.id
        ) as TickerI

        let textObject: TextI = createObject(
            objects,
            defaultScrollTickerText,
            maskObject.id
        ) as TextI

        const newTickerTimeline: GSAPTimeline = gsap.timeline({
            paused: true,
            repeat: -1,
        })
        const numberOfChild = maskObject.childIds.length + 1
        const textListHeightPerc = 100 / numberOfChild

        const maskAnimations: AnimationI[] = []

        for (let i = 0; i < numberOfChild; i++) {
            // move current by one text
            const currentAnimation = animationScrollTranslateY(
                maskObject,
                SCROLL_TIME,
                i * SCROLL_TIME + i * SCROLL_PAUSE,
                -100 + (numberOfChild - i + 1) * textListHeightPerc,
                -100 + (numberOfChild - i) * textListHeightPerc
            )
            maskAnimations.push({
                ...currentAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUp',
            })

            // pause
            const pauseAnimation = animationScrollTranslateY(
                maskObject,
                SCROLL_PAUSE,
                (i + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
                -100 + (numberOfChild - i) * textListHeightPerc,
                -100 + (numberOfChild - i) * textListHeightPerc
            )
            maskAnimations.push({
                ...pauseAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUp',
            })
        }

        dispatch(
            createTickerStructureAction({
                object: {
                    ...object,
                },
                maskObject: { ...maskObject, duration: SCROLL_TIME, delay: SCROLL_PAUSE },
                maskAnimations: maskAnimations,
                textObject,
                textAnimations: null,
                updatedAt: Date.now(),
            })
        )

        dispatch(initSubTimelineAction({ id: maskObject.id, timeline: newTickerTimeline }))

        dispatch(clearSelectedAnimationsAction())
        dispatch(setActiveObjectAction({ id: object.id }))
    }
    const addScrollUpDownTicker = (parentId: string | null) => {
        let object: TickerI = createObject(objects, defaultScrollUpDownTicker, parentId) as TickerI
        let maskObject: TickerI = createObject(
            objects,
            defaultTickerScrollUpDownTextWrapperItem,
            object.id
        ) as TickerI

        let textObject: TextI = createObject(
            objects,
            defaultScrollTickerText,
            maskObject.id
        ) as TextI

        const topStyle: SimpleStyleT | undefined = textObject.styles.find(
            (style) => style.property === 'top'
        )
        const restStyles: SimpleStyleT[] = textObject.styles.filter(
            (style) => style.property !== 'top'
        )
        if (!topStyle) {
            console.error('Not top styles')
            return
        }

        const updatedStyles: SimpleStyleT[] = [
            ...restStyles,
            {
                ...topStyle,
                value: 80,
            },
        ]
        const newTickerTimeline: GSAPTimeline = gsap.timeline({
            paused: true,
            repeat: -1,
        })
        const numberOfChild = maskObject.childIds.length + 1

        const maskAnimations: AnimationI[] = []
        const textAnimations: AnimationI[] = []

        for (let i = 0; i < numberOfChild; i++) {
            const currentSetAnimation = animationScrollSet(textObject, 0)
            textAnimations.push({
                ...currentSetAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUpDown',
                parentId: maskObject.id,
            })

            // move to up
            const currentUpFromToAnimation = animationScrollTranslateY(
                textObject,
                SCROLL_TIME,
                i * 2 * SCROLL_TIME + i * SCROLL_PAUSE,
                0,
                -100
            )
            textAnimations.push({
                ...currentUpFromToAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUpDown',
                parentId: maskObject.id,
            })
            // pause
            const pauseAnimation = animationScrollTranslateY(
                textObject,
                SCROLL_PAUSE,
                (i * 2 + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
                -100,
                -100
            )
            textAnimations.push({
                ...pauseAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUpDown',
                parentId: maskObject.id,
            })
            // move current by one text down
            const currentDownAnimation = animationScrollTranslateY(
                textObject,
                SCROLL_TIME,
                (i * 2 + 1) * SCROLL_TIME + (i + 1) * SCROLL_PAUSE,
                -100,
                0
            )
            textAnimations.push({
                ...currentDownAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUpDown',
                parentId: maskObject.id,
            })
        }

        dispatch(
            createTickerStructureAction({
                object: {
                    ...object,
                    parentId: parentId,
                },
                maskObject: { ...maskObject, duration: SCROLL_TIME, delay: SCROLL_PAUSE },
                maskAnimations: maskAnimations,
                textObject: { ...textObject, styles: updatedStyles },
                textAnimations,
                updatedAt: Date.now(),
            })
        )

        dispatch(initSubTimelineAction({ id: maskObject.id, timeline: newTickerTimeline }))

        dispatch(clearSelectedAnimationsAction())
        dispatch(setActiveObjectAction({ id: object.id }))
    }

    return {
        addCrawlStaggeredTicker,
        addCrawlContinuousTicker,
        addScrollDownTicker,
        addScrollUpTicker,
        addScrollUpDownTicker,
    }
}
