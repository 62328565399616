import { useMemo } from 'react'
import { selectUndoable } from '../../../../helpers/selector.helpers'
import useObject from '../../../../hooks/useObject'
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux'
import { useTickerTimeline } from '../../../../hooks/useTickerTimeline'
import {
    addTextCrawlContinuousAction,
    addTextCrawlStaggeredAction,
    addTextScrollDownAction,
    addTextScrollUpAction,
    addTextScrollUpDownAction,
    deleteTickerTextAction,
    selectObjectsByIds,
    updateCrawlPropertyAction,
    updateScrollPropertyAction,
    updateTextCrawlActionAsync,
    updateTickerTextsAttributeAction,
    updateTickerTextsPropertyAction,
    updObjectAction,
} from '../../../../store/slices/objects.slice'
import BorderRadius from '../ExpandableSections/BorderRadius'
import Fill from '../ExpandableSections/Fill'
import SizeAndPosition from '../ExpandableSections/SizeAndPosition'
import CrawlTools from './CrawlTools'
import ScrollTools from './ScrollTools'
import TickerFont from './TickerFont'
import TickerText from './TickerText'

interface Props {
    object: TickerI
    handleSetStyleValue: (property: any, value: any) => void
    handleSetObjectValue: (property: any, value: any) => void
    handleSetAttributeValue: (property: any, value: any) => void
}

const TickerTools = ({
    object,
    handleSetStyleValue,
    handleSetObjectValue,
    handleSetAttributeValue,
}: Props) => {
    const dispatch = useAppDispatch()

    // find every Texts Object in structure
    const { findObjectById } = useObject()
    const objectsState = useAppSelector((state) => selectUndoable(state).objects)
    const subTimelines = useAppSelector((state) => state.subTimelines.value)

    const maskObject = useMemo(() => {
        return findObjectById(object.childIds[0]) as TickerI
    }, [findObjectById, object.childIds])

    const textObjects: TextI[] = useMemo(() => {
        return maskObject && maskObject.childIds
            ? (selectObjectsByIds(objectsState, maskObject?.childIds) as TextI[])
            : []
    }, [maskObject, objectsState])

    const actualTimeline = useMemo(() => {
        if (maskObject) return subTimelines[maskObject.id]
    }, [maskObject, subTimelines])

    const { playTimeline, pauseTimeline } = useTickerTimeline(object, textObjects, actualTimeline)

    const handleAddCrawlTextTicker = () => {
        if (maskObject.tickerType === 'crawl-continuous') {
            dispatch(addTextCrawlContinuousAction({ maskObject }))
        } else if (maskObject.tickerType === 'crawl-staggered') {
            dispatch(addTextCrawlStaggeredAction({ maskObject }))
        }
    }
    const handleAddScrollTextTicker = () => {
        actualTimeline?.clear()
        if (maskObject.tickerType === 'scroll-up-down') {
            dispatch(addTextScrollUpDownAction({ maskObject }))
        } else if (maskObject.tickerType === 'scroll-up') {
            dispatch(addTextScrollUpAction({ maskObject }))
        } else if (maskObject.tickerType === 'scroll-down') {
            dispatch(addTextScrollDownAction({ maskObject }))
        }
    }

    const handleDeleteText = (deleteObject: AnySceneObjectT) => {
        dispatch(
            deleteTickerTextAction({
                object: object,
                maskObject: maskObject,
                textObject: deleteObject as TextI,
                textObjects: textObjects,
            })
        )
    }

    const handleUpdateText = (objectId: string, property: string, value: string) => {
        if (object.tickerType.startsWith('scroll')) {
            dispatch(updObjectAction({ id: objectId, property: property, value: value }))
        } else {
            dispatch(
                updateTextCrawlActionAsync(
                    object,
                    maskObject,
                    textObjects,
                    objectId,
                    property,
                    value
                )
            )
        }
    }

    const handleSetTickerStyleValue = (property: any, value: any) => {
        actualTimeline?.clear()

        if (object.tickerType.startsWith('scroll')) {
            dispatch(
                updateScrollPropertyAction({ object, maskObject, textObjects, property, value })
            )
        } else {
            dispatch(
                updateCrawlPropertyAction({ object, maskObject, textObjects, property, value })
            )
        }
    }
    const handleSetTextsStyleValue = (property: any, value: any) => {
        dispatch(
            updateTickerTextsPropertyAction({ object, maskObject, textObjects, property, value })
        )
    }
    const handleSetIsEditable = (property: any, value: any) => {
        dispatch(updateTickerTextsAttributeAction({ textObjects, property, value }))
    }

    return (
        <div>
            {object.tickerType.startsWith('crawl') ? (
                <CrawlTools
                    object={object}
                    maskObject={maskObject}
                    textObjects={textObjects}
                    playTimeline={playTimeline}
                    pauseTimeline={pauseTimeline}
                />
            ) : (
                <ScrollTools
                    object={object}
                    maskObject={maskObject}
                    textObjects={textObjects}
                    playTimeline={playTimeline}
                    pauseTimeline={pauseTimeline}
                />
            )}
            <TickerText
                textObjects={textObjects}
                handleUpdateText={handleUpdateText}
                handleDeleteText={handleDeleteText}
                handleAddTextTicker={
                    object.tickerType.startsWith('crawl')
                        ? handleAddCrawlTextTicker
                        : handleAddScrollTextTicker
                }
                handleSetIsEditable={handleSetIsEditable}
            />
            <SizeAndPosition
                expanded={false}
                object={object}
                handleSetValue={handleSetTickerStyleValue}
            />
            <Fill
                expanded={false}
                object={object}
                handleSetStyleValue={handleSetStyleValue}
                handleSetObjectValue={handleSetObjectValue}
                handleSetAttributeValue={handleSetAttributeValue}
            />
            {textObjects.length > 0 && (
                <TickerFont
                    tickerType={object.tickerType.startsWith('crawl') ? 'crawl' : 'scroll'}
                    textObjects={textObjects}
                    handleSetStyleValue={handleSetTextsStyleValue}
                />
            )}
            <BorderRadius object={object} handleSetValue={handleSetStyleValue} />
        </div>
    )
}

export default TickerTools
