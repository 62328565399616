import { selectUndoable } from '../../helpers/selector.helpers'
import { clearAllSelectedObjectsAction } from '../../store/slices/activeObject.slice'
import {
    delMultiObjectsAction,
    duplicateObjects,
    selectObjectsByIds,
} from '../../store/slices/objects.slice'
import { useAppDispatch, useAppSelector } from '../useRedux'

const useMultiselectObjectAction = () => {
    const dispatch = useAppDispatch()
    const selectedObjectIds: string[] = useAppSelector((state) => state.activeObject.selected)

    const objects = useAppSelector((state) => selectUndoable(state).objects)

    const handleDuplicateObjects = () => {
        dispatch(duplicateObjects(selectObjectsByIds(objects, selectedObjectIds)))
    }

    const handleDeleteObjects = () => {
        dispatch(delMultiObjectsAction(selectObjectsByIds(objects, selectedObjectIds)))
        dispatch(clearAllSelectedObjectsAction())
    }

    return {
        handleDuplicateObjects,
        handleDeleteObjects,
    }
}

export default useMultiselectObjectAction
