import ArticleIcon from '@mui/icons-material/Article'
import { Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useTickerCreateAction } from '../../../hooks/useTickerCreateAction'
import ButtonContextMenu from '../../common/ContextMenu/ButtonContextMenu'

const ScrollTickerButton = () => {
    const { addScrollDownTicker, addScrollUpTicker, addScrollUpDownTicker } =
        useTickerCreateAction()

    return (
        <Tooltip title={t('editor:tickers.addScrollTickerTooltip')}>
            <span>
                <ButtonContextMenu
                    title={t('editor:tickers.addScrollTickerButton')}
                    icon={<ArticleIcon fontSize="small" />}
                    data={[
                        {
                            title: t('editor:tickers.scrollDown'),
                            onClick: () => addScrollDownTicker(null),
                        },
                        {
                            title: t('editor:tickers.scrollUp'),
                            onClick: () => addScrollUpTicker(null),
                        },
                        {
                            title: t('editor:tickers.scrollUpDown'),
                            onClick: () => addScrollUpDownTicker(null),
                        },
                    ]}
                />
            </span>
        </Tooltip>
    )
}

export default ScrollTickerButton
