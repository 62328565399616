import { PayloadAction } from '@reduxjs/toolkit'
import {
    recreateCrawlContinuousAnimations,
    recreateCrawlStaggeredAnimations,
} from '../../../../helpers/crawlTicker.helpers'
import { updateObjectStyle } from '../../../../helpers/object.helpers'
import { ObjectsStateT } from '../../objects.slice'
import { UpdatePropertyTicker } from './updateScrollPropertyBaseAction'

export const updateCrawlPropertyBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdatePropertyTicker>
) => {
    const { object, maskObject, textObjects, property, value } = action.payload
    const currentObjectValue = object.styles.find((style) => style.property === property)?.value
    if (Number(currentObjectValue) === Number(value)) {
        return
    }
    if (property === 'width') {
        state.value = {
            ...state.value,
            [object.id]: {
                ...state.value[object.id],
                styles: updateObjectStyle(object, property, value) ?? state.value[object.id].styles,
            },
        }
    } else if (property === 'height') {
        state.value = {
            ...state.value,
            [object.id]: {
                ...state.value[object.id],
                styles: updateObjectStyle(object, property, value) ?? state.value[object.id].styles,
            },
            [maskObject.id]: {
                ...state.value[maskObject.id],
                styles:
                    updateObjectStyle(maskObject, property, value) ??
                    state.value[maskObject.id].styles,
            },
        }
        //update styles for text objects
        textObjects.forEach((textObject) => {
            state.value = {
                ...state.value,
                [textObject.id]: {
                    ...textObject,
                    styles:
                        updateObjectStyle(textObject, property, value) ??
                        state.value[textObject.id].styles,
                },
            }
        })
    } else {
        state.value = {
            ...state.value,
            [object.id]: {
                ...state.value[object.id],
                styles: updateObjectStyle(object, property, value) ?? state.value[object.id].styles,
            },
        }
    }

    let animation: AnimationI | null = null
    const modifiedTextObjects = maskObject.childIds.map((childId) => state.value[childId] as TextI)
    if (object.tickerType === 'crawl-continuous') {
        animation = recreateCrawlContinuousAnimations(
            state.value[object.id] as TickerI,
            state.value[maskObject.id] as TickerI,
            modifiedTextObjects
        )
    } else if (object.tickerType === 'crawl-staggered') {
        animation = recreateCrawlStaggeredAnimations(
            state.value[object.id] as TickerI,
            state.value[maskObject.id] as TickerI,
            modifiedTextObjects
        )
    }
    if (animation) {
        const animations: Record<string, AnimationI> = {}

        animations[animation.id] = animation

        state.value = {
            ...state.value,
            [maskObject.id]: {
                ...state.value[maskObject.id],
                animations: animations,
            },
        }
    }
    state.updatedAt = Date.now()
}
