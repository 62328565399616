import { RefreshRounded } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRefreshAllStyles } from '../../../hooks/editor/useRefreshAllStyles'

const RefreshStyle = () => {
    const { t } = useTranslation()
    const { refreshAllStyles } = useRefreshAllStyles()

    const handleRefresh = () => refreshAllStyles()

    return (
        <Tooltip title={t('menu:refreshScene')}>
            <IconButton onClick={handleRefresh}>
                <RefreshRounded fontSize="small" />
            </IconButton>
        </Tooltip>
    )
}
export default RefreshStyle
