import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
export type TickerStructure = {
    object: AnySceneObjectT
    maskAnimations: AnimationI[]
    maskObject: AnySceneObjectT
    textObject: AnySceneObjectT
    textAnimations: AnimationI[] | null
    updatedAt: number
}
export const createTickerStructureBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<TickerStructure>
) => {
    const object = action.payload.object
    const maskObject = action.payload.maskObject
    const textObject = action.payload.textObject
    const maskAnimations = action.payload.maskAnimations
    const arrayTextAnimations = action.payload.textAnimations
    const updatedAt = action.payload.updatedAt

    const animations: Record<string, AnimationI> = {}
    const textAnimations: Record<string, AnimationI> = {}

    maskAnimations.forEach((animation) => {
        animations[animation.id] = animation
    })
    arrayTextAnimations &&
        arrayTextAnimations.forEach((animation) => {
            textAnimations[animation.id] = animation
        })

    if (object.parentId) {
        state.value = {
            ...state.value,
            [object.parentId]: {
                ...state.value[object.parentId],
                childIds: [...state.value[object.parentId].childIds, object.id],
            },
        }
    }

    state.value = {
        ...state.value,
        [object.id]: {
            ...object,
            childIds: [maskObject.id],
        },
        [maskObject.id]: {
            ...maskObject,
            childIds: [textObject.id],
            parentId: object.id,
            animations: animations,
        },
        [textObject.id]: { ...textObject, animations: textAnimations, parentId: maskObject.id },
    }
    state.updatedAt = updatedAt
}
