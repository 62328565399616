import ArticleIcon from '@mui/icons-material/Article'
import { Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useTickerCreateAction } from '../../../hooks/useTickerCreateAction'
import ButtonContextMenu from '../../common/ContextMenu/ButtonContextMenu'

const CrawlTickerButton = () => {
    const { addCrawlContinuousTicker, addCrawlStaggeredTicker } = useTickerCreateAction()

    return (
        <Tooltip title={t('editor:tickers.addCrawlTickerTooltip')}>
            <span>
                <ButtonContextMenu
                    title={t('editor:tickers.addCrawlTickerButton')}
                    icon={<ArticleIcon fontSize="small" />}
                    data={[
                        {
                            title: t('editor:tickers.crawlContinuous'),
                            onClick: () => addCrawlContinuousTicker(null),
                        },
                        {
                            title: t('editor:tickers.crawlStaggered'),
                            onClick: () => addCrawlStaggeredTicker(null),
                        },
                    ]}
                />
            </span>
        </Tooltip>
    )
}

export default CrawlTickerButton
