import { PayloadAction } from '@reduxjs/toolkit'
import { sumArray } from '../../../../helpers/array.helpers'
import {
    recreateCrawlContinuousAnimations,
    recreateCrawlStaggeredAnimations,
} from '../../../../helpers/crawlTicker.helpers'
import { ObjectsStateT } from '../../objects.slice'
import { objectNodeUpdate } from '../object/objectNodeUpdate'
export type UpdateTextCrawl = {
    object: TickerI
    maskObject: TickerI
    textObjects: TextI[]
    id: string
    property: string
    value: string
    newText: string
}

export const updateTextCrawlBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateTextCrawl>
) => {
    const object = action.payload.object
    const maskObject = action.payload.maskObject
    const textObjects = action.payload.textObjects
    const value = action.payload.value
    const property = action.payload.property
    const newText = action.payload.newText
    let lastObject: AnySceneObjectT | null = null
    let updatedCrawlTextObjects: TextI[] = []
    let newWidths: Record<string, number> = {}

    const id = action.payload.id
    state.value = {
        ...state.value,
        [id]: objectNodeUpdate(state.value[id], { id, property, value }),
    }
    let modifiedTextObjects: TextI[] = []
    textObjects.forEach((textObject) =>
        textObject.id === id
            ? modifiedTextObjects.push(
                  objectNodeUpdate(state.value[id], { id, property, value }) as TextI
              )
            : modifiedTextObjects.push(textObject)
    )
    modifiedTextObjects.forEach(async (textObject) => {
        const element = document.getElementById(textObject.id) as HTMLElement
        const clone = element.cloneNode(true) as HTMLElement
        clone.id = 'clone'
        // const newText = await tweenly.processField(value, assets as Asset[])

        if (textObject.id === id && newText) {
            // clone.textContent = newText
            clone.textContent = newText
        }
        const parent =
            textObject.parentId && (document.getElementById(textObject.parentId) as HTMLElement)
        if (parent) {
            parent.appendChild(clone)
            newWidths[textObject.id] = clone.clientWidth
            parent.removeChild(clone)
        }
    })

    modifiedTextObjects
        .sort((a, b) => a.index - b.index)
        .forEach((obj) => {
            if (lastObject === null) lastObject = obj
            if (obj.id !== lastObject.id) {
                const lastObjectDiv = document.getElementById(lastObject.id)
                if (lastObjectDiv) {
                    // const lastObjectClientWidth = newWidths[lastObject.id]
                    // // const lastObjectLeft = lastObject.styles.find(
                    // //     (style) => style.property === 'left'
                    // // )?.value

                    // if (lastObjectClientWidth !== undefined) {
                    const updatedStyles = obj.styles.map((style) => {
                        if (style.property === 'left') {
                            return {
                                ...style,
                                // value: Number(lastObjectLeft) + Number(lastObjectClientWidth),
                                value: 0,
                            }
                        }
                        return style
                    })
                    updatedCrawlTextObjects.push({ ...obj, styles: updatedStyles })
                    //set this modified object as lastObject for next iteration
                    lastObject = { ...obj, styles: updatedStyles }
                    // }
                }
            }
        })
    //update state,value with updatedObject with new left styles
    state.value = updatedCrawlTextObjects.reduce((newState, object, index) => {
        return {
            ...newState,
            [object.id]: {
                ...state.value[object.id],
                styles: object.styles,
            },
        }
    }, state.value)

    let crawlAnimation: AnimationI | null = null
    switch (object.tickerType) {
        case 'crawl-continuous':
            crawlAnimation = recreateCrawlContinuousAnimations(
                state.value[object.id] as TickerI,
                state.value[maskObject.id] as TickerI,
                modifiedTextObjects,
                sumArray(Object.values(newWidths))
            )
            break
        case 'crawl-staggered':
            crawlAnimation = recreateCrawlStaggeredAnimations(
                state.value[object.id] as TickerI,
                state.value[maskObject.id] as TickerI,
                modifiedTextObjects,
                sumArray(Object.values(newWidths))
            )
            break
    }
    if (crawlAnimation) {
        const animations: Record<string, AnimationI> = {}
        animations[crawlAnimation.id] = crawlAnimation

        state.value = {
            ...state.value,
            [maskObject.id]: {
                ...state.value[maskObject.id],
                animations: animations,
            },
        }
    }
    state.updatedAt = Date.now()
}
