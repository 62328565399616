import { PayloadAction } from '@reduxjs/toolkit'
import { updateObjectStyle } from '../../../../helpers/object.helpers'
import {
    recreateScrollDownAnimations,
    recreateScrollUpAnimations,
    recreateScrollUpDownAnimations,
} from '../../../../helpers/scrollTicker.helpers'
import { ObjectsStateT } from '../../objects.slice'
export type UpdatePropertyTicker = {
    object: TickerI
    maskObject: TickerI
    textObjects: TextI[]
    property: string | number
    value: any
}
export const updateScrollPropertyBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdatePropertyTicker>
) => {
    const { object, maskObject, textObjects, property, value } = action.payload
    const currentObjectValue = object.styles.find((style) => style.property === property)?.value
    if (Number(currentObjectValue) === Number(value)) {
        return
    }
    if (property === 'width') {
        state.value = {
            ...state.value,
            [object.id]: {
                ...state.value[object.id],
                styles: updateObjectStyle(object, property, value) ?? state.value[object.id].styles,
            },
            [maskObject.id]: {
                ...state.value[maskObject.id],
                styles:
                    updateObjectStyle(maskObject, property, value) ??
                    state.value[maskObject.id].styles,
            },
        }
        //update styles for text objects
        textObjects.forEach((textObject) => {
            state.value = {
                ...state.value,
                [textObject.id]: {
                    ...textObject,
                    styles:
                        updateObjectStyle(textObject, property, value) ??
                        state.value[textObject.id].styles,
                },
            }
        })
    } else if (property === 'height') {
        state.value = {
            ...state.value,
            [object.id]: {
                ...state.value[object.id],
                styles: updateObjectStyle(object, property, value) ?? state.value[object.id].styles,
            },
            [maskObject.id]: {
                ...state.value[maskObject.id],
                styles:
                    updateObjectStyle(
                        maskObject,
                        property,
                        Number(value) * maskObject.childIds.length
                    ) ?? state.value[maskObject.id].styles,
            },
        }
        textObjects.forEach((textObject) => {
            state.value = {
                ...state.value,
                [textObject.id]: {
                    ...textObject,
                    styles:
                        updateObjectStyle(textObject, property, value) ??
                        state.value[textObject.id].styles,
                },
            }
        })
        if (object.tickerType !== 'scroll-up-down') {
            const modifiedTextObjects = maskObject.childIds.map(
                (childId) => state.value[childId] as TextI
            )
            modifiedTextObjects.forEach((textObject, index) => {
                state.value = {
                    ...state.value,
                    [textObject.id]: {
                        ...state.value[textObject.id],
                        styles:
                            updateObjectStyle(
                                state.value[textObject.id],
                                'top',
                                Number(value) * index
                            ) ?? state.value[textObject.id].styles,
                    },
                }
            })
        } else {
            const modifiedTextObjects = maskObject.childIds.map(
                (childId) => state.value[childId] as TextI
            )
            modifiedTextObjects.forEach((textObject) => {
                state.value = {
                    ...state.value,
                    [textObject.id]: {
                        ...textObject,
                        styles:
                            updateObjectStyle(textObject, 'top', Number(value)) ??
                            state.value[textObject.id].styles,
                    },
                }
            })
        }
    } else {
        state.value = {
            ...state.value,
            [object.id]: {
                ...state.value[object.id],
                styles: updateObjectStyle(object, property, value) ?? state.value[object.id].styles,
            },
        }
    }

    const animations: Record<string, AnimationI> = {}

    if (object.tickerType === 'scroll-up') {
        recreateScrollUpAnimations(state.value[maskObject.id] as TickerI).forEach((animation) => {
            animations[animation.id] = animation
        })
    } else if (object.tickerType === 'scroll-down') {
        recreateScrollDownAnimations(state.value[maskObject.id] as TickerI).forEach((animation) => {
            animations[animation.id] = animation
        })
    } else if (object.tickerType === 'scroll-up-down') {
        recreateScrollUpDownAnimations(state.value[maskObject.id] as TickerI, state)
    }
    if (object.tickerType !== 'scroll-up-down') {
        state.value = {
            ...state.value,
            [maskObject.id]: {
                ...state.value[maskObject.id],
                animations: animations,
            },
        }
    }
    state.updatedAt = Date.now()
}
