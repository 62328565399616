import { PayloadAction } from '@reduxjs/toolkit'
import { defaultScrollTickerText } from '../../../../data/defaults/objects.types.defaults'
import { createObject } from '../../../../helpers/creators.helpers'
import {
    animationScrollSet,
    animationScrollTranslateY,
} from '../../../../helpers/subtimeline.helpers'
import { ObjectsStateT } from '../../objects.slice'
import { AddTextTicker } from '../object/objectTypes'

export const addTextScrollUpDownBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<AddTextTicker>
) => {
    const objects = Object.values(state.value)
    let maskObject = action.payload.maskObject as TickerI
    const lastTextObject = objects.find(
        (obj) => obj.id === maskObject.childIds[maskObject.childIds.length - 1]
    )
    let newTextObject = createObject(objects, defaultScrollTickerText, maskObject.id)
    if (lastTextObject) {
        newTextObject = { ...newTextObject, styles: lastTextObject?.styles }
        const width: number = lastTextObject?.styles.find(
            (style) => style.property === 'width'
        )?.value
        const height: number = lastTextObject?.styles.find(
            (style) => style.property === 'height'
        )?.value
        const maskObjectHeight: number = maskObject?.styles.find(
            (style) => style.property === 'height'
        )?.value

        const heightStyle: SimpleStyleT | undefined = newTextObject.styles.find(
            (style) => style.property === 'height'
        )
        const widthStyle: SimpleStyleT | undefined = newTextObject.styles.find(
            (style) => style.property === 'width'
        )
        const topStyle: SimpleStyleT | undefined = newTextObject.styles.find(
            (style) => style.property === 'top'
        )

        const restStyles: SimpleStyleT[] = newTextObject.styles.filter(
            (style) =>
                style.property !== 'height' &&
                style.property !== 'width' &&
                style.property !== 'top'
        )
        if (!heightStyle || !widthStyle || !topStyle) {
            console.error('New text object do not have correct styles')
            return
        }
        const updatedStyles: SimpleStyleT[] = [
            ...restStyles,
            {
                ...heightStyle,
                value: height,
            },
            {
                ...widthStyle,
                value: width,
            },
            {
                ...topStyle,
                value: maskObjectHeight,
            },
        ]

        newTextObject = { ...newTextObject, styles: updatedStyles, parentId: maskObject.id }
        maskObject = { ...maskObject, childIds: [...maskObject.childIds, newTextObject.id] }
        state.value = {
            ...state.value,
            [maskObject.id]: maskObject,
            [newTextObject.id]: newTextObject,
        }

        const SCROLL_TIME = maskObject.duration ?? 2
        const SCROLL_PAUSE = maskObject.delay ?? 5
        const numberOfChild = state.value[maskObject.id].childIds.length

        let textAnimations: AnimationI[] = []

        for (let i = 0; i < numberOfChild; i++) {
            const textObject = Object.values(state.value).find(
                (obj) => obj.id === maskObject.childIds[i]
            )

            if (textObject) {
                const currentSetAnimation = animationScrollSet(textObject, 0)
                textAnimations.push({
                    ...currentSetAnimation,
                    subTimeline: true,
                    subTimelineType: 'scrollUpDown',
                    parentId: maskObject.id,
                })
                // // tl.set(`#${childId}`, { transform: `translateY(${-i * 100}%)` }, 0)
                const currentUpFromToAnimation = animationScrollTranslateY(
                    textObject,
                    SCROLL_TIME,
                    i * 2 * SCROLL_TIME + i * SCROLL_PAUSE,
                    0,
                    -100
                )
                textAnimations.push({
                    ...currentUpFromToAnimation,
                    subTimeline: true,
                    subTimelineType: 'scrollUpDown',
                    parentId: maskObject.id,
                })
                // // pause
                const pauseAnimation = animationScrollTranslateY(
                    textObject,
                    SCROLL_PAUSE,
                    (i * 2 + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
                    -100,
                    -100
                )
                textAnimations.push({
                    ...pauseAnimation,
                    subTimeline: true,
                    subTimelineType: 'scrollUpDown',
                    parentId: maskObject.id,
                })
                // move current by one text down
                const currentDownAnimation = animationScrollTranslateY(
                    textObject,
                    SCROLL_TIME,
                    (i * 2 + 1) * SCROLL_TIME + (i + 1) * SCROLL_PAUSE,
                    -100,
                    0
                )
                textAnimations.push({
                    ...currentDownAnimation,
                    subTimeline: true,
                    subTimelineType: 'scrollUpDown',
                    parentId: maskObject.id,
                })
                const finalTextAnimations: Record<string, AnimationI> = {}

                textAnimations.forEach((animation) => {
                    finalTextAnimations[animation.id] = animation
                })
                state.value = {
                    ...state.value,
                    [textObject.id]: {
                        ...state.value[textObject.id],
                        animations: finalTextAnimations,
                    },
                }
            }
            textAnimations = []
        }
        state.updatedAt = Date.now()
    }
}
