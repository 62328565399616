import { PayloadAction } from '@reduxjs/toolkit'
import {
    animationScrollSet,
    animationScrollTranslateY,
} from '../../../../helpers/subtimeline.helpers'
import { ObjectsStateT } from '../../objects.slice'
import { UpdateTickerAnimationT } from '../animation/animationTypes'

export const updateTickerAnimationBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateTickerAnimationT>
) => {
    const objectId = action.payload.objectId
    const updatedAt = action.payload.updatedAt
    const property = action.payload.property
    const value = action.payload.value

    const maskObject = state.value[objectId] as TickerI
    const maskObjectAnimationType = Object.values(state.value[objectId].animations)[0]
        ?.subTimelineType
    const SCROLL_TIME = property === 'duration' ? value : maskObject.duration ?? 2
    const SCROLL_PAUSE = property === 'delay' ? value : maskObject.delay ?? 5
    const numberOfChild = state.value[objectId].childIds.length
    const textListHeightPerc = 100 / numberOfChild

    const maskAnimations: AnimationI[] = []

    if (maskObjectAnimationType === 'scrollDown') {
        for (let i = 0; i < numberOfChild; i++) {
            const currentAnimation = animationScrollTranslateY(
                state.value[objectId],
                SCROLL_TIME,
                i * SCROLL_TIME + i * SCROLL_PAUSE,
                -100 + i * textListHeightPerc,
                -100 + (i + 1) * textListHeightPerc
            )
            maskAnimations.push({
                ...currentAnimation,
                subTimeline: true,
                subTimelineType: 'scrollDown',
            })

            const pauseAnimation = animationScrollTranslateY(
                maskObject,
                SCROLL_PAUSE,
                (i + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
                -100 + (i + 1) * textListHeightPerc,
                -100 + (i + 1) * textListHeightPerc
            )
            maskAnimations.push({
                ...pauseAnimation,
                subTimeline: true,
                subTimelineType: 'scrollDown',
            })
        }
    } else if (maskObjectAnimationType === 'scrollUp') {
        for (let i = 0; i < numberOfChild; i++) {
            const currentAnimation = animationScrollTranslateY(
                maskObject,
                SCROLL_TIME,
                i * SCROLL_TIME + i * SCROLL_PAUSE,
                -100 + (numberOfChild - i + 1) * textListHeightPerc,
                -100 + (numberOfChild - i) * textListHeightPerc
            )
            maskAnimations.push({
                ...currentAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUp',
            })

            const pauseAnimation = animationScrollTranslateY(
                maskObject,
                SCROLL_PAUSE,
                (i + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
                -100 + (numberOfChild - i) * textListHeightPerc,
                -100 + (numberOfChild - i) * textListHeightPerc
            )
            maskAnimations.push({
                ...pauseAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUp',
            })
        }
    } else if (maskObject.tickerType === 'scroll-up-down') {
        // } else if (maskObjectAnimationType === 'scrollUpDown') { //if create animation for maskObject then, structure of create scrollUpDown
        let textAnimations: AnimationI[] = []
        for (let i = 0; i < numberOfChild; i++) {
            const textObject = Object.values(state.value).find(
                (obj) => obj.id === maskObject.childIds[i]
            )

            if (textObject) {
                //set to 0 but no effect on anomaly, when set maskObject with animation to set -100% height then no effect too
                const currentSetAnimation = animationScrollSet(textObject, 0)
                textAnimations.push({
                    ...currentSetAnimation,
                    subTimeline: true,
                    subTimelineType: 'scrollUpDown',
                    parentId: maskObject.id,
                })

                const currentUpFromToAnimation = animationScrollTranslateY(
                    textObject,
                    SCROLL_TIME,
                    i * 2 * SCROLL_TIME + i * SCROLL_PAUSE,
                    0,
                    -100
                )
                textAnimations.push({
                    ...currentUpFromToAnimation,
                    subTimeline: true,
                    subTimelineType: 'scrollUpDown',
                    parentId: maskObject.id,
                })

                const pauseAnimation = animationScrollTranslateY(
                    textObject,
                    SCROLL_PAUSE,
                    (i * 2 + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
                    -100,
                    -100
                )
                textAnimations.push({
                    ...pauseAnimation,
                    subTimeline: true,
                    subTimelineType: 'scrollUpDown',
                    parentId: maskObject.id,
                })

                const currentDownAnimation = animationScrollTranslateY(
                    textObject,
                    SCROLL_TIME,
                    (i * 2 + 1) * SCROLL_TIME + (i + 1) * SCROLL_PAUSE,
                    -100,
                    0
                )
                textAnimations.push({
                    ...currentDownAnimation,
                    subTimeline: true,
                    subTimelineType: 'scrollUpDown',
                    parentId: maskObject.id,
                })
                const finalTextAnimations: Record<string, AnimationI> = {}

                textAnimations.forEach((animation) => {
                    finalTextAnimations[animation.id] = animation
                })
                state.value = {
                    ...state.value,
                    [textObject.id]: {
                        ...state.value[textObject.id],
                        animations: finalTextAnimations,
                    },
                }
            }
            textAnimations = []
        }
    }

    const animations: Record<string, AnimationI> = {}
    maskAnimations.forEach((animation) => {
        animations[animation.id] = animation
    })

    state.value = {
        ...state.value,
        [objectId]: {
            ...state.value[objectId],
            animations: animations,
            [property]: value,
        } as TickerI,
    }
    state.updatedAt = updatedAt
}
