import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import EditIcon from '@mui/icons-material/Edit'
import EditOffIcon from '@mui/icons-material/EditOff'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import ShareIcon from '@mui/icons-material/Share'
import { Alert, Box, Button, Dialog, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useElementSize } from 'usehooks-ts'
import { Status } from '../../constants/status'
import { defaultCanvas } from '../../data/defaults/objects.types.defaults'
import { dateToString } from '../../helpers/date.helpers'
import {
    ImageSize,
    getAssetTypeMsg,
    getGraphicImageUrl,
    getImageSize,
} from '../../helpers/graphic.helpers'
import { getFullNameString } from '../../helpers/string.helpers'
import { useClipboard } from '../../hooks/useClipboard'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { useReduxForm } from '../../hooks/useReduxForm'
import { useVerifyPermission } from '../../hooks/useVerifyPermission'
import {
    graphicSchema,
    submitAssetAsync,
    submitGraphicAsync,
} from '../../store/slices/graphic.slice'
import { GraphicAsset } from '../../store/slices/graphics.slice'
import PreviewScreenWrapper from '../GraphicCommon/PreviewScreenWrapper'
import LoadingBackdrop from '../common/LoadingBackdrop'
import { Transition } from '../common/Transition'
import Select from '../common/form/Select'
import Switch from '../common/form/Switch'
import Tags from '../common/form/Tags'
import TextField from '../common/form/TextField'
import config from '../../config'

interface Props {
    open: boolean
    fileContent: Blob | undefined
    onClose: () => void
    setFirstPage: () => void
}

function GraphicDetail({ open, fileContent, onClose, setFirstPage }: Props) {
    const theme = useTheme()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const graphic = useAppSelector((state) => state.graphic)
    const user = useAppSelector((state) => state.user)
    const currentCompanies = useAppSelector((state) => state.companies.data)

    const navigate = useNavigate()
    const { share } = useClipboard()
    const clipboardMsgType: string = graphic.data.type ? getAssetTypeMsg(graphic.data.type) : ''
    const { canUpdate } = useVerifyPermission(graphic.data, graphic.data.company)

    const [loadingAssets, setLoadingAssets] = useState<boolean>(true)

    //to do after load graphic from gallery in editor, second load is glitching after that animation is working
    //when using await before dispatch then animation not work
    const handleNavigateToEditor = async () => {
        if (isMobileOnly) {
            navigate(`/app/editor`)
        } else {
            navigate(`/app/editor?id=${graphic.data._id}`)
        }
    }

    const handleNavigateToTest = async () => {
        const link = `${config.testApp}?graphicId=${graphic.data._id}`
        window.open(link, '_blank')
    }

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useReduxForm({
        schema: graphicSchema,
        defaultValues: graphic.data,
    })
    const handleOnSubmit = () => {
        setFirstPage()
        onClose()
    }

    const onSubmitGraphic = async (data: GraphicAsset) => {
        data.userId = user.value._id

        if (graphic.data.type) {
            const msgAssetType: string = getAssetTypeMsg(graphic.data.type)
            await dispatch(
                submitAssetAsync(
                    data,
                    'graphics:successMessage.graphicDetailSave' + msgAssetType,
                    handleOnSubmit
                )
            )
        } else {
            await dispatch(
                submitGraphicAsync(
                    data,
                    null,
                    'graphics:successMessage.graphicDetailSave',
                    handleOnSubmit
                )
            )
        }
    }

    const assetsSrc = getGraphicImageUrl(graphic.data)
    const [ref, { width }] = useElementSize()
    const defaultAspectRation = defaultCanvas.width / defaultCanvas.height

    const previewHeight = (width * defaultCanvas.height) / defaultCanvas.width
    const [assetImageSize, setAssetImageSize] = useState<ImageSize>({
        width: 0,
        height: 0,
    })
    const aspectRatio = assetImageSize.width / assetImageSize.height

    useEffect(() => {
        getImageSize(assetsSrc)
            .then((size) => {
                setAssetImageSize({ width: size.width, height: size.height })
            })
            .catch((err) => {
                setAssetImageSize({ width: 0, height: 0 })
                console.error(err.message)
            })
    }, [assetsSrc])

    //if graphic consist assets then setLoading for waiting translation of assets in preview
    useEffect(() => {
        if (graphic.data && graphic.data.assetsRef?.length === 0) {
            setLoadingAssets(false)
        }
        if (graphic.data && graphic.data.assetsRef?.length !== 0) {
            const timer = setTimeout(() => {
                setLoadingAssets(false)
            }, 1500)
            return () => clearTimeout(timer)
        }
    }, [graphic.data])

    const renderPreview = () => {
        switch (graphic.data.type) {
            case 'image':
            case 'sequence':
                return (
                    <Box
                        ref={ref}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            component={'img'}
                            sx={{
                                height:
                                    aspectRatio >= defaultAspectRation
                                        ? (assetImageSize.height * width) / assetImageSize.width
                                        : previewHeight,
                                width:
                                    aspectRatio >= defaultAspectRation
                                        ? width
                                        : (assetImageSize.width * previewHeight) /
                                          assetImageSize.height,
                            }}
                            alt={graphic.data.name}
                            src={assetsSrc}
                        ></Box>
                    </Box>
                )
            case 'font':
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            fontFamily: graphic.data.name,
                            fontSize: 50,
                        }}
                    >
                        {graphic.data.name}
                    </Box>
                )

            case 'json':
            case 'xml':
            case 'text':
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            fontSize: 30,
                            fontFamily: 'code',
                        }}
                    >
                        {`<DATA SOURCE (${graphic.data.type})>`}
                    </Box>
                )
            default:
                return <PreviewScreenWrapper fileContent={fileContent} />
        }
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth={'xl'}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmitGraphic)}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Grid container p={4} spacing={4} alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        {renderPreview()}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={3}
                        >
                            <Grid item>
                                <Grid
                                    container
                                    pb={2}
                                    alignItems={'center'}
                                    justifyContent="space-between"
                                >
                                    <Grid item>
                                        <Typography variant="h4" gutterBottom>
                                            {graphic.data.isPublished ? (
                                                <EditOffIcon sx={{ mr: 1 }} />
                                            ) : (
                                                <EditIcon sx={{ mr: 1 }} />
                                            )}
                                            {t('graphics:graphicDetailTitle')}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" justifyContent="flex-end">
                                            <Tooltip title={t('graphics:copyUrl')}>
                                                <IconButton
                                                    color="inherit"
                                                    onClick={() =>
                                                        share(
                                                            `graphics:urlSetToClipboardSuccess${clipboardMsgType}`
                                                        )
                                                    }
                                                >
                                                    <ShareIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {!graphic.data.type && (
                                                <>
                                                    {config.testApp && (
                                                        <Button
                                                            variant="text"
                                                            color="inherit"
                                                            onClick={() => handleNavigateToTest()}
                                                        >
                                                            <Typography variant="body1">
                                                                {t('graphics:testButton')}
                                                            </Typography>
                                                        </Button>
                                                    )}
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => handleNavigateToEditor()}
                                                        disabled={graphic.data.isPublished}
                                                    >
                                                        <Typography variant="body1">
                                                            {t('graphics:editorButton')}
                                                        </Typography>
                                                    </Button>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <TextField
                                    control={control}
                                    fullWidth
                                    name="name"
                                    error={errors.name}
                                    label={t('graphics:name')}
                                    disabled={!canUpdate}
                                />
                                <TextField
                                    control={control}
                                    fullWidth
                                    name="description"
                                    label={t('graphics:description')}
                                    error={errors.description}
                                    multiline={true}
                                    rows={4}
                                    disabled={!canUpdate}
                                />
                                <Tags
                                    control={control}
                                    name="tags"
                                    label={t('graphics:tags')}
                                    error={errors.tags}
                                    fullWidth
                                    disabled={!canUpdate}
                                />
                                <Select
                                    control={control}
                                    required
                                    name="companyId"
                                    label={t('company:title')}
                                    fullWidth
                                    labelField="name"
                                    valueField="_id"
                                    error={errors.companyId}
                                    options={currentCompanies}
                                    disabled={!canUpdate}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {graphic.status === Status.GRAPHIC_EXISTS && (
                                    <Alert severity="warning" variant="filled" sx={{ mt: 1 }}>
                                        {t('graphics:warningMessage.graphicExists')}
                                    </Alert>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    {!graphic.data.type && (
                                        <Switch
                                            control={control}
                                            name="isFinal"
                                            error={errors.isFinal}
                                            label={t('graphics:final')}
                                            onIcon={<CheckCircleRoundedIcon />}
                                            disabled={!canUpdate}
                                        />
                                    )}
                                    {graphic.data.isPublished && (
                                        <Tooltip title={t('graphics:isPublished')}>
                                            <PublicRoundedIcon sx={{ width: 20, height: 20 }} />
                                        </Tooltip>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Button
                                    variant="text"
                                    color="inherit"
                                    disabled={isSubmitting}
                                    onClick={() => onClose()}
                                >
                                    <Typography variant="body1">{t('disagree')}</Typography>
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={isSubmitting || !canUpdate}
                                >
                                    <Typography variant="body1">{t('submitShort')}</Typography>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    {graphic.data.isPublished && (
                                        <Typography variant="body2">
                                            {`${t('graphics:offeredFor')} ${
                                                graphic.data.price === 0
                                                    ? t('graphics:free')
                                                    : graphic.data.price + ' ' + t('graphics:coins')
                                            }`}
                                        </Typography>
                                    )}
                                    <Typography variant="caption">
                                        {t('graphics:created')}
                                        {graphic.data.created &&
                                            dateToString(graphic.data.created)}{' '}
                                        {t('graphics:by')}
                                        {
                                            <i>
                                                {!graphic.data.user?.firstName &&
                                                !graphic.data.user?.lastName
                                                    ? t('graphics:anonymous')
                                                    : ''}
                                            </i>
                                        }
                                        {getFullNameString(
                                            graphic.data.user?.firstName,
                                            graphic.data.user?.lastName
                                        )}
                                    </Typography>
                                    <Typography variant="caption">
                                        {t('graphics:lastUpdated')}
                                        {graphic.data.updated &&
                                            dateToString(graphic.data.updated)}{' '}
                                        {t('graphics:by')}
                                        {
                                            <i>
                                                {!graphic.data.updatedBy?.firstName &&
                                                !graphic.data.updatedBy?.lastName
                                                    ? t('graphics:anonymous')
                                                    : ''}
                                            </i>
                                        }
                                        {getFullNameString(
                                            graphic.data.updatedBy?.firstName,
                                            graphic.data.updatedBy?.lastName
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <LoadingBackdrop open={loadingAssets} />
        </Dialog>
    )
}

export default GraphicDetail
