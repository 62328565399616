import { PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { createAnimation } from '../../../../helpers/creators.helpers'
import { ObjectsStateT, filterAndSortSiblings, getObjectByTitle } from '../../objects.slice'
import { selectAnimationsByIds } from '../animation/selectAnimationsByIds'

let newObjectsIdsForSelect: string[] = []

const simpleDuplication = (state: ObjectsStateT, object: AnySceneObjectT, isChild: boolean) => {
    const objects = Object.values(state.value)
    const id: string = !isChild ? object.id : 'i' + uuidv4()
    const index: number = filterAndSortSiblings(objects, object.parentId).length
    let titleIndex: number = 0
    let title: string =
        `${object.title.replace(/( )([c][o][p][y])( )(\d+)$/, '')} copy ` + titleIndex

    //increment titleIndex in copy title while this titleIndex exist
    while (getObjectByTitle(objects, title) !== undefined) {
        title = `${object.title.replace(/( )([c][o][p][y])( )(\d+)$/, '')} copy ` + ++titleIndex
    }

    const newObject: AnySceneObjectT = {
        ...object,
        id,
        index,
        title,
        parentId: object.parentId,
        childIds: [],
        animations: {},
    }
    //create new object
    state.value = {
        ...state.value,
        [newObject.id]: newObject,
    }
    if (!isChild) newObjectsIdsForSelect.push(newObject.id)

    //duplicate animations to new object
    const animations = selectAnimationsByIds(state, Object.keys(object.animations))
    let temporaryDuplicatedAnimation: AnimationI[] = []

    animations.forEach((animation: AnimationI) => {
        temporaryDuplicatedAnimation.push(
            createAnimation(newObject.id, animation.delay, {
                ...animation,
                title: animation.title + ' copy',
            })
        )
    })
    temporaryDuplicatedAnimation.forEach((animation) => {
        state.value = {
            ...state.value,
            [newObject.id]: {
                ...state.value[newObject.id],
                animations: {
                    ...state.value[newObject.id].animations,
                    [animation.id]: animation,
                },
            },
        }
    })

    //if object have childIds then duplicate this child, with parentId: newObject.id
    object.childIds.forEach((childId) => {
        const childObject = objects.find((object) => object?.id === childId)
        if (childObject) simpleDuplication(state, { ...childObject, parentId: newObject.id }, true)
    })

    //if object have parentId, then add newObjectId to parent object like childId
    if (object.parentId)
        state.value = {
            ...state.value,
            [object.parentId]: {
                ...state.value[object.parentId],
                childIds: [...state.value[object.parentId].childIds, newObject.id],
            },
        }
}
export const duplicateObjectsBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<AnySceneObjectT[]>
) => {
    const objectsForDuplicate = action.payload
    objectsForDuplicate.forEach((object) => {
        simpleDuplication(state, object, false)
    })
    //[NOTE]: state.updatedAt = Date.now() must not be set because duplicate action
    // change selectedObjectIds and it invoke updateMasterTimeline in Editor useEffect
}
