import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { RGBColor } from 'react-color'
import { ActionCreators } from 'redux-undo'
import { Status } from '../../constants/status'
import { defaultCanvas } from '../../data/defaults/objects.types.defaults'
import { get, put } from '../../helpers/ajax.helpers'
import { Step } from '../../hooks/useWizard'
import { AppThunkT } from '../store'
import { clearSelectedAnimationsAction } from './activeAnimation.slice'
import { clearAllSelectedObjectsAction } from './activeObject.slice'
import { getArchivedGraphicFulfilled } from './archivedGraphics.slice'
import { getGraphicAssetsAsync } from './assets.slice'
import { getFileFulfilled } from './file.slice'
import { getGraphicFulfilled } from './graphic.slice'
import { updateMasterTimeline } from './masterTimeline.slice'
import { clearObjectsAction, clearTmpObjectsAsync } from './objects.slice'
import { getThumbnailFulfilled } from './thumbnail.slice'
import { clearTimelineAction } from './timeline.slice'

export enum InitState {
    InitAndPlay = 'initAndPlay',
    InitAndPause = 'initAndPause',
    HideAndPause = 'hideAndPause',
}
export enum EndState {
    EndAndClear = 'endAndClear',
    EndAndPause = 'endAndPause',
}
export type Timeline = {
    initState: InitState
    endState: EndState
    //TO DO in future extends after move other values from global.d.ts
    // minduration: number
    // repeat: number
    // labels: TimelineLabelI[]
    // freezeTime: number | null
    // onInitCode: CodeTriggerT | null
    // onUpdateCode: CodeTriggerT | null
}
export type RecentColor = {
    color: RGBColor
    hex: string
}

type Settings = {
    canvasOverflowOn: boolean
    timelineRowsExpanded: boolean
    selectedCompanyId: string
    canvasGridOn: boolean
    canvasSnapToGridOn: boolean
    canvasZoom: number
    canvasResolution: number[] //[width, height]
    horizontalSplitterSize: number[]
    subVerticalSplitterSize: number[]
    subHorizontalSplitterSize: number[]
    horizontalLeftSideBarSplitterSize: number[]
    timeline: Timeline
    currentStep: string
    recentColor: RecentColor[]
    fontFamily: string[]
    lockAspectRatio: boolean
    companyId?: string
    galleryFilters: GalleryFilter
    storeFilters: StoreFilter
}

export type SettingsKey = keyof Settings

type Setting = {
    [key in SettingsKey]?: any
}

type GalleryFilter = {
    companyId: string
    attribute: string
    createdBy: string
    sortedBy: string
    type: string
}

type StoreFilter = {
    createdBy: string
    sortedBy: string
}

type StateT = {
    value: {
        timeline: TimelineI
        settings: Settings
        visibleSaveForm: boolean
        openDeleteAlert: boolean
        openDialogToDuplicate: boolean
        openDialogToClearScene: boolean
        versionHistoryDetail: boolean
        signSaveAs: boolean
        prevFile: string
        openMenuWizard: boolean
        openCanvasSettings: boolean
        isTimelineSettingChanged: boolean
    }
    status?: string
}
const DEFAULT_GALLERY_FILTERS = {
    companyId: '',
    attribute: 'pageHeader.filterDefaultValue',
    createdBy: 'pageHeader.filterDefaultValue',
    sortedBy: 'pageHeader.sortedByNewest',
    type: 'all',
}
const DEFAULT_STORE_FILTER = {
    createdBy: 'pageHeader.filterDefaultValue',
    sortedBy: 'pageHeader.sortedByNewest',
}

const initSettings: Settings = {
    canvasOverflowOn: false,
    timelineRowsExpanded: true,
    selectedCompanyId: '',
    canvasGridOn: false,
    canvasSnapToGridOn: false,
    canvasZoom: 100,
    canvasResolution: [defaultCanvas.width, defaultCanvas.height],
    horizontalSplitterSize: [75, 25],
    subVerticalSplitterSize: [66, 34],
    subHorizontalSplitterSize: [25, 75],
    horizontalLeftSideBarSplitterSize: [85, 15],
    timeline: {
        initState: InitState.InitAndPause,
        endState: EndState.EndAndPause,
    },
    currentStep: '',
    recentColor: [],
    lockAspectRatio: false,
    fontFamily: [],
    galleryFilters: DEFAULT_GALLERY_FILTERS,
    storeFilters: DEFAULT_STORE_FILTER,
}

const initialState: StateT = {
    value: {
        timeline: {} as TimelineI,
        settings: initSettings,
        visibleSaveForm: false,
        openDeleteAlert: false,
        openDialogToDuplicate: false,
        openDialogToClearScene: false,
        versionHistoryDetail: false,
        signSaveAs: false,
        prevFile: '',
        openMenuWizard: false,
        openCanvasSettings: false,
        isTimelineSettingChanged: false,
    },
}

export const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        prevSaveFile: (state: StateT, action: PayloadAction<string>) => {
            state.value = {
                ...state.value,
                prevFile: action.payload,
            }
        },
        setOpenSaveForm: (state: StateT, action: PayloadAction<boolean>) => {
            state.value = {
                ...state.value,
                visibleSaveForm: action.payload,
            }
        },
        setOpenDeleteAlert: (state: StateT, action: PayloadAction<boolean>) => {
            state.value = {
                ...state.value,
                openDeleteAlert: action.payload,
            }
        },
        setOpenDialogToDuplicate: (state: StateT, action: PayloadAction<boolean>) => {
            state.value = {
                ...state.value,
                openDialogToDuplicate: action.payload,
            }
        },
        setOpenDialogToClearScene: (state: StateT, action: PayloadAction<boolean>) => {
            state.value = {
                ...state.value,
                openDialogToClearScene: action.payload,
            }
        },
        setOpenCanvasSettings: (state: StateT, action: PayloadAction<boolean>) => {
            state.value = {
                ...state.value,
                openCanvasSettings: action.payload,
            }
        },
        setIsTimelineSettingChanged: (state: StateT, action: PayloadAction<boolean>) => {
            state.value = {
                ...state.value,
                isTimelineSettingChanged: action.payload,
            }
        },
        setLockAspectRationSettings: (state: StateT, action: PayloadAction<boolean>) => {
            state.value = {
                ...state.value,
                settings: {
                    ...state.value.settings,
                    lockAspectRatio: action.payload,
                },
            }
        },
        setOpenMenuWizard: (state: StateT, action: PayloadAction<boolean>) => {
            state.value = {
                ...state.value,
                openMenuWizard: action.payload,
            }
        },
        setOpenVersionHistoryDetail: (state: StateT, action: PayloadAction<boolean>) => {
            state.value = {
                ...state.value,
                versionHistoryDetail: action.payload,
            }
        },
        setSignSaveAs: (state: StateT, action: PayloadAction<boolean>) => {
            state.value = {
                ...state.value,
                signSaveAs: action.payload,
            }
        },
        getPreferencesPending: (state: StateT) => {
            state.status = undefined
        },
        getPreferencesFulfilled: (state: StateT, action: PayloadAction<Setting>) => {
            const data = action.payload
            state.status = Status.OK
            state.value = {
                ...state.value,
                settings: {
                    ...state.value.settings,
                    ...data,
                },
            }
        },
        getPreferencesRejected: (state: StateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
        setPreferencesPending: (state: StateT) => {
            state.status = undefined
        },
        setPreferencesFulfilled: (state: StateT, action: PayloadAction<Setting>) => {
            //TO DO need to refactor -> set nested object
            state.status = Status.OK
            state.value = {
                ...state.value,
                settings: {
                    ...state.value.settings,
                    ...action.payload,
                },
            }
        },

        setPreferencesRejected: (state: StateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const {
    prevSaveFile,
    setOpenSaveForm,
    setOpenCanvasSettings,
    setIsTimelineSettingChanged,
    setLockAspectRationSettings,
    setOpenVersionHistoryDetail,
    setOpenMenuWizard,
    setSignSaveAs,
    setOpenDeleteAlert,
    setOpenDialogToDuplicate,
    setOpenDialogToClearScene,
    getPreferencesPending,
    getPreferencesFulfilled,
    getPreferencesRejected,
    setPreferencesPending,
    setPreferencesFulfilled,
    setPreferencesRejected,
} = editorSlice.actions
export default editorSlice.reducer

export const getPreferencesAsync = (): AppThunkT => async (dispatch) => {
    try {
        dispatch(getPreferencesPending())
        const preferences = await get<Settings>(`preferences`)
        const steps: Step[] = t('wizard:steps', { returnObjects: true })
        let modifiedPreferences = preferences

        const currentPathName = steps.find((x) => x.key === preferences.currentStep)?.pathname
        const actualPageSteps: Step[] = steps.filter((x) => x.pathname === currentPathName)

        //default first step when user not absolved tutorial
        if (!preferences?.currentStep) {
            modifiedPreferences = {
                ...preferences,
                currentStep: steps[0].key,
            }
        }
        //set first step in page when user refresh page or redirected to another page and back to page where ended tutorial
        if (currentPathName && actualPageSteps[0].key) {
            modifiedPreferences = {
                ...preferences,
                currentStep: actualPageSteps[0].key,
            }
        }

        dispatch(getPreferencesFulfilled(modifiedPreferences))
    } catch (error: any) {
        dispatch(getPreferencesRejected(error.message))
    }
}

export const setRecentColorPreferencesAsync =
    (value: any): AppThunkT =>
    async (dispatch, getState) => {
        try {
            let modifiedRecentColor: RecentColor[] = []
            const recentColor = getState().editor.value.settings.recentColor
            if (recentColor.find((x) => x.hex === value.hex)) {
                return
            } else {
                if (recentColor.length === 10) {
                    const filteredRecentColor = recentColor.filter((x, index) => index !== 9)
                    modifiedRecentColor = [
                        { color: value.rgb, hex: value.hex },
                        ...filteredRecentColor,
                    ]
                } else {
                    modifiedRecentColor = [{ color: value.rgb, hex: value.hex }, ...recentColor]
                }
                dispatch(setPreferencesFulfilled({ recentColor: modifiedRecentColor }))
                await put(`preferences/recentColor`, { data: modifiedRecentColor })
            }
        } catch (error: any) {
            dispatch(setPreferencesRejected(error.message))
        }
    }

export const setFontFamilyPreferencesAsync =
    (value: any): AppThunkT =>
    async (dispatch, getState) => {
        try {
            const fontFamily = getState().editor.value.settings.fontFamily
            let modifiedFondFamily: string[] = []
            if (fontFamily.find((x) => x === value)) {
                const filteredArray = fontFamily.filter((x) => x !== value)
                modifiedFondFamily = [value, ...filteredArray]
            } else if (fontFamily?.length === 5) {
                const filteredArray = fontFamily.filter((x, index) => index !== 4)
                modifiedFondFamily = [value, ...filteredArray]
            } else {
                modifiedFondFamily = [value, ...fontFamily]
            }
            dispatch(setPreferencesFulfilled({ fontFamily: modifiedFondFamily }))
            await put(`preferences/fontFamily`, { data: modifiedFondFamily })
        } catch (error: any) {
            dispatch(setPreferencesRejected(error.message))
        }
    }
export const setLockAspectRationAsync =
    (lockAspectRatio: boolean): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(setPreferencesFulfilled({ lockAspectRatio: !lockAspectRatio }))
            await put(`preferences/lockAspectRatio`, { data: !lockAspectRatio })
        } catch (error: any) {
            dispatch(setPreferencesRejected(error.message))
        }
    }

export const setPreferencesAsync =
    (key: SettingsKey, value: any): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(setPreferencesFulfilled({ [key]: value }))
            await put(`preferences/${key}`, { data: value })
        } catch (error: any) {
            dispatch(setPreferencesRejected(error.message))
        }
    }

export const resetGalleryFiltersPreferencesAsync = (): AppThunkT => async (dispatch) => {
    try {
        dispatch(setPreferencesFulfilled({ galleryFilters: DEFAULT_GALLERY_FILTERS }))
        await put(`preferences/galleryFilters`, { data: DEFAULT_GALLERY_FILTERS })
    } catch (error: any) {
        dispatch(setPreferencesRejected(error.message))
    }
}
export const resetStoreFiltersPreferencesAsync = (): AppThunkT => async (dispatch) => {
    try {
        dispatch(setPreferencesFulfilled({ storeFilters: DEFAULT_STORE_FILTER }))
        await put(`preferences/storeFilters`, { data: DEFAULT_STORE_FILTER })
    } catch (error: any) {
        dispatch(setPreferencesRejected(error.message))
    }
}

export const clear = (): AppThunkT => async (dispatch) => {
    await dispatch(clearObjectsAction())
    await dispatch(clearSelectedAnimationsAction())

    await dispatch(clearTimelineAction())
    await dispatch(updateMasterTimeline(0)) //To DO [NOTE] if clear set timeToSeek to 0 or to value from reducer?
    dispatch(ActionCreators.clearHistory())
}

export const clearAndReset =
    (graphicId?: string): AppThunkT =>
    async (dispatch) => {
        if (graphicId) await dispatch(clearTmpObjectsAsync(graphicId))
        await dispatch(getGraphicFulfilled())
        await dispatch(getGraphicAssetsAsync([], ''))
        await dispatch(getFileFulfilled())
        await dispatch(getArchivedGraphicFulfilled())
        await dispatch(getThumbnailFulfilled({ id: undefined }))
        await dispatch(clear())
    }

export const undo =
    (refreshAllStyles: () => void): AppThunkT =>
    async (dispatch, getState) => {
        const state = getState()
        const timeToSeek = state.masterTimeline.time

        const prevState = state.undoable.past[state.undoable.past.length - 1]
        if (!prevState.objects.value[state.activeObject.selected[0]!]) {
            await dispatch(clearAllSelectedObjectsAction())
        }

        await dispatch(ActionCreators.undo())
        await dispatch(updateMasterTimeline(timeToSeek, refreshAllStyles))
    }

export const redo =
    (refreshAllStyles: () => void): AppThunkT =>
    async (dispatch, getState) => {
        const state = getState()
        const timeToSeek = state.masterTimeline.time
        await dispatch(ActionCreators.redo())
        await dispatch(updateMasterTimeline(timeToSeek, refreshAllStyles))
    }
