import { PayloadAction } from '@reduxjs/toolkit'
import { getAbsoluteTopLeftPosition } from '../../../../helpers/object.helpers'
import { filterAndSortSiblings, ObjectsStateT } from '../../objects.slice'
import { checkIndexesBase } from './checkIndexesBase'

export const moveSelectedHereBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<{ object: AnySceneObjectT; parent: AnySceneObjectT }>
) => {
    const object = action.payload.object
    const prevParentId = object.parentId
    const parent = action.payload.parent

    // if parent and selected object are same then return
    if (parent && object.id === parent.id) return
    //if parent isn`t item type then return
    if (parent && parent.type !== 'item') return

    //delete selected object from parent childIds
    if (prevParentId) {
        state.value = {
            ...state.value,
            [prevParentId]: {
                ...state.value[prevParentId],
                childIds: state.value[prevParentId].childIds.filter((id) => id !== object.id),
            },
        }
    }
    const restStyles = object.styles.filter(
        (style) => style.property !== 'top' && style.property !== 'left'
    )
    const topStyle = object.styles.find((style) => style.property === 'top')
    const leftStyle = object.styles.find((style) => style.property === 'left')
    const absolutePositionOfObject = getAbsoluteTopLeftPosition(state, object)
    const absolutePositionOfParent = getAbsoluteTopLeftPosition(state, parent)
    const absoluteLeftValue =
        Number(absolutePositionOfObject.left) - Number(absolutePositionOfParent.left)
    const absoluteTopValue =
        Number(absolutePositionOfObject.top) - Number(absolutePositionOfParent.top)

    if (!leftStyle || !topStyle) return

    state.value = {
        ...state.value,
        [object.id]: {
            ...state.value[object.id],
            parentId: parent.id,
            styles: [
                ...restStyles,
                { ...leftStyle, value: absoluteLeftValue },
                { ...topStyle, value: absoluteTopValue },
            ],
        },
        [parent.id]: {
            ...state.value[parent.id],
            childIds: [...state.value[parent.id].childIds, object.id],
        },
    }

    //check indexes
    const parentSiblings = filterAndSortSiblings(Object.values(state.value), parent.id)
    state.value = {
        ...state.value,
        [object.id]: {
            ...state.value[object.id],
            index: parentSiblings?.length || 0,
        },
    }
    //check indexes for prevParentId group of objects
    checkIndexesBase(state, prevParentId)
    //check indexes for parent.id group of objects
    checkIndexesBase(state, parent.id)
}
