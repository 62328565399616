import CloseIcon from '@mui/icons-material/Close'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import SearchIcon from '@mui/icons-material/Search'
import {
    Box,
    Grid,
    IconButton,
    InputAdornment,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FILTER_CREATED_BY } from '../../constants/filterLists'
import { ORDER_STORE } from '../../constants/sortLists'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import {
    resetStoreFiltersPreferencesAsync,
    setPreferencesAsync,
} from '../../store/slices/editor.slice'
import GraphicFilter from '../Graphic/GraphicFilter'
import GraphicSort from '../Graphic/GraphicSort'
import ButtonWithTooltip from '../common/ButtonWithTooltip'

interface Props {
    searchTxt: string
    setSearchTxt: (searchTxt: string) => void
    filterCreatedBy: string
    sortedBy: string
    title: string
}
function PageHeader({ searchTxt, setSearchTxt, sortedBy, filterCreatedBy, title }: Props) {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const storeFilters = useAppSelector((state) => state.editor.value.settings.storeFilters)
    const handleFilterCreatedBy = (event: SelectChangeEvent) => {
        dispatch(
            setPreferencesAsync('storeFilters', {
                ...storeFilters,
                createdBy: event.target.value as string,
            })
        )
    }
    const handleSortedBy = (value: string) => {
        dispatch(
            setPreferencesAsync('storeFilters', {
                ...storeFilters,
                sortedBy: value,
            })
        )
    }
    const handleResetStoreFilter = () => {
        dispatch(resetStoreFiltersPreferencesAsync())
    }

    return (
        <Grid className="store-header" container spacing={3} px={3} pt={3} alignItems="center">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Typography variant="h2" noWrap>
                        {t(title)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} md={9}>
                    <Grid container spacing={1} alignItems={'center'} justifyContent="flex-end">
                        <Grid item md={3} xs={12}>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    height: 45,
                                    borderRadius: 0.5,
                                }}
                            >
                                <TextField
                                    id="search-field"
                                    label={t('search')}
                                    type="text"
                                    fullWidth
                                    name="search"
                                    value={searchTxt}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setSearchTxt(event.target.value)
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: searchTxt && (
                                            <IconButton onClick={() => setSearchTxt('')}>
                                                <CloseIcon />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid container spacing={1} alignItems={'center'}>
                        <Grid item md={9} xs={9}>
                            <GraphicFilter
                                index={'created-by'}
                                label={t('graphics:pageHeader.filterCreatedByLabel')}
                                onChangeFilter={handleFilterCreatedBy}
                                value={filterCreatedBy}
                                arrayOfFilterItem={FILTER_CREATED_BY}
                            />
                        </Grid>
                        <Grid item md={3} xs={3}>
                            <GraphicSort
                                keyValue={'sort-order-by'}
                                handleSortedBy={handleSortedBy}
                                sortedBy={sortedBy}
                                arrayOfSortItems={ORDER_STORE}
                            />
                            <ButtonWithTooltip
                                tooltipTitle={t('graphics:resetFilter')}
                                buttonComponent={
                                    <IconButton onClick={handleResetStoreFilter}>
                                        <FilterAltOffIcon />
                                    </IconButton>
                                }
                                disabled={false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default PageHeader
