import { InputAdornment, TextField } from '@mui/material'
import { t } from 'i18next'
import { KeyboardEvent } from 'react'
import { useController } from '../../../../hooks/useController'
import useKeyDown from '../../../../hooks/useKeyDown'

interface Props {
    inputValue: number
    name: string
    label: string
    handleOnChange: (e: any) => void
    unit?: boolean
}
export const TickerInput = ({ inputValue, name, label, handleOnChange, unit = true }: Props) => {
    const { value, onChange } = useController(inputValue.toString())

    const { handleOnEnterKeyDown } = useKeyDown()

    return (
        <TextField
            fullWidth
            error={+inputValue < 0 ? true : false}
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            onBlur={handleOnChange}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                handleOnEnterKeyDown(e, handleOnChange)
            }
            InputProps={{
                inputProps: { min: 0, max: 99999, step: 1 },
                endAdornment: unit ?? (
                    <InputAdornment position="end">
                        {t('editor:inputAdornmentUnit.sec')}
                    </InputAdornment>
                ),
            }}
        />
    )
}
