import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Button, FormControlLabel, Grid, Switch, Tooltip, Typography } from '@mui/material'
import { t } from 'i18next'
import ExpandableSection from '../../../common/ExpandableSection'
import TickerTextRow from './TickerTextRow'
interface Props {
    textObjects: AnySceneObjectT[]
    handleUpdateText: (objectId: string, property: string, value: string) => void
    handleDeleteText: (object: AnySceneObjectT) => void
    handleSetIsEditable: (property: any, value: any) => void
    handleAddTextTicker: () => void
}
const TickerText = ({
    textObjects,
    handleUpdateText,
    handleDeleteText,
    handleSetIsEditable,
    handleAddTextTicker,
}: Props) => {
    const isTextEditable: boolean = textObjects[0]
        ? textObjects[0].attributes?.find((attribute) => attribute.property === 'isTextEditable')
              ?.value
        : true

    const handleChangeEditable = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        handleSetIsEditable('isTextEditable', !isTextEditable)
    }

    return (
        <ExpandableSection
            expanded={true}
            title={t('editor:tickers.textTitle')}
            secondaryActionComponent={
                <Tooltip title={t('editor:textCanBeChanged')}>
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={isTextEditable}
                                name="isTextEditable"
                                onClick={handleChangeEditable}
                            />
                        }
                        label={<Typography variant="body2">{t('editor:editable')}</Typography>}
                    />
                </Tooltip>
            }
            component={
                <Grid container direction="row" spacing={1}>
                    <Grid item md={12}>
                        {textObjects.map((object, index) => {
                            if (object.type === 'text') {
                                return (
                                    <TickerTextRow
                                        key={object.id + index}
                                        index={index}
                                        object={object}
                                        textsCount={textObjects.length}
                                        handleUpdateText={handleUpdateText}
                                        handleDeleteText={handleDeleteText}
                                        textObjects={textObjects}
                                    />
                                )
                            } else return null
                        })}
                    </Grid>
                    <Grid item md={12}>
                        <Button
                            variant="text"
                            color="success"
                            startIcon={<AddRoundedIcon />}
                            onClick={() => handleAddTextTicker()}
                        >
                            {t('editor:tickers.addTextButton')}
                        </Button>
                    </Grid>
                </Grid>
            }
        />
    )
}

export default TickerText
