import { PayloadAction } from '@reduxjs/toolkit'
import { defaultTickerCrawlText } from '../../../../data/defaults/objects.types.defaults'
import { sumArray } from '../../../../helpers/array.helpers'
import { CRAWL_STAGGERED_DURATION } from '../../../../helpers/crawlTicker.helpers'
import { createObject } from '../../../../helpers/creators.helpers'
import { getElementWidth } from '../../../../helpers/html.helpers'
import { animationCrawlStaggered } from '../../../../helpers/subtimeline.helpers'
import { ObjectsStateT } from '../../objects.slice'
import { AddTextTicker } from '../object/objectTypes'

export const addTextCrawlStaggeredBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<AddTextTicker>
) => {
    const objects = Object.values(state.value)
    const maskObject = action.payload.maskObject as TickerI
    const textObjects = maskObject.childIds.map(
        (childId) => objects.find((obj) => obj.id === childId) as AnySceneObjectT
    )

    const lastTextObject = objects.find(
        (obj) => obj.id === maskObject.childIds[maskObject.childIds.length - 1]
    )

    if (maskObject.parentId && lastTextObject) {
        let newTextObject = createObject(objects, defaultTickerCrawlText, maskObject.id) //defaultTickerCrawlText is with 10px right padding
        newTextObject = { ...newTextObject, styles: lastTextObject.styles }

        const leftStyle: SimpleStyleT | undefined = newTextObject.styles.find(
            (style) => style.property === 'left'
        )

        if (!leftStyle) {
            console.error('No left style found in new text object')
            return
        }

        const restStyles: SimpleStyleT[] = newTextObject.styles.filter(
            (style) => style.property !== 'left'
        )
        const updatedStyles: SimpleStyleT[] = [
            ...restStyles,
            {
                ...leftStyle,
                value: 0,
            },
        ]

        const textObject = { ...newTextObject, styles: updatedStyles }

        const maskObjectElement = document.getElementById(maskObject.id)
        const maskWidth = maskObjectElement?.clientWidth ?? 0
        const textsWidth = sumArray(textObjects.map((x) => getElementWidth(x?.id)))
        const tickerWidth = getElementWidth(maskObject.parentId)
        const duration: number =
            maskObject && maskObject?.duration ? maskObject?.duration : CRAWL_STAGGERED_DURATION
        const crawlSpeed = Number((maskWidth + textsWidth + 185) / duration)

        const crawlAnimation = animationCrawlStaggered(
            maskObject,
            crawlSpeed,
            tickerWidth,
            -Number(textsWidth + 185) // default crawl text width (175px) + right padding 10px
        )

        state.value = {
            ...state.value,
            [maskObject.id]: {
                ...maskObject,
                animations: {
                    [crawlAnimation.id]: {
                        ...crawlAnimation,
                        subTimeline: true,
                        subTimelineType: 'crawlStaggered',
                    },
                },
                childIds: [...maskObject.childIds, textObject.id],
            },
            [textObject.id]: { ...textObject, parentId: maskObject.id },
        }
        state.updatedAt = Date.now()
    }
}
