import RedoRoundedIcon from '@mui/icons-material/RedoRounded'
import UndoRoundedIcon from '@mui/icons-material/UndoRounded'
import {
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Tooltip,
    Typography,
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import { useEffect } from 'react'
import { isMobileOnly, isWindows } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import pathToLogo from '../assets/tweenly-logo.png'
import { Role } from '../constants/role.enum'
import { selectCanRedo, selectCanUndo } from '../helpers/selector.helpers'
import { useRefreshAllStyles } from '../hooks/editor/useRefreshAllStyles'
import { RouteT } from '../hooks/router/useRoute'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { getCompaniesStatsAsync } from '../store/slices/companiesStats.slice'
import { getPreferencesAsync, redo, setPreferencesAsync, undo } from '../store/slices/editor.slice'
import { getTimelinePreferencesAsync } from '../store/slices/timelinePreferences.slice'
import MainMenu from './MainMenu'
import TopNavbarEditorActions from './TopNavbarEditorActions'

interface Props {
    routes: RouteT[]
}

const TopNavbar = ({ routes }: Props) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const canUndo = useAppSelector((state) => selectCanUndo(state))
    const canRedo = useAppSelector((state) => selectCanRedo(state))
    const location = useLocation()
    const user = useAppSelector((state) => state.user.value)
    const currentCompanies = useAppSelector((state) => state.companies.data)
    const filteredCurrentCompanies = currentCompanies.filter((company) =>
        company.usersRef.find(
            (userRef) => userRef.id === user._id && userRef.role > Role.Collaborator
        )
    )
    const { selectedCompanyId } = useAppSelector((state) => state.editor.value.settings)
    const selectedCompanyIdStatus = useAppSelector((state) => state.editor.status)

    const session = useAppSelector((state) => state.session)
    const { refreshAllStyles } = useRefreshAllStyles()

    const handleChangeCompany = (event: SelectChangeEvent<string>) => {
        dispatch(setPreferencesAsync('selectedCompanyId', event.target.value))
    }

    useEffect(() => {
        if (session.data) {
            dispatch(getPreferencesAsync())
            dispatch(getTimelinePreferencesAsync())
            dispatch(getCompaniesStatsAsync())
        }
    }, [dispatch, session.data])

    return (
        <AppBar position="relative" color="default" sx={{ position: 'relative', zIndex: 1400 }}>
            <Toolbar>
                <Grid container direction="row" alignItems={'center'}>
                    <MainMenu routes={routes} />

                    <CardMedia image={pathToLogo} sx={{ width: '109px', height: '15px', mx: 2 }} />

                    {location.pathname === '/app/store' && (
                        <Box sx={{ marginLeft: 'auto', width: 350 }}>
                            {filteredCurrentCompanies.some((x) => !x.isPrivate) && (
                                <Grid
                                    container
                                    alignItems={'center'}
                                    justifyContent={'flex-end'}
                                    spacing={1}
                                >
                                    <Grid item md={4} xs={4}>
                                        <Typography variant="body1">
                                            {t('accountBalance')}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={5} xs={6}>
                                        <FormControl fullWidth sx={{ m: 0, minWidth: 50 }}>
                                            <InputLabel id="companies">
                                                {t('company:title')}
                                            </InputLabel>
                                            <Select
                                                onChange={handleChangeCompany}
                                                labelId="companies"
                                                id="companies"
                                                value={selectedCompanyId}
                                                disabled={!selectedCompanyIdStatus}
                                            >
                                                {filteredCurrentCompanies
                                                    ?.filter((x) => x.isPrivate === false)
                                                    .map((company: any) => {
                                                        return (
                                                            <MenuItem
                                                                key={company._id}
                                                                value={company._id}
                                                            >
                                                                {company.name}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {!selectedCompanyIdStatus ? (
                                        <Box
                                            sx={{
                                                width: 45,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <CircularProgress
                                                size={'medium'}
                                                color="secondary"
                                                sx={{ width: 20 }}
                                            />
                                        </Box>
                                    ) : (
                                        <Grid item md={3} xs={2}>
                                            <Typography variant="body1">
                                                {!filteredCurrentCompanies?.find(
                                                    (x) => x._id === selectedCompanyId
                                                )?.coins
                                                    ? 0
                                                    : filteredCurrentCompanies?.find(
                                                          (x) => x._id === selectedCompanyId
                                                      )?.coins}
                                                {` `}
                                                {t('coins')}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Box>
                    )}
                    {!isMobileOnly && location.pathname === '/app/editor' && (
                        <Grid item flexGrow={1}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item ml={2}>
                                    <Tooltip title={isWindows ? 'Ctrl Z' : '⌘ Z'}>
                                        <IconButton
                                            color="default"
                                            component="span"
                                            onClick={() => dispatch(undo(refreshAllStyles))}
                                            disabled={!canUndo}
                                        >
                                            <UndoRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={isWindows ? '⇧ Ctrl Z' : '⇧ ⌘ Z'}>
                                        <IconButton
                                            color="default"
                                            component="span"
                                            onClick={() => dispatch(redo(refreshAllStyles))}
                                            disabled={!canRedo}
                                        >
                                            <RedoRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <TopNavbarEditorActions />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default TopNavbar
