import FormatAlignCenterRoundedIcon from '@mui/icons-material/FormatAlignCenterRounded'
import FormatAlignLeftRoundedIcon from '@mui/icons-material/FormatAlignLeftRounded'
import FormatAlignRightRoundedIcon from '@mui/icons-material/FormatAlignRightRounded'
import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded'
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded'
import VerticalAlignBottomRoundedIcon from '@mui/icons-material/VerticalAlignBottomRounded'
import VerticalAlignCenterRoundedIcon from '@mui/icons-material/VerticalAlignCenterRounded'
import VerticalAlignTopRoundedIcon from '@mui/icons-material/VerticalAlignTopRounded'

import { SelectChangeEvent } from '@mui/material'
import Grid from '@mui/material/Grid'
import { unionBy } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentCompanyStats } from '../../../../hooks/useCompanyStats'
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux'
import { setFontFamilyPreferencesAsync } from '../../../../store/slices/editor.slice'
import ExpandableSection from '../../../common/ExpandableSection'
import ButtonGroupInput from '../../../common/PropertyTools/ButtonGroupInput'
import ButtonSwitch from '../../../common/PropertyTools/ButtonSwitch'
import ColorPickerInput from '../../../common/PropertyTools/ColorPickerInput'
import NumberInput from '../../../common/PropertyTools/NumberInput'
import SelectDividerInput from '../../../common/PropertyTools/SelectDividerInput'
import SelectInput from '../../../common/PropertyTools/SelectInput'
import { ColorEventT } from '../ExpandableSections/Color'
import { LoadFontButton } from '../LoadFontButton'

interface Props {
    tickerType: 'scroll' | 'crawl'
    textObjects: TextI[]
    handleSetStyleValue: (property: any, value: any) => void
}

const TickerFont = ({ textObjects, handleSetStyleValue, tickerType }: Props) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const object = textObjects[0] // show only first ... on change change same properties for every text

    const preferencesFontFamily: string[] = useAppSelector(
        (state) => state.editor.value.settings.fontFamily
    )

    const { assets: companyAssets } = useCurrentCompanyStats()
    const assets = useAppSelector((state) => state.assets.data)

    const fontFamily: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'fontFamily'
    )
    const fontSize: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'fontSize'
    )
    const fontWeight: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'fontWeight'
    )
    const fontStyle: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'fontStyle'
    )
    const textAlign: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'textAlign'
    )
    const textTransform: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'textTransform'
    )
    const justifyContent: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'justifyContent'
    )

    const color: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'color' || style.property === 'backgroundColor'
    )
    const opacity: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'opacity'
    )

    const handleOnChangeColor = (e: ColorEventT) =>
        handleSetStyleValue(e.target.name, e.target.value)

    const fonts = useMemo(() => {
        return unionBy(
            assets.filter((asset) => asset.type === 'font'),
            companyAssets.filter((asset) => asset.type === 'font'),
            '_id'
        )
    }, [assets, companyAssets])

    useEffect(() => {
        fonts.forEach(async (font) => {
            const fontStyle = document.createElement('style')
            fontStyle.appendChild(
                document.createTextNode(`
                    @font-face {
                        font-family: ${font.name};
                        src: url(${font.url});
                    }
                `)
            )

            document.head.appendChild(fontStyle)
        })
    }, [fonts])

    if (
        !fontFamily &&
        !fontSize &&
        !fontWeight &&
        !fontStyle &&
        !textAlign &&
        !textTransform &&
        !justifyContent
    )
        return <></>

    const handleStyleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        handleSetStyleValue(e.target.name, e.target.value)

    const handleFontFamilyOnChange = (e: SelectChangeEvent<any>) => {
        handleSetStyleValue(fontFamily?.property, e.target.value)
        if ((fontFamily?.input as SelectInputT).props.includes(e.target.value)) {
            dispatch(setFontFamilyPreferencesAsync(e.target.value))
        }
    }

    return (
        <ExpandableSection
            expanded={false}
            title={'Font'}
            component={
                <Grid container spacing={1} alignItems={'center'}>
                    <Grid item>
                        {fontFamily && (
                            <>
                                <SelectDividerInput
                                    value={fontFamily.value}
                                    label={fontFamily.input.label ?? fontFamily.property}
                                    styleProperty={(fontFamily.input as SelectInputT).props}
                                    stylePropertyBeforeDivider={[
                                        ...fonts.map((font) => font.name),
                                        ...preferencesFontFamily,
                                    ]}
                                    handleOnChange={handleFontFamilyOnChange}
                                />
                                <LoadFontButton
                                    onChange={(value) =>
                                        handleSetStyleValue(fontFamily?.property, value)
                                    }
                                />
                            </>
                        )}
                        {fontSize && (
                            <NumberInput
                                styleProperty={fontSize}
                                handleOnChange={handleStyleOnChange}
                            />
                        )}
                        {textTransform && (
                            <SelectInput
                                styleProperty={textTransform}
                                handleOnChange={handleStyleOnChange}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        {fontWeight && (
                            <ButtonSwitch
                                selectedButton={{
                                    value: 'bold',
                                    tooltip: t('editor:textTools.fontWeight.bold'),
                                    icon: <FormatBoldRoundedIcon />,
                                }}
                                unselectedButton={{
                                    value: 'normal',
                                    tooltip: t('editor:textTools.fontWeight.normal'),
                                    icon: <FormatBoldRoundedIcon />,
                                }}
                                property={fontWeight.property}
                                value={fontWeight.value}
                                handleOnChange={handleSetStyleValue}
                            />
                        )}
                        {fontStyle && (
                            <ButtonSwitch
                                selectedButton={{
                                    value: 'italic',
                                    tooltip: t('editor:textTools.fontStyle.italic'),
                                    icon: <FormatItalicRoundedIcon />,
                                }}
                                unselectedButton={{
                                    value: 'normal',
                                    tooltip: t('editor:textTools.fontStyle.normal'),
                                    icon: <FormatItalicRoundedIcon />,
                                }}
                                property={fontStyle.property}
                                value={fontStyle.value}
                                handleOnChange={handleSetStyleValue}
                            />
                        )}
                        {tickerType === 'scroll' && textAlign && (
                            <ButtonGroupInput
                                buttons={[
                                    {
                                        value: 'left',
                                        tooltip: t('editor:textTools.textAlign.left'),
                                        icon: <FormatAlignLeftRoundedIcon />,
                                    },
                                    {
                                        value: 'center',
                                        tooltip: t('editor:textTools.textAlign.center'),
                                        icon: <FormatAlignCenterRoundedIcon />,
                                    },
                                    {
                                        value: 'right',
                                        tooltip: t('editor:textTools.textAlign.right'),
                                        icon: <FormatAlignRightRoundedIcon />,
                                    },
                                ]}
                                property={textAlign.property}
                                value={textAlign.value}
                                handleOnChange={handleSetStyleValue}
                            />
                        )}
                        {justifyContent && (
                            <ButtonGroupInput
                                buttons={[
                                    {
                                        value: 'flex-start',
                                        tooltip: t('editor:textTools.textAlign.top'),
                                        icon: <VerticalAlignTopRoundedIcon />,
                                    },
                                    {
                                        value: 'center',
                                        tooltip: t('editor:textTools.textAlign.center'),
                                        icon: <VerticalAlignCenterRoundedIcon />,
                                    },
                                    {
                                        value: 'flex-end',
                                        tooltip: t('editor:textTools.textAlign.bottom'),
                                        icon: <VerticalAlignBottomRoundedIcon />,
                                    },
                                ]}
                                property={justifyContent.property}
                                value={justifyContent.value}
                                handleOnChange={handleSetStyleValue}
                            />
                        )}
                    </Grid>
                    <Grid item md={12}>
                        {opacity && (
                            <NumberInput
                                styleProperty={opacity}
                                handleOnChange={handleOnChangeColor}
                            />
                        )}

                        {color && (
                            <ColorPickerInput
                                styleProperty={color}
                                handleOnChange={handleOnChangeColor}
                            />
                        )}
                    </Grid>
                </Grid>
            }
        />
    )
}

export default TickerFont
