import { PayloadAction } from '@reduxjs/toolkit'
import { visibility } from '../../../../data/styles/simple/visibility.styles'
import { ObjectsStateT } from '../../objects.slice'

const setVisibilityStyles = (state: ObjectsStateT, object: AnySceneObjectT, isHidden: boolean) => {
    const visibilityStyle: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'visibility'
    )
    let newVisibilityStyle: SimpleStyleT | undefined
    if (!visibilityStyle) {
        newVisibilityStyle = { ...visibility, value: isHidden ? 'hidden' : 'visible' }
    } else {
        newVisibilityStyle = { ...visibilityStyle, value: isHidden ? 'hidden' : 'visible' }
    }

    if (newVisibilityStyle) {
        const restStyles = object.styles.filter((style) => style.property !== 'visibility')
        state.value = {
            ...state.value,
            [object.id]: {
                ...state.value[object.id],
                isHidden: isHidden,
                styles: [...restStyles, newVisibilityStyle],
            },
        }
    }

    object.childIds.forEach((childId) => {
        const object = state.value[childId]
        setVisibilityStyles(state, object, isHidden)
    })
    return state.value
}
export const updateObjectVisibilityStylesBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<{ object: AnySceneObjectT; isHidden: boolean }>
) => {
    const object = action.payload.object
    const isHidden = action.payload.isHidden
    setVisibilityStyles(state, object, isHidden)

    if (object.type === 'ticker') {
        state.updatedAt = Date.now()
    }
}
