import { PayloadAction } from '@reduxjs/toolkit'
import {
    recreateCrawlContinuousAnimations,
    recreateCrawlStaggeredAnimations,
} from '../../../../helpers/crawlTicker.helpers'
import { updateObjectStyle } from '../../../../helpers/object.helpers'
import { ObjectsStateT } from '../../objects.slice'
import { objectNodeUpdate } from '../object/objectNodeUpdate'
import { PayloadObjT } from '../object/objectTypes'

export const resizeCrawlTickerStylesBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<PayloadObjT>
) => {
    const id = action.payload.id
    const widthValue = action.payload.value.find(
        (x: { property: string }) => x.property === 'width'
    ).value
    const heightValue = action.payload.value.find(
        (x: { property: string }) => x.property === 'height'
    ).value
    //update wrapper object
    state.value = {
        ...state.value,
        [id]: objectNodeUpdate(state.value[id], action.payload),
    }
    const object = state.value[id] as TickerI
    const maskObject = object.childIds[0] && (state.value[object.childIds[0]] as TickerI)

    if (maskObject) {
        const textObjects = maskObject.childIds.map((id) => state.value[id] as TextI)

        //update width and height for maskObject
        state.value = {
            ...state.value,
            [maskObject.id]: {
                ...state.value[maskObject.id],
                styles:
                    updateObjectStyle(state.value[maskObject.id], 'width', widthValue) ??
                    state.value[maskObject.id].styles,
            },
        }
        state.value = {
            ...state.value,
            [maskObject.id]: {
                ...state.value[maskObject.id],
                styles:
                    updateObjectStyle(state.value[maskObject.id], 'height', heightValue) ??
                    state.value[maskObject.id].styles,
            },
        }
        //update textObjects - only height property
        textObjects.forEach((textObject) => {
            state.value = {
                ...state.value,
                [textObject.id]: {
                    ...state.value[textObject.id],
                    styles:
                        updateObjectStyle(state.value[textObject.id], 'height', heightValue) ??
                        state.value[textObject.id].styles,
                },
            }
        })
        let animation: AnimationI | null = null
        const modifiedTextObjects = maskObject.childIds.map(
            (childId) => state.value[childId] as TextI
        )
        if (object.tickerType === 'crawl-continuous') {
            animation = recreateCrawlContinuousAnimations(
                state.value[object.id] as TickerI,
                state.value[maskObject.id] as TickerI,
                modifiedTextObjects
            )
        }
        if (object.tickerType === 'crawl-staggered') {
            animation = recreateCrawlStaggeredAnimations(
                state.value[object.id] as TickerI,
                state.value[maskObject.id] as TickerI,
                modifiedTextObjects
            )
        }

        if (animation) {
            const animations: Record<string, AnimationI> = {}

            animations[animation.id] = animation

            state.value = {
                ...state.value,
                [maskObject.id]: {
                    ...state.value[maskObject.id],
                    animations: animations,
                },
            }
        }
    }
    state.updatedAt = Date.now()
}
