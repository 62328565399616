import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ObjectsStateT, selectObjectsByIds } from '../../../../store/slices/objects.slice'
import { selectAnimationById } from '../../../../store/slices/objects.slice/animation/selectAnimationById'
import { TIMELINE_ROW } from '../../../../style/sizing'
import TimelineRow from './TimelineRow'
import TimelineRows from './TimelineRows'

interface Props {
    stateObjects: ObjectsStateT
    object: AnySceneObjectT
    index: number
    timelineRowWidth: number
    isHidingObject: boolean
    isLockingObject: boolean
    rowsExpanded: boolean
    timelineAreaWidth: number
}
export const TimelineRowWrapper = ({
    stateObjects,
    object,
    index,
    timelineRowWidth,
    isHidingObject,
    isLockingObject,
    rowsExpanded,
    timelineAreaWidth,
}: Props) => {
    //[NOTE]: this combination of useEffect and useState is necessary,
    //bcs. rowsExpand sets all objects on same value, but user can expand and collapse each row separately
    const [isExpanded, setIsExpanded] = useState<boolean>(rowsExpanded)
    useEffect(() => {
        setIsExpanded(!rowsExpanded)
    }, [rowsExpanded])

    const handleCollapse = () => {
        setIsExpanded(false)
    }
    const handleExpand = () => {
        setIsExpanded(true)
    }
    const compareAnimations = (a1: string, a2: string) => {
        const animation1 = selectAnimationById(Object.values(stateObjects.value), a1)
        const animation2 = selectAnimationById(Object.values(stateObjects.value), a2)

        if (animation1 && animation2) {
            if (animation1.tween.name < animation2.tween.name) {
                return -1
            }
            if (animation1.tween.name > animation2.tween.name) {
                return 1
            }
            return animation1.delay - animation2.delay
        }
        return 0
    }
    const sortAnimations = (animationIds: string[]): string[] => {
        const sortedAnimations = animationIds.slice().sort(compareAnimations)
        return sortedAnimations
    }
    let animationIdsLength = object.animations ? Object.keys(object.animations).length : 0

    const animationIds = object.animations ? Object.keys(object.animations) : []

    if (!object || (animationIdsLength === 0 && object.childIds.length === 0))
        return <React.Fragment key={index}></React.Fragment>
    if (animationIdsLength === 0 && object.childIds.length > 0)
        return (
            <TimelineRows
                objects={selectObjectsByIds(stateObjects, object.childIds)}
                timelineRowWidth={timelineRowWidth}
                isHidingObject={isHidingObject || (object.isHidden ? object.isHidden : false)}
                isLockingObject={isLockingObject || object.isLocked}
            />
        )
    if (object.type !== 'sequence' && animationIdsLength > 0)
        return (
            <Box
                key={index}
                sx={{
                    width: { timelineAreaWidth } + 'px',
                    marginBottom: TIMELINE_ROW.paddingBottom + 'px',
                }}
            >
                {isExpanded ? (
                    sortAnimations(animationIds).map((animationId: string, index: number) => (
                        <TimelineRow
                            key={index}
                            object={object}
                            animationId={animationId}
                            isMainRow={index === 0}
                            timelineRowWidth={timelineRowWidth}
                            isHidingObject={
                                isHidingObject || (object.isHidden ? object.isHidden : false)
                            }
                            isLockingObject={isLockingObject || object.isLocked}
                            isExpanded={isExpanded}
                            handleCollapse={handleCollapse}
                            handleExpand={handleExpand}
                        />
                    ))
                ) : (
                    <TimelineRow
                        object={object}
                        animationId={null}
                        isMainRow={true}
                        timelineRowWidth={timelineRowWidth}
                        isHidingObject={
                            isHidingObject || (object.isHidden ? object.isHidden : false)
                        }
                        isLockingObject={isLockingObject || object.isLocked}
                        isExpanded={isExpanded}
                        handleCollapse={handleCollapse}
                        handleExpand={handleExpand}
                    />
                )}

                {object.childIds.length > 0 && (
                    <TimelineRows
                        objects={selectObjectsByIds(stateObjects, object.childIds)}
                        timelineRowWidth={timelineRowWidth}
                        isHidingObject={
                            isHidingObject || (object.isHidden ? object.isHidden : false)
                        }
                        isLockingObject={isLockingObject || object.isLocked}
                    />
                )}
            </Box>
        )
    else
        return (
            <Box
                key={index}
                sx={{
                    width: { timelineAreaWidth } + 'px',
                    marginBottom: TIMELINE_ROW.paddingBottom + 'px',
                }}
            >
                <TimelineRow
                    object={object}
                    animationId={null}
                    isMainRow={true}
                    timelineRowWidth={timelineRowWidth}
                    isHidingObject={isHidingObject || (object.isHidden ? object.isHidden : false)}
                    isLockingObject={isLockingObject || object.isLocked}
                    isExpanded={isExpanded}
                    handleCollapse={handleCollapse}
                    handleExpand={handleExpand}
                />
                {/* recursive for TimelineRows */}
                {object.childIds.length > 0 && (
                    <TimelineRows
                        objects={selectObjectsByIds(stateObjects, object.childIds)}
                        timelineRowWidth={timelineRowWidth}
                        isHidingObject={
                            isHidingObject || (object.isHidden ? object.isHidden : false)
                        }
                        isLockingObject={isLockingObject || object.isLocked}
                    />
                )}
            </Box>
        )
}
