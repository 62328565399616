import Grid from '@mui/material/Grid'
import ExpandableSection from '../../../common/ExpandableSection'
import ColorPickerInput from '../../../common/PropertyTools/ColorPickerInput'
import NumberInput from '../../../common/PropertyTools/NumberInput'

export type ColorEventT = {
    target: { name: string; value: any }
}

interface Props {
    title?: string
    object: AnySceneObjectT
    handleSetValue: (property: any, value: any) => void
}

const Color = ({ title, object, handleSetValue }: Props) => {
    const color: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'color' || style.property === 'backgroundColor'
    )
    const opacity: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'opacity'
    )

    const handleOnChange = (e: ColorEventT) => handleSetValue(e.target.name, e.target.value)

    if (object.type === 'item' && !color) return <></>
    if (!color && !opacity) return <></>

    return (
        <ExpandableSection
            expanded={true}
            title={title ?? 'Color'}
            component={
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        {opacity && (
                            <NumberInput styleProperty={opacity} handleOnChange={handleOnChange} />
                        )}
                    </Grid>
                    <Grid item>
                        {color && (
                            <ColorPickerInput
                                styleProperty={color}
                                handleOnChange={handleOnChange}
                            />
                        )}
                    </Grid>
                </Grid>
            }
        />
    )
}

export default Color
