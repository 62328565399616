import { useEffect, useRef } from 'react'
import * as tweenly from 'tweenly'

import { Asset } from 'tweenly/dist/fetchAsset'
import { useAppSelector } from '../useRedux'

export function useCanvasTimer(objects: AnySceneObjectT[]) {
    const assets = useAppSelector((state) => state.assets.data)
    const pendingRefreshAssets = useAppSelector((state) => state.assets.pendingRefreshAssets)

    const intervalRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (pendingRefreshAssets) {
            return
        }
        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
                tweenly.setDynamicTexts(objects, assets as Asset[])
                tweenly.setDynamicImageUrls(objects, assets as Asset[])
            }, 500)
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        }
    }, [objects, assets, pendingRefreshAssets])

    return {}
}
