import CloseIcon from '@mui/icons-material/Close'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import SearchIcon from '@mui/icons-material/Search'
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FILTER_ATTRIBUTE, FILTER_CREATED_BY, FILTER_TYPE } from '../../constants/filterLists'
import { ORDER_STORE } from '../../constants/sortLists'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import {
    resetGalleryFiltersPreferencesAsync,
    setPreferencesAsync,
} from '../../store/slices/editor.slice'
import AssetsFilter from '../Graphic/AssetFilter'
import GraphicFilter from '../Graphic/GraphicFilter'
import GraphicSort from '../Graphic/GraphicSort'
import ButtonWithTooltip from '../common/ButtonWithTooltip'

interface Props {
    searchTxt: string
    setSearchTxt: (searchTxt: string) => void
    filterCreatedBy: string
    filterAttribute: string
    sortedBy: string
    filterCompanyId: string
    filterType: string
    title: string
    createNew: () => void
}
function GalleryPageHeader({
    searchTxt,
    setSearchTxt,
    sortedBy,
    filterCreatedBy,
    filterAttribute,
    filterCompanyId,
    filterType,
    title,
    createNew,
}: Props) {
    const { t } = useTranslation()
    const currentCompanies = useAppSelector((state) => state.companies)
    const graphics = useAppSelector((state) => state.graphics.data)
    const wizardId = graphics.length > 0 ? 'gallery-step-3' : ''
    const dispatch = useAppDispatch()
    const galleryFilters = useAppSelector((state) => state.editor.value.settings.galleryFilters)

    const handleFilterCompany = (event: SelectChangeEvent) => {
        dispatch(
            setPreferencesAsync('galleryFilters', {
                ...galleryFilters,
                companyId: event.target.value,
            })
        )
    }
    const handleFilterAttribute = (event: SelectChangeEvent) => {
        dispatch(
            setPreferencesAsync('galleryFilters', {
                ...galleryFilters,
                attribute: event.target.value as string,
            })
        )
    }
    const handleFilterCreatedBy = (event: SelectChangeEvent) => {
        dispatch(
            setPreferencesAsync('galleryFilters', {
                ...galleryFilters,
                createdBy: event.target.value,
            })
        )
    }
    const handleSortedBy = (value: string) => {
        dispatch(
            setPreferencesAsync('galleryFilters', {
                ...galleryFilters,
                sortedBy: value,
            })
        )
    }
    const handleFilterType = (value: string | null) => {
        dispatch(
            setPreferencesAsync('galleryFilters', {
                ...galleryFilters,
                type: value,
            })
        )
    }
    const handleResetGalleryFilter = () => {
        dispatch(resetGalleryFiltersPreferencesAsync())
    }

    return (
        <Grid className="gallery-header" container py={3} alignItems={'center'}>
            <Grid container spacing={1} py={3} alignItems={'center'} id="gallery-step-1">
                <Grid item md={10} sm={9} xs={6}>
                    <Typography variant="h2" noWrap>
                        {t(title)}
                    </Typography>
                </Grid>
                <Grid item md={2} sm={3} xs={6}>
                    <Grid container justifyContent="flex-end">
                        <Button variant="contained" id={wizardId} onClick={() => createNew()}>
                            {t('graphics:createNew')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} paddingLeft={0}>
                <Grid item md={8} xs={12}>
                    <Grid
                        container
                        spacing={1}
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs={7} sm={7} md={3} lg={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <InputLabel id="companies">{t('graphics:gallery')}</InputLabel>
                                <Select
                                    onChange={handleFilterCompany}
                                    labelId="companies"
                                    id="gallery-step-2"
                                    value={filterCompanyId}
                                >
                                    <MenuItem key={'0'} value={''}>
                                        {t('graphics:pageHeader.filterDefaultValue')}
                                    </MenuItem>
                                    {currentCompanies.data?.map((company: any) => {
                                        return (
                                            <MenuItem key={company._id} value={company._id}>
                                                {company.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={5}>
                            <Box
                                sx={{
                                    display: {
                                        md: 'flex',
                                        sm: 'block',
                                    },
                                    justifyContent: {
                                        md: 'center',
                                        sm: 'unset',
                                    },
                                }}
                            >
                                <AssetsFilter
                                    onChange={handleFilterType}
                                    options={[...FILTER_TYPE]}
                                    value={filterType}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={7} sm={6} md={3}>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    height: 45,
                                    borderRadius: 0.5,
                                }}
                            >
                                <TextField
                                    id="search-field"
                                    label={t('search')}
                                    type="text"
                                    fullWidth
                                    name="search"
                                    value={searchTxt}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setSearchTxt(event.target.value)
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: searchTxt && (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setSearchTxt('')}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Grid
                        container
                        spacing={1}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <Grid item md={5} xs={5}>
                            <GraphicFilter
                                index={'graphics-status'}
                                label={t('graphics:pageHeader.filterStatusLabel')}
                                onChangeFilter={handleFilterAttribute}
                                value={filterAttribute}
                                arrayOfFilterItem={FILTER_ATTRIBUTE}
                            />
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <GraphicFilter
                                index={'created-by'}
                                label={t('graphics:pageHeader.filterCreatedByLabel')}
                                onChangeFilter={handleFilterCreatedBy}
                                value={filterCreatedBy}
                                arrayOfFilterItem={FILTER_CREATED_BY}
                            />
                        </Grid>
                        <Grid item md={3} xs={3}>
                            <GraphicSort
                                keyValue={'sort-order-by'}
                                handleSortedBy={handleSortedBy}
                                sortedBy={sortedBy}
                                arrayOfSortItems={ORDER_STORE}
                            />

                            <ButtonWithTooltip
                                tooltipTitle={t('graphics:resetFilter')}
                                buttonComponent={
                                    <IconButton onClick={handleResetGalleryFilter}>
                                        <FilterAltOffIcon />
                                    </IconButton>
                                }
                                disabled={false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default GalleryPageHeader
