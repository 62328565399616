import { Grid } from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import useFramerate from '../../../../hooks/useFramerate'
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux'
import { updateMasterTimeline } from '../../../../store/slices/masterTimeline.slice'
import {
    durationAnimationAction,
    durationAnimationsAction,
    moveOffsetAnimationsAction,
    relativeDurationAnimationAction,
    relativeDurationAnimationsAction,
    startOffsetAnimationAction,
    startOffsetAnimationsAction,
} from '../../../../store/slices/objects.slice'
import ExpandableSection from '../../../common/ExpandableSection'
import { TimingInput, TimingOffsetInput } from './TimingInput'
import { useRefreshAllStyles } from '../../../../hooks/editor/useRefreshAllStyles'

interface Props {
    animation: AnimationI
    selectedAnimations: AnimationI[]
}

const Timing = ({ animation, selectedAnimations }: Props) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const timeToSeek = useAppSelector((state) => state.masterTimeline.time)
    const { refreshAllStyles } = useRefreshAllStyles()

    const refreshAfterUpdate = () => {
        dispatch(updateMasterTimeline(timeToSeek, refreshAllStyles))
    }

    const { setFramerateValue, getInputAdornment } = useFramerate()
    const inputAdornmentUnit = getInputAdornment()

    const checkDurationValues = (array: AnimationI[], inputValue: number): boolean => {
        if (array.length === 0) {
            return true
        }
        for (let i = 1; i < array.length; i++) {
            if (array[i].duration !== inputValue) {
                return false
            }
        }
        return true
    }
    const checkDelayValues = (array: AnimationI[], inputValue: number): boolean => {
        if (array.length === 0) {
            return true
        }

        // Loop through the array and check if all delays are the same
        for (let i = 1; i < array.length; i++) {
            if (array[i].delay !== inputValue) {
                return false
            }
        }

        // If all delays are the same, return true
        return true
    }
    const handleDuration = (e: ChangeEvent<HTMLInputElement>) => {
        const offset = setFramerateValue(Number(e.target.value))
        const canUpdate = !checkDurationValues(selectedAnimations, offset)

        if (e.target.value !== '' && e.target.value !== '*') {
            if (selectedAnimations.length < 2) {
                if (animation.duration !== offset) {
                    dispatch(
                        durationAnimationAction({ animation, offset, objectId: animation.objectId })
                    )
                    refreshAfterUpdate()
                }
            } else {
                if (canUpdate) {
                    dispatch(durationAnimationsAction({ selectedAnimations, offset }))
                    refreshAfterUpdate()
                }
            }
        }
    }

    const handleStartOffset = (e: ChangeEvent<HTMLInputElement>): void => {
        const offset = setFramerateValue(Number(e.target.value))
        const canUpdate = !checkDelayValues(selectedAnimations, offset)
        if (e.target.value !== '' && e.target.value !== '*') {
            if (selectedAnimations.length < 2) {
                if (animation.delay !== offset) {
                    dispatch(
                        startOffsetAnimationAction({
                            animation,
                            offset,
                            objectId: animation.objectId,
                        })
                    )
                    refreshAfterUpdate()
                }
            } else {
                if (canUpdate) {
                    dispatch(
                        startOffsetAnimationsAction({
                            selectedAnimations,
                            offset,
                        })
                    )
                    refreshAfterUpdate()
                }
            }
        }
    }

    const handleRelativeDuration = (e: ChangeEvent<HTMLInputElement>): void => {
        const offset = setFramerateValue(Number(e.target.value))
        if (offset !== 0)
            if (selectedAnimations.length < 2) {
                dispatch(
                    relativeDurationAnimationAction({
                        animation,
                        offset,
                        objectId: animation.objectId,
                    })
                )
                refreshAfterUpdate()
            } else {
                dispatch(
                    relativeDurationAnimationsAction({
                        selectedAnimations,
                        offset,
                    })
                )
                refreshAfterUpdate()
            }
    }

    const handleRelativeStartOffset = (e: ChangeEvent<HTMLInputElement>): void => {
        const offset = setFramerateValue(Number(e.target.value))
        if (offset !== 0) {
            dispatch(
                moveOffsetAnimationsAction({
                    selectedAnimations,
                    offset,
                })
            )
            refreshAfterUpdate()
        }
    }

    const getSelectedAnimationsDuration = (): string => {
        let duration: number = 0
        Object.values(selectedAnimations).forEach((animation: AnimationI) => {
            duration =
                duration === 0
                    ? animation.duration
                    : animation.duration === duration
                    ? duration
                    : -1
        })
        return duration < 0 ? '*' : duration.toString()
    }

    const getSelectedAnimationsDelay = (): string => {
        let delay: number = -2
        Object.values(selectedAnimations).forEach((animation: AnimationI) => {
            delay = delay === -2 ? animation.delay : animation.delay === delay ? delay : -1
        })
        return delay < 0 ? '*' : delay.toString()
    }

    return (
        <ExpandableSection
            expanded={true}
            title={'Timing'}
            component={
                <>
                    <Grid container spacing={1} pb={0.5}>
                        <Grid item>
                            <TimingInput
                                time={
                                    selectedAnimations.length < 2
                                        ? +animation.duration
                                        : +getSelectedAnimationsDuration()
                                }
                                name="duration"
                                label={t('editor:timing.duration')}
                                unit={inputAdornmentUnit}
                                handleOnChange={handleDuration}
                            />
                        </Grid>
                        <Grid item>
                            <TimingOffsetInput
                                time={0}
                                name="relative-duration"
                                label={t('editor:timing.relative-duration')}
                                unit={inputAdornmentUnit}
                                handleOnChange={handleRelativeDuration}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item>
                            <TimingInput
                                time={
                                    selectedAnimations.length < 2
                                        ? +animation.delay
                                        : +getSelectedAnimationsDelay()
                                }
                                name="start-offset"
                                label={t('editor:timing.start-offset')}
                                unit={inputAdornmentUnit}
                                handleOnChange={handleStartOffset}
                            />
                        </Grid>
                        <Grid item>
                            <TimingOffsetInput
                                time={0}
                                name="relative-start-offset"
                                label={t('editor:timing.relative-start-offset')}
                                unit={inputAdornmentUnit}
                                handleOnChange={handleRelativeStartOffset}
                            />
                        </Grid>
                    </Grid>
                </>
            }
        />
    )
}

export default Timing
