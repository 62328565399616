import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'

export const updateObjectsStylesBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<{ objects: AnySceneObjectT[] }>
) => {
    const modifiedObjects = action.payload.objects
    state.value = modifiedObjects.reduce((newState, object) => {
        return {
            ...newState,
            [object.id]: {
                ...state.value[object.id],
                styles: object.styles,
            },
        }
    }, state.value)
    state.updatedAt = Date.now()
}
