import JSONfn from 'json-fn'
import JSZip from 'jszip'
import { GSAPlib } from '../data/libs'
import { renderStyles } from '../data/styles/styles'
import { AssetT } from '../store/slices/assets.slice'
import { Timeline } from '../store/slices/editor.slice'
import { GraphicT } from '../store/slices/graphic.slice'
import { FileResponse, get, getFile } from './ajax.helpers'
import { createJSONparams, getFilePath, getFontPath } from './file.helpers'
import { initMasterTimelineForDownload, masterTimelineScripts } from './masterTimeline.helpers'
import { getImageSrc, processAnimations, processObjects, sortObjects } from './object.helpers'
import { generalScripts } from './scripts.helpers'
import { replaceSpacesWithDashes } from './string.helpers'

const NEW_LINE = '\n'

export class FileProcessor {
    private inlineCSS: string

    constructor(
        private allObjects: AnySceneObjectT[],
        private animations: AnimationI[],
        private assets: AssetT[],
        private settingsTimeline: Timeline,
        private timeline: TimelineI,
        private canvas: CanvasI,
        private graphic: GraphicT
    ) {
        this.inlineCSS = `* { box-sizing:border-box; margin:0; padding:0; } `
    }

    // Transform object to HTML div according to the object's type - item or text
    private ObjectToHTML = (object: AnySceneObjectT, assets: FileResponse[]) => {
        let result = ''
        const styles = renderStyles(object, 'css')

        if (object.type === 'item' || object.type === 'ticker') {
            result +=
                "<div class='object-container " +
                object.id +
                ' ' +
                replaceSpacesWithDashes(object.title) +
                "' id='" +
                object.id +
                "' style=' "
            this.inlineCSS += '#' + object.id + ' {' + styles + '} '
            result += ";'>" + NEW_LINE
            result += object.childIds
                .map((childId) => {
                    return this.ObjectToHTML(
                        this.allObjects.find((object) => object.id === childId)!,
                        assets
                    )
                })
                .join('')
            result += '</div>' + NEW_LINE
        } else if (object.type === 'text') {
            result +=
                "<div class='object-text-container " +
                object.id +
                ' ' +
                replaceSpacesWithDashes(object.title) +
                " ' id='" +
                object.id +
                "' style=' "
            this.inlineCSS += '#' + object.id + ' {' + styles + '} '
            result += ";'>" + NEW_LINE
            result += (object as TextI).text
            result += (object as TextI).childIds
                .map((childId) => {
                    return this.ObjectToHTML(
                        this.allObjects.find((object) => object.id === childId)!,
                        assets
                    )
                })
                .join('')
            result += '</div>' + NEW_LINE
        }
        if (object.type === 'image' || object.type === 'sequence') {
            result += `
        <img src="${getImageSrc(object, assets)}" class="object-image-container ${
                object.id
            } ${replaceSpacesWithDashes(object.title)}" id="${object.id}" style="${styles}">
                ${NEW_LINE}
                ${object.childIds
                    .map((childId) => {
                        return this.ObjectToHTML(
                            this.allObjects.find((object) => object.id === childId)!,
                            assets
                        )
                    })
                    .join('')}
            </img>${NEW_LINE}`
        }
        return result
    }

    // Body of final HTML graphics as a string
    private createHTMLbody = (animations: AnimationI[], assets: FileResponse[]): string => {
        const objects = sortObjects([...this.allObjects]).filter(
            (object) => object.parentId === null
        )

        const objectsAsDivs = objects
            .map((object) => {
                return this.ObjectToHTML(object, assets)
            })
            .join('') // join removes commas between mapped objects
        return (
            "<div class='canvas' id='canvas' style=' " +
            'width: ' +
            this.canvas.width +
            'px; ' +
            'height: ' +
            this.canvas.height +
            'px; ' +
            'position: relative;' +
            'overflow: hidden;' +
            'visibility: ' +
            (animations.length === 0 ? 'visible;' : 'hidden;') +
            " '>" +
            NEW_LINE +
            objectsAsDivs +
            '</div>' +
            NEW_LINE
        )
    }

    // HTML graphics as a string
    processFile = (basePath: string = window.location.origin, files: FileResponse[] = []) => {
        const processedObjects = processObjects(this.allObjects, files)
        const processedAnimations = processAnimations(this.animations, files)

        const videoPanelObject = this.allObjects.find(
            (object) => object.type === 'video-panel'
        ) as VideoPanel
        const videoPanelAnimations = Object.values(
            videoPanelObject?.animations ?? {}
        ) as AnimationI[]

        const HTMLbody: string = this.createHTMLbody(processedAnimations, files)

        let finalHTML: string = ''
        finalHTML += '<!DOCTYPE html>' + NEW_LINE
        // add HTML head with graphic params
        finalHTML += '<head>' + NEW_LINE
        finalHTML += "<meta version='" + process.env.REACT_APP_VERSION + "'>" + NEW_LINE
        finalHTML += "<meta charset='UTF-8'>" + NEW_LINE
        finalHTML +=
            "<meta property='JSONparams' content='" +
            createJSONparams(processedObjects, 'meta') +
            "'>" +
            NEW_LINE
        finalHTML +=
            "<meta property='canvas' content='" + JSONfn.stringify(this.canvas) + "'>" + NEW_LINE
        finalHTML +=
            `<meta property='assets' content='${JSONfn.stringify(this.assets)}'>` + NEW_LINE
        finalHTML +=
            "<meta property='objects' content='" +
            JSONfn.stringify(processedObjects) +
            "'>" +
            NEW_LINE
        finalHTML +=
            "<meta property='metadata' content='" +
            JSONfn.stringify({ graphicId: this.graphic._id, fileId: this.graphic.fileId }) +
            "'>" +
            NEW_LINE
        // finalHTML += "<meta property='animations' content='" + JSONfn.stringify(removeTargetsFromTweens(animations)) + "'>"
        finalHTML +=
            processedAnimations.length > 0
                ? "<meta property='animations' content='" +
                  JSONfn.stringify(processedAnimations) +
                  "'>" +
                  NEW_LINE
                : ''
        finalHTML +=
            "<meta property='timeline' content='" +
            JSONfn.stringify(this.timeline) +
            "'>" +
            NEW_LINE

        finalHTML += '<style>' + NEW_LINE
        this.assets
            .filter((asset) => asset.type === 'font')
            .forEach((asset) => {
                const url = basePath === '.' ? getFontPath(asset) : asset.url
                finalHTML += `
                @font-face {
                    font-family: ${asset.name};
                    src: url(${url});
                }
            `
            })

        finalHTML += this.inlineCSS + NEW_LINE
        finalHTML += '</style>' + NEW_LINE

        finalHTML += '</head>' + NEW_LINE
        // add HTML body
        finalHTML +=
            processedAnimations.length > 0 ? "<body onload='initTimeline()'>" : '<body>' + NEW_LINE
        // add HTML objects
        finalHTML += HTMLbody + NEW_LINE
        // add GSAP
        finalHTML +=
            processedAnimations.length > 0
                ? '<script>' + NEW_LINE + GSAPlib + NEW_LINE + '</script>' + NEW_LINE
                : '' + NEW_LINE
        finalHTML += `<script src="${basePath}/scripts/tweenly.umd.cjs"></script>` + NEW_LINE
        finalHTML += `<script src="${basePath}/scripts/sc.umd.cjs"></script>` + NEW_LINE
        // add GSAP timeline
        finalHTML +=
            processedAnimations.length > 0
                ? '<script>' +
                  NEW_LINE +
                  initMasterTimelineForDownload(
                      this.timeline,
                      processedAnimations,
                      this.settingsTimeline,
                      processedObjects
                  ) +
                  NEW_LINE +
                  '</script>' +
                  NEW_LINE
                : ''
        // add custom scripts
        finalHTML +=
            '<script>' +
            NEW_LINE +
            generalScripts(processedObjects, this.assets, videoPanelAnimations, this.canvas) +
            NEW_LINE +
            '</script>' +
            NEW_LINE
        finalHTML +=
            this.animations.length > 0
                ? '<script>' +
                  NEW_LINE +
                  masterTimelineScripts() +
                  NEW_LINE +
                  '</script>' +
                  NEW_LINE
                : ''
        finalHTML += '</body>' + NEW_LINE + '</html>' + NEW_LINE
        return finalHTML
    }

    processZip = async (loadingCallback: (value: number) => void) => {
        const zip = new JSZip()
        let basePath = window.location.origin
        const libResponse = await fetch(`${basePath}/scripts/tweenly.umd.cjs`)
        if (libResponse.ok) {
            zip.file('scripts/tweenly.umd.cjs', await libResponse.blob())
        }
        const scLibResponse = await fetch(`${basePath}/scripts/sc.umd.cjs`)
        if (scLibResponse.ok) {
            zip.file('scripts/sc.umd.cjs', await scLibResponse.blob())
        }
        basePath = '.'

        let files: FileResponse[] = []

        const images = this.allObjects.filter(
            (obj) => obj.type === 'image' || obj.type === 'sequence'
        )

        if (images.length) {
            files = await get('files', {
                key: `/(images|sequences)/(${images.map((image) => image.id).join('|')})`,
            })

            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                const path = getFilePath(file)
                if (path) {
                    try {
                        const data = await getFile(file._id)
                        zip.file(path, data)
                    } catch (error) {
                        console.error(`File ${file._id} not found.`)
                    }
                }
                loadingCallback(Number(((i / files.length) * 100) / 2))
            }
        }

        for (const asset of this.assets.filter((asset) => asset.type === 'font')) {
            const path = getFontPath(asset)
            try {
                const data = await getFile(asset.slug)
                zip.file(path, data)
            } catch (error) {
                console.error(`File ${asset.slug} not found.`)
            }
        }

        let content = this.processFile(basePath, files)
        zip.file('index.html', content)

        //updateCallback(metadata) is called for set perceptual Loading when generate ZIP file
        return await zip.generateAsync({ type: 'blob' }, function updateCallback(metadata) {
            loadingCallback(Number(100 / 2 + metadata.percent / 2))
        })
    }
}
