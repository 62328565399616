import { useHotkeys } from 'react-hotkeys-hook'
import { useNavigate } from 'react-router-dom'
import { selectCanRedo, selectCanUndo, selectUndoable } from '../helpers/selector.helpers'
import {
    clearAndReset,
    prevSaveFile,
    redo,
    setIsTimelineSettingChanged,
    setOpenCanvasSettings,
    setOpenDeleteAlert,
    setOpenDialogToClearScene,
    setOpenDialogToDuplicate,
    setOpenSaveForm,
    setOpenVersionHistoryDetail,
    setSignSaveAs,
    undo,
} from '../store/slices/editor.slice'
import { updateGraphicAsync } from '../store/slices/graphic.slice'
import { AssetRef } from '../store/slices/graphics.slice'
import { selectObjectById } from '../store/slices/objects.slice'
import { getSimilarGraphicAsync } from '../store/slices/similarGraphics.slice'
import useMultiselectObjectAction from './editor/useMultiselectObjectAction'
import { useRefreshAllStyles } from './editor/useRefreshAllStyles'
import useHTMLFileContent from './useHTMLFileContent'
import { useObjectActions } from './useObjectActions'
import { useAppDispatch, useAppSelector } from './useRedux'

//[NOTE]: MUST BE used only once in Editor,
//every usage in app invoke action with shortcuts one more time
export const useShortcuts = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { refreshAllStyles } = useRefreshAllStyles()

    const canUndo = useAppSelector(selectCanUndo)
    const canRedo = useAppSelector(selectCanRedo)
    const graphic = useAppSelector((state) => state.graphic)
    const assets = useAppSelector((state) => state.assets.data)
    const objects = useAppSelector((state) => selectUndoable(state).objects)
    const selectedObjectsIds = useAppSelector((state) => state.activeObject.selected)

    const { processFile } = useHTMLFileContent()
    const { moveObjectUp, moveObjectDown } = useObjectActions()
    const { handleDeleteObjects, handleDuplicateObjects } = useMultiselectObjectAction()

    //Undo
    useHotkeys(
        `ctrl+z, meta+z`,
        () => {
            if (canUndo) {
                dispatch(undo(refreshAllStyles))
            }
        },
        [canUndo]
    )
    //Redo
    useHotkeys(
        `ctrl+shift+z, meta+shift+z`,
        () => {
            if (canRedo) {
                dispatch(redo(refreshAllStyles))
            }
        },
        [canRedo]
    )

    //[TO DO]: how to duplicate only selected animation, when select animations automatically select object of animation
    //Duplicate selected object
    useHotkeys(
        `ctrl+d, meta+d`,
        () => {
            handleDuplicateObjects()
        },
        { preventDefault: true }
    )

    //Save graphic
    useHotkeys(
        `ctrl+s, meta+s`,
        () => {
            const file = processFile()
            if (!graphic.data._id) {
                dispatch(prevSaveFile(file))
                dispatch(setOpenSaveForm(true))
            } else {
                let assetsRef: AssetRef[] = assets.map((asset) => ({ id: asset._id }))
                dispatch(prevSaveFile(file))
                dispatch(updateGraphicAsync({ ...graphic.data, assetsRef }, file, false))
                dispatch(setIsTimelineSettingChanged(false))
            }
        },
        { preventDefault: true }
    )

    //[TO DO]: how to delete only selected animation, when select animations automatically select object of animation
    // Delete selected object
    useHotkeys(
        `ctrl+del, ctrl+delete, ctrl+backspace,
         meta+del, meta+delete, meta+backspace`,
        () => {
            handleDeleteObjects()
        },
        { preventDefault: true }
    )

    // Move object up
    useHotkeys(
        `ctrl+shift+up, meta+shift+up`,
        () => {
            if (selectedObjectsIds.length === 1) {
                const object = selectObjectById(objects, selectedObjectsIds[0])
                moveObjectUp(object)
            }
        },
        { preventDefault: true }
    )

    // Move object down
    useHotkeys(
        `ctrl+shift+down, meta+shift+down`,
        () => {
            if (selectedObjectsIds.length === 1) {
                const object = selectObjectById(objects, selectedObjectsIds[0])
                moveObjectDown(object)
            }
        },
        { preventDefault: true }
    )

    // New Graphic
    useHotkeys(
        `ctrl+alt+n, meta+alt+n`,
        () => {
            if (canUndo) {
                dispatch(setOpenDialogToClearScene(true))
            } else {
                dispatch(setOpenSaveForm(false))
                dispatch(setOpenVersionHistoryDetail(false))
                dispatch(clearAndReset(graphic.data._id ?? graphic.data.tmpId))
                navigate('/app/editor')
                dispatch(setOpenDialogToClearScene(false))
                dispatch(setOpenCanvasSettings(true))
            }
        },
        { preventDefault: true }
    )

    // Duplicate Graphic
    useHotkeys(
        `ctrl+shift+d, meta+shift+d`,
        () => {
            if (graphic.data._id) {
                if (canUndo) {
                    dispatch(setOpenDialogToDuplicate(true))
                }
                const file = processFile()
                dispatch(prevSaveFile(file))
                dispatch(setOpenSaveForm(true))

                const companyId = graphic.data.companyId
                const regex = '^' + graphic.data.name.replace(/(\()(\d+)(\))$/, () => '')

                dispatch(getSimilarGraphicAsync(companyId, regex))
                dispatch(setOpenVersionHistoryDetail(false))
                dispatch(setSignSaveAs(true))
            }
        },
        { preventDefault: true }
    )

    // Delete Graphic
    useHotkeys(
        `ctrl+shift+del, ctrl+shift+delete, ctrl+shift+backspace,
         meta+shift+del, meta+shift+delete, meta+shift+backspace`,
        () => {
            dispatch(setOpenDeleteAlert(true))
        },
        { preventDefault: true }
    )
}
