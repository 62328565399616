import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Step,
    StepLabel,
    Stepper,
    useTheme,
} from '@mui/material'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as tweenly from 'tweenly'
import * as yup from 'yup'
import { createRefreshLabel } from '../../../helpers/creators.helpers'
import { useEditorSettings } from '../../../hooks/useEditorSettings'
import useFramerate from '../../../hooks/useFramerate'
import { useAppDispatch } from '../../../hooks/useRedux'
import { useReduxForm } from '../../../hooks/useReduxForm'
import { useTimelineActions } from '../../../hooks/useTimelineActions'
import useValidationDataSource from '../../../hooks/useValidationDataSource'
import { AssetT, createAssetAsync, updateAssetAsync } from '../../../store/slices/assets.slice'
import { Transition } from '../../common/Transition'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import ThirdStep from './ThirdStep'
import './yupExtensions' // Import the yup extensions

interface Props {
    selectedAsset?: AssetT
    open: boolean
    onClose: () => void
}

export type AttributeT = {
    name?: string
    path?: string
}

type FormData = AssetT & {
    refresh: boolean
    refreshAt: number
    JSONdata?: string
    rawJSON?: string
}

const DEFAULT_VALUES = {
    name: '',
    slug: '',
    url: '',
    type: 'json',
    refresh: false,
    refreshAt: 0,
    JSONdata: '',
    rawJSON: '',
    attributes: [],
}

const DataSourceDialog = ({ selectedAsset, open, onClose }: Props) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const { debounceVerify } = useValidationDataSource()

    // Define the types for the validation schema
    const validationSchema = [
        yup.object({
            name: yup.string().required(),
            slug: yup
                .string()
                .required()
                .test(
                    'custom-slug-validation',
                    t('dataSource:formErrorMsg.notUniqueSlug') as string,
                    (value) =>
                        new Promise((resolve) => debounceVerify(selectedAsset, value, resolve))
                ),
            url: yup.string().url().required(),
            type: yup.string().required(),
            refresh: yup.boolean(),
            refreshAt: yup
                .number()
                .transform((curr, orig) => (orig === '' ? -1 : curr))
                .when('refresh', {
                    is: true,
                    then: yup
                        .number()
                        .min(0, 'dataSource:refreshAtValidationMin')
                        .required('dataSource:refreshAtValidationRequired'),
                }),
        }),
        yup.object({
            JSONdata: yup.string().required(),
        }),
        yup.object({
            attributes: yup
                .array()
                .of(
                    yup.object().shape({
                        path: yup.string().required(),
                        name: yup.string().required(),
                    })
                )
                .unique(t('dataSource:formErrorMsg.uniqueAttributeName'), (a: any) => a.name),
        }),
    ]
    const steps = [
        t('dataSource:formSteps.step0'),
        t('dataSource:formSteps.step1'),
        t('dataSource:formSteps.step2'),
    ]
    const [activeStep, setActiveStep] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const currentValidationSchema = validationSchema[activeStep]
    const [error, setError] = useState<boolean>(false)
    const { setFramerateValue, resultTimeFramerate } = useFramerate()

    const { refreshLabels, upsertTimelineLabel, delTimelineLabel } = useTimelineActions()
    const dataSourceData = useMemo(() => {
        let refresh = false
        let refreshAt = 0
        if (selectedAsset) {
            const refreshLabel = refreshLabels.find((lbl) => lbl.title === selectedAsset.slug)
            if (refreshLabel) {
                refreshAt = resultTimeFramerate(refreshLabel.time)
                refresh = true
            }
        }
        return { ...DEFAULT_VALUES, ...selectedAsset, refresh, refreshAt }
    }, [selectedAsset, refreshLabels, resultTimeFramerate])

    const disableFormInput = selectedAsset && selectedAsset._id ? true : false

    const handleNext = async () => {
        const isStepValid = await trigger()
        if (isStepValid && url && activeStep === 0) {
            setJSONData()
        }
        if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
    const handleCancel = () => {
        setActiveStep(0)
        reset(DEFAULT_VALUES)
        onClose()
    }

    const { companyId } = useEditorSettings()

    const onSubmitDataSource = async (data: FormData) => {
        if (selectedAsset && selectedAsset._id) {
            dispatch(
                updateAssetAsync(selectedAsset._id, {
                    ...selectedAsset,
                    attributes: data.attributes,
                    name: data.name,
                })
            )
        } else {
            const asset = {
                ...data,
                rawJSON: undefined,
                JSONdata: undefined,
            }
            dispatch(createAssetAsync(asset, companyId))
        }

        if (data.refresh) {
            upsertTimelineLabel(createRefreshLabel(setFramerateValue(data.refreshAt), data.slug))
        } else {
            delTimelineLabel(data.slug)
        }

        setActiveStep(0)
        reset(DEFAULT_VALUES)
        onClose()
    }

    const {
        control,
        setValue,
        handleSubmit,
        trigger,
        reset,
        formState: { errors, isSubmitting, isValid },
    } = useReduxForm({
        schema: currentValidationSchema,
        defaultValues: dataSourceData,
    })
    const type = useWatch({
        control,
        name: 'type',
    })
    const url = useWatch({
        control,
        name: 'url',
    })

    const rawJSON = useWatch({
        control,
        name: 'rawJSON',
    })

    const setJSONData = async () => {
        setLoading(true)
        const { data } = await tweenly.fetchAsset(url, type)
        setLoading(false)
        if (data === undefined || _.isEmpty(data)) {
            setError(true)
            setValue('JSONdata', '')
            setValue('rawJSON', '')
        } else {
            setError(false)
            setValue('JSONdata', JSON.stringify(data, undefined, 2))
            setValue('rawJSON', data)
        }
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <FirstStep
                        control={control}
                        errors={errors}
                        disableFormInput={disableFormInput}
                    />
                )
            case 1:
                return (
                    <SecondStep control={control} loading={loading} error={error} errors={errors} />
                )
            case 2:
                return <ThirdStep control={control} errors={errors} rawJSON={rawJSON} />
            case 3:
            default:
                return 'Unknown step'
        }
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCancel}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth={'lg'}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmitDataSource)}
                noValidate
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    m: 3,
                }}
            >
                <DialogTitle component={'h4'} sx={{ display: 'flex', alignItems: 'center', pb: 2 }}>
                    <ElectricalServicesIcon fontSize="large" />
                    {selectedAsset?._id ? selectedAsset.name : t('dataSource:titleNew')}
                </DialogTitle>
                <DialogContent>
                    <Grid>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {}
                                const labelProps: {
                                    optional?: React.ReactNode
                                } = {}
                                return (
                                    <Step key={label} {...stepProps} sx={{ py: 2 }}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                        {getStepContent(activeStep)}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCancel} sx={{ px: 2 }} variant="text" color="inherit">
                        {t('disagree')}
                    </Button>
                    <Button
                        variant="text"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ px: 2 }}
                    >
                        {t('dataSource:button.back')}
                    </Button>
                    {activeStep === steps.length - 1 ? (
                        <Button
                            onClick={handleSubmit(onSubmitDataSource)}
                            disabled={isSubmitting || !isValid}
                            sx={{ px: 2 }}
                        >
                            {t('dataSource:button.finish')}
                        </Button>
                    ) : (
                        <Button
                            onClick={handleNext}
                            disabled={activeStep === 1 && error} //disabled when JSON did not read correctly
                            sx={{ px: 2 }}
                        >
                            {t('dataSource:button.next')}
                        </Button>
                    )}
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default DataSourceDialog
