import CloseIcon from '@mui/icons-material/Close'
import { Grid, IconButton } from '@mui/material'
import TextArea from '../../../common/PropertyTools/TextArea'
import SelectAttributes from '../../DataSource/SelectAttributes'

interface Props {
    key: string
    index: number
    textsCount: number
    object: TextI
    handleUpdateText: (objectId: string, property: string, value: string) => void
    handleDeleteText: (object: AnySceneObjectT) => void
    textObjects: AnySceneObjectT[]
}

const TickerTextRow = ({
    key,
    index,
    textsCount,
    object,
    handleUpdateText,
    handleDeleteText,
}: Props) => {
    const disableMoveUp = index === 0
    const disableMoveDown = index + 1 === textsCount
    const disableDelete = disableMoveUp && disableMoveDown

    const handleAppendAttribute = (attribute: string) => {
        handleUpdateText(
            object.id,
            'text',
            !object.text || object.text === ' ' ? attribute : object.text + ' ' + attribute
        )
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleUpdateText(object.id, e.target.name, e.target.value || ' ')
    }

    return (
        <Grid
            container
            justifyContent={'space-between'}
            direction={'row'}
            spacing={1}
            alignItems={'center'}
        >
            <Grid item md={9}>
                <TextArea
                    key={key}
                    fullWidth
                    rows={2}
                    label={'text'}
                    name="text"
                    value={object.text}
                    onChange={handleOnChange}
                />
            </Grid>
            <Grid item md={2}>
                <SelectAttributes handleChange={handleAppendAttribute} />
            </Grid>
            <Grid item md={1}>
                <IconButton
                    disabled={disableDelete}
                    aria-label="menu"
                    onClick={() => handleDeleteText(object)}
                    size="large"
                >
                    <CloseIcon color={disableDelete ? 'disabled' : 'error'} />
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default TickerTextRow
