import { MAX_INPUT_NUM } from "../../../constants"

const positiveUnitaryInput: StyleInputT = {
	type: "number",
	props: {
		inputProps: {
			min: 0,
			max: MAX_INPUT_NUM,
			step: 1,
		},
	},
}

export const width: SimpleStyleT = {
	property: "width",
	value: 200,
	unit: "px",
	input: positiveUnitaryInput,
	render: (value, unit) => ({ property: "width", value: value + unit }),
}

export const crawlTickerTextWidth: SimpleStyleT = {
    property: "width",
    value: "auto", 
    input: {
        type: "string",
	props: "",
    },
    render: (value) => ({ property: "width", value }),
}

export const height: SimpleStyleT = {
	property: "height",
	value: 200,
	unit: "px",
	input: positiveUnitaryInput,
	render: (value, unit) => ({ property: "height", value: value + unit }),
}
