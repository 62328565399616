import { Grid } from '@mui/material'
import { fillProperties } from '../../../../data/styles/groups/fill.styles'
import { useObjectGroupActions } from '../../../../hooks/useObjectGroupActions'
import AddButton from '../../../common/AddButton'
import ExpandableSection from '../../../common/ExpandableSection'
import GroupFields from '../../../common/PropertyTools/GroupFields/GroupFields'
import NumberInput from '../../../common/PropertyTools/NumberInput'

interface Props {
    object: ItemI | ImageI | TickerI
    handleSetStyleValue: (property: any, value: any) => void
    handleSetObjectValue: (property: any, value: any) => void
    handleSetAttributeValue: (property: any, value: any) => void
    expanded?: boolean
}

const Fill = ({
    object,
    handleSetStyleValue,
    handleSetObjectValue,
    handleSetAttributeValue,
    expanded = true,
}: Props) => {
    const { addObjectGroup, updateObjectGroups, deleteObjectGroup } = useObjectGroupActions()

    const handleAddGroup = (e: any) => {
        if (!e) e = window.event
        e.cancelBubble = true
        if (e.stopPropagation) e.stopPropagation()
        const updatedGroups = addObjectGroup('fill', object['fill'])
        handleSetObjectValue('fill', updatedGroups)
    }

    const handleSetGroup = (index: number, property: any, value: any) => {
        const updatedGroups = updateObjectGroups(object['fill'], index, property, value)
        handleSetObjectValue('fill', updatedGroups)
    }

    const handleDeleteGroup = (index: number) => {
        const updatedGroups = deleteObjectGroup(object['fill'], index)
        handleSetObjectValue('fill', updatedGroups)
    }

    const fill: GroupStyleI[] | undefined = object.fill
    const opacity: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'opacity'
    )

    const handleOnChange = (e: any) => handleSetStyleValue(e.target.name, e.target.value)

    if (!fill && !opacity) return <></>

    return (
        <ExpandableSection
            expanded={expanded}
            title="Color & background"
            secondaryActionComponent={
                <AddButton
                    text="fill"
                    handleAddObject={handleAddGroup}
                    disabled={fill.length >= 1 ? true : false}
                />
            }
            component={
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        {opacity && (
                            <Grid item>
                                <NumberInput
                                    styleProperty={opacity}
                                    handleOnChange={handleOnChange}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item>
                        {fill && fillProperties && (
                            <GroupFields
                                object={object}
                                groupStyles={fill}
                                properties={fillProperties}
                                handleSetGroupValue={handleSetGroup}
                                handleDeleteGroup={handleDeleteGroup}
                                handleSetStyleValue={handleSetStyleValue}
                                handleSetAttributeValue={handleSetAttributeValue}
                            />
                        )}
                    </Grid>
                </Grid>
            }
        />
    )
}

export default Fill
