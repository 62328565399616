import { PayloadAction } from '@reduxjs/toolkit'
import { getAbsoluteTopLeftPosition } from '../../../../helpers/object.helpers'
import { filterAndSortSiblings, ObjectsStateT } from '../../objects.slice'
import { checkIndexesBase } from './checkIndexesBase'

export const moveSelectedToRootBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<{ object: AnySceneObjectT }>
) => {
    const object = action.payload.object
    const prevParentId = object.parentId
    if (!object.parentId) return

    //delete selected object from parent childIds
    if (prevParentId) {
        state.value = {
            ...state.value,
            [prevParentId]: {
                ...state.value[prevParentId],
                childIds: state.value[prevParentId].childIds.filter((id) => id !== object.id),
            },
        }
    }
    const restStyles = object.styles.filter(
        (style) => style.property !== 'top' && style.property !== 'left'
    )
    const topStyle = object.styles.find((style) => style.property === 'top')
    const leftStyle = object.styles.find((style) => style.property === 'left')
    const absolutePositionOfObject = getAbsoluteTopLeftPosition(state, object)

    if (!topStyle || !leftStyle) return

    //set parentId to null, because now is object in root, and set absolute position
    state.value = {
        ...state.value,
        [object.id]: {
            ...state.value[object.id],
            parentId: null,
            styles: [
                ...restStyles,
                { ...topStyle, value: absolutePositionOfObject.top },
                { ...leftStyle, value: absolutePositionOfObject.left },
            ],
        },
    }

    //check indexes of root objects
    const rootSiblings = filterAndSortSiblings(Object.values(state.value), null)
    state.value = {
        ...state.value,
        [object.id]: {
            ...state.value[object.id],
            index: rootSiblings?.length,
        },
    }
    //check indexes of previous parent
    checkIndexesBase(state, prevParentId)
}
