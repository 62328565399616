import { PayloadAction } from '@reduxjs/toolkit'
import { defaultScrollTickerText } from '../../../../data/defaults/objects.types.defaults'
import { createObject } from '../../../../helpers/creators.helpers'
import { animationScrollTranslateY } from '../../../../helpers/subtimeline.helpers'
import { ObjectsStateT } from '../../objects.slice'
import { AddTextTicker } from '../object/objectTypes'

export const addTextScrollUpBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<AddTextTicker>
) => {
    const objects = Object.values(state.value)
    const maskObject = action.payload.maskObject as TickerI
    const SCROLL_TIME = maskObject.duration ? maskObject.duration : 2
    const SCROLL_PAUSE = maskObject.delay ? maskObject.delay : 5

    const lastTextObject = objects.find(
        (obj) => obj.id === maskObject.childIds[maskObject.childIds.length - 1]
    )
    if (lastTextObject) {
        const id: string = lastTextObject.id
        const div = document.getElementById(id)
        const offHeight = div?.offsetHeight
        const top: number = lastTextObject?.styles.find((style) => style.property === 'top')?.value
        const height: number = lastTextObject?.styles.find(
            (style) => style.property === 'height'
        )?.value

        let newTextObject = createObject(objects, defaultScrollTickerText, maskObject.id)
        newTextObject = { ...newTextObject, styles: lastTextObject.styles }

        const topStyle: SimpleStyleT | undefined = newTextObject.styles.find(
            (style) => style.property === 'top'
        )

        if (!topStyle) {
            console.error('No top style found in new text object')
            return
        }

        const restStyles: SimpleStyleT[] = newTextObject.styles.filter(
            (style) => style.property !== 'top'
        )
        const updatedStyles: SimpleStyleT[] = [
            ...restStyles,
            {
                ...topStyle,
                value: Number(top) + Number(offHeight),
            },
        ]

        const maskHeightStyle: SimpleStyleT | undefined = maskObject.styles.find(
            (style) => style.property === 'height'
        )

        if (!maskHeightStyle) {
            console.error('No maskHeight Style found in mask object')
            return
        }

        const restMaskStyles: SimpleStyleT[] = maskObject.styles.filter(
            (style) => style.property !== 'height'
        )
        const updatedMaskStyles: SimpleStyleT[] = [
            ...restMaskStyles,
            {
                ...maskHeightStyle,
                value: Number(maskHeightStyle.value) + Number(height),
            },
        ]

        const textObject = { ...newTextObject, styles: updatedStyles }
        const updatedMaskObject = { ...maskObject, styles: updatedMaskStyles }

        const numberOfChild = maskObject.childIds.length + 1
        const textListHeightPerc = 100 / numberOfChild

        const maskAnimations: AnimationI[] = []

        for (let i = 0; i < numberOfChild; i++) {
            const currentAnimation = animationScrollTranslateY(
                maskObject,
                SCROLL_TIME,
                i * SCROLL_TIME + i * SCROLL_PAUSE,
                -100 + (numberOfChild - i + 1) * textListHeightPerc,
                -100 + (numberOfChild - i) * textListHeightPerc
            )
            maskAnimations.push({
                ...currentAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUp',
            })

            const pauseAnimation = animationScrollTranslateY(
                maskObject,
                SCROLL_PAUSE,
                (i + 1) * SCROLL_TIME + i * SCROLL_PAUSE,
                -100 + (numberOfChild - i) * textListHeightPerc,
                -100 + (numberOfChild - i) * textListHeightPerc
            )
            maskAnimations.push({
                ...pauseAnimation,
                subTimeline: true,
                subTimelineType: 'scrollUp',
            })
        }

        const animations: Record<string, AnimationI> = {}

        maskAnimations.forEach((animation) => {
            animations[animation.id] = animation
        })

        state.value = {
            ...state.value,
            [maskObject.id]: {
                ...updatedMaskObject,
                childIds: [...updatedMaskObject.childIds, textObject.id],
                animations: animations,
            },
            [textObject.id]: { ...textObject, parentId: updatedMaskObject.id },
        }
        state.updatedAt = Date.now()
    }
}
