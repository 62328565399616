import { PayloadAction } from '@reduxjs/toolkit'
import { updateObjectStyle } from '../../../../helpers/object.helpers'
import { ObjectsStateT } from '../../objects.slice'

export type UpdateTickerTexts = {
    object: TickerI
    maskObject: TickerI
    textObjects: TextI[]
    property: any
    value: any
}
export const updateTickerTextsPropertyBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateTickerTexts>
) => {
    const property = action.payload.property
    const value = action.payload.value
    const textObjects = action.payload.textObjects

    // if exist updatedCrawlTextObjects then update this object with new font styles, except first object without modified leftStyle,
    // when updating only scrollTicker textObjects then update only font styles
    const actualStateValue = { ...state.value }
    textObjects.forEach((textObject: TextI) => {
        actualStateValue[textObject.id] = {
            ...state.value[textObject.id],
            styles:
                updateObjectStyle(textObject, property, value) ??
                state.value[textObject.id]?.styles,
        }
    })
    state.value = actualStateValue

    //initialization for calling updateMasterTimeline
    state.updatedAt = Date.now()
}
