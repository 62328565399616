import { renderGroupStyleValue } from '../../data/styles/groups/groups.styles'
import { renderSimpleStyleValue } from '../../data/styles/simple/simple.styles'
import { selectUndoable } from '../../helpers/selector.helpers'
import useDynamicRefs from '../useDynamicRefs'
import { useAppSelector } from '../useRedux'
import useWrapSelectedObjects from './useWrapSelectedObjects'

export const useRefreshAllStyles = () => {
    const [getRef] = useDynamicRefs<HTMLElement>()

    const objects = useAppSelector((state) => selectUndoable(state).objects.value)
    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)
    const { wrapperObject } = useWrapSelectedObjects()

    const refreshStyles = (object: AnySceneObjectT) => {
        Object.values(object.styles).forEach((style) => {
            const ref = getRef(object.id)
            if (ref && ref.current) {
                const styleProperty = style.property as keyof string
                if (selectedObjectIds.find((id) => id === object.id)) {
                    if (style.property === 'left') {
                        ref.current.style['left'] = `${style.value - wrapperObject.left}px`
                    } else if (style.property === 'top') {
                        ref.current.style['top'] = `${style.value - wrapperObject.top}px`
                    } else {
                        if (style.value !== ref.current.style[styleProperty as any]) {
                            ref.current.style[styleProperty as any] = renderSimpleStyleValue(
                                style,
                                'css'
                            )
                        }
                    }
                } else {
                    if (style.value !== ref.current.style[styleProperty as any]) {
                        ref.current.style[styleProperty as any] = renderSimpleStyleValue(
                            style,
                            'css'
                        )
                    }
                }
            }
        })

        Object.values(object.transforms).forEach((style) => {
            const ref = getRef(object.id)
            if (ref && ref.current) {
                const transformType = style.type as keyof GroupStyleTypeT
                if (style.value !== ref.current.style[transformType as any]) {
                    ref.current.style[transformType as any] = renderGroupStyleValue(style, 'css')
                }
            }
        })
    }
    const refreshAllStyles = () => {
        Object.values(objects).forEach((object: AnySceneObjectT) => refreshStyles(object))
    }
    return {
        refreshAllStyles,
    }
}
