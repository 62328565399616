import FormatAlignCenterRoundedIcon from '@mui/icons-material/FormatAlignCenterRounded'
import FormatAlignLeftRoundedIcon from '@mui/icons-material/FormatAlignLeftRounded'
import FormatAlignRightRoundedIcon from '@mui/icons-material/FormatAlignRightRounded'
import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded'
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded'
import FormatShapesRoundedIcon from '@mui/icons-material/FormatShapesRounded'
import FormatTextdirectionLToRRoundedIcon from '@mui/icons-material/FormatTextdirectionLToRRounded'
import FormatTextdirectionRToLRoundedIcon from '@mui/icons-material/FormatTextdirectionRToLRounded'
import VerticalAlignBottomRoundedIcon from '@mui/icons-material/VerticalAlignBottomRounded'
import VerticalAlignCenterRoundedIcon from '@mui/icons-material/VerticalAlignCenterRounded'
import VerticalAlignTopRoundedIcon from '@mui/icons-material/VerticalAlignTopRounded'
import WrapTextRoundedIcon from '@mui/icons-material/WrapTextRounded'

import { FormControlLabel, SelectChangeEvent, Switch, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { unionBy } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { lineHeight as lineHeightDefaultStyle } from '../../../../data/styles/simple/font.styles'
import { useCurrentCompanyStats } from '../../../../hooks/useCompanyStats'
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux'
import { setFontFamilyPreferencesAsync } from '../../../../store/slices/editor.slice'
import ExpandableSection from '../../../common/ExpandableSection'
import ButtonGroupInput from '../../../common/PropertyTools/ButtonGroupInput'
import ButtonSwitch from '../../../common/PropertyTools/ButtonSwitch'
import NumberInput from '../../../common/PropertyTools/NumberInput'
import SelectDividerInput from '../../../common/PropertyTools/SelectDividerInput'
import SelectInput from '../../../common/PropertyTools/SelectInput'
import TextArea from '../../../common/PropertyTools/TextArea'
import SelectAttributes from '../../DataSource/SelectAttributes'
import { LoadFontButton } from '../LoadFontButton'

interface Props {
    object: TextI
    handleSetValue: (property: any, value: any) => void
    handleSetAttributeValue: (property: any, value: any) => void
    handleSetStyleValue: (property: any, value: any) => void
}

const TextAndFont = ({
    object,
    handleSetValue,
    handleSetAttributeValue,
    handleSetStyleValue,
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const handleAppendAttribute = (attribute: string) => {
        handleSetValue(
            'text',
            !object.text || object.text === ' ' ? attribute : object.text + ' ' + attribute
        )
    }

    const preferencesFontFamily: string[] = useAppSelector(
        (state) => state.editor.value.settings.fontFamily
    )

    const { assets: companyAssets } = useCurrentCompanyStats()
    const assets = useAppSelector((state) => state.assets.data)

    const fontFamily: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'fontFamily'
    )
    const fontSize: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'fontSize'
    )
    const fontWeight: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'fontWeight'
    )
    const fontStyle: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'fontStyle'
    )
    const textAlign: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'textAlign'
    )
    const direction: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'direction'
    )
    const whiteSpace: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'whiteSpace'
    )
    const textTransform: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'textTransform'
    )
    const justifyContent: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'justifyContent'
    )
    let lineHeight: SimpleStyleT | undefined = object.styles.find(
        (style) => style.property === 'lineHeight'
    )
    if (!lineHeight) {
        lineHeight = lineHeightDefaultStyle
    }

    const fonts = useMemo(() => {
        return unionBy(
            assets.filter((asset) => asset.type === 'font'),
            companyAssets.filter((asset) => asset.type === 'font'),
            '_id'
        )
    }, [assets, companyAssets])

    useEffect(() => {
        fonts.forEach(async (font) => {
            const fontStyle = document.createElement('style')
            fontStyle.appendChild(
                document.createTextNode(`
                    @font-face {
                        font-family: ${font.name};
                        src: url(${font.url});
                    }
                `)
            )

            document.head.appendChild(fontStyle)
        })
    }, [fonts])

    if (
        !fontFamily &&
        !fontSize &&
        !fontWeight &&
        !fontStyle &&
        !textAlign &&
        !direction &&
        !whiteSpace &&
        !textTransform &&
        !justifyContent &&
        !lineHeight
    )
        return <></>

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        handleSetValue(e.target.name, e.target.value || ' ')

    const handleStyleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        handleSetStyleValue(e.target.name, e.target.value)

    const handleFontFamilyOnChange = (e: SelectChangeEvent<any>) => {
        handleSetStyleValue(fontFamily?.property, e.target.value)
        if ((fontFamily?.input as SelectInputT).props.includes(e.target.value)) {
            dispatch(setFontFamilyPreferencesAsync(e.target.value))
        }
    }

    const isTextEditable: boolean = object.attributes?.find(
        (attribute) => attribute.property === 'isTextEditable'
    )?.value

    const textSizing: AttributeT | undefined = object.attributes?.find(
        (attribute) => attribute.property === 'textSizing'
    )

    const handleChangeEditable = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        handleSetAttributeValue('isTextEditable', !isTextEditable)
    }

    return (
        <ExpandableSection
            expanded={true}
            title={'Text & font'}
            secondaryActionComponent={
                <Tooltip title={t('editor:textCanBeChanged')}>
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={isTextEditable}
                                name="isTextEditable"
                                onClick={handleChangeEditable}
                            />
                        }
                        label={<Typography variant="body2">{t('editor:editable')}</Typography>}
                    />
                </Tooltip>
            }
            component={
                <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs={10}>
                        <TextArea
                            fullWidth
                            label={'text'}
                            name="text"
                            value={object.text}
                            onChange={handleOnChange}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <SelectAttributes handleChange={handleAppendAttribute} />
                    </Grid>
                    <Grid item>
                        {fontFamily && (
                            <>
                                <SelectDividerInput
                                    value={fontFamily.value}
                                    label={fontFamily.input.label ?? fontFamily.property}
                                    styleProperty={(fontFamily.input as SelectInputT).props}
                                    stylePropertyBeforeDivider={[
                                        ...fonts.map((font) => font.name),
                                        ...preferencesFontFamily,
                                    ]}
                                    handleOnChange={handleFontFamilyOnChange}
                                />
                                <LoadFontButton
                                    onChange={(value) =>
                                        handleSetStyleValue(fontFamily?.property, value)
                                    }
                                />
                            </>
                        )}
                    </Grid>
                    <Grid item>
                        {fontSize && (
                            <NumberInput
                                styleProperty={fontSize}
                                handleOnChange={handleStyleOnChange}
                            />
                        )}
                        {lineHeight && (
                            <NumberInput
                                styleProperty={lineHeight}
                                handleOnChange={handleStyleOnChange}
                            />
                        )}
                        {textTransform && (
                            <SelectInput
                                styleProperty={textTransform}
                                handleOnChange={handleStyleOnChange}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        {fontWeight && (
                            <ButtonSwitch
                                selectedButton={{
                                    value: 'bold',
                                    tooltip: t('editor:textTools.fontWeight.bold'),
                                    icon: <FormatBoldRoundedIcon />,
                                }}
                                unselectedButton={{
                                    value: 'normal',
                                    tooltip: t('editor:textTools.fontWeight.normal'),
                                    icon: <FormatBoldRoundedIcon />,
                                }}
                                property={fontWeight.property}
                                value={fontWeight.value}
                                handleOnChange={handleSetStyleValue}
                            />
                        )}
                        {fontStyle && (
                            <ButtonSwitch
                                selectedButton={{
                                    value: 'italic',
                                    tooltip: t('editor:textTools.fontStyle.italic'),
                                    icon: <FormatItalicRoundedIcon />,
                                }}
                                unselectedButton={{
                                    value: 'normal',
                                    tooltip: t('editor:textTools.fontStyle.normal'),
                                    icon: <FormatItalicRoundedIcon />,
                                }}
                                property={fontStyle.property}
                                value={fontStyle.value}
                                handleOnChange={handleSetStyleValue}
                            />
                        )}
                        {textAlign && (
                            <ButtonGroupInput
                                buttons={[
                                    {
                                        value: 'left',
                                        tooltip: t('editor:textTools.textAlign.left'),
                                        icon: <FormatAlignLeftRoundedIcon />,
                                    },
                                    {
                                        value: 'center',
                                        tooltip: t('editor:textTools.textAlign.center'),
                                        icon: <FormatAlignCenterRoundedIcon />,
                                    },
                                    {
                                        value: 'right',
                                        tooltip: t('editor:textTools.textAlign.right'),
                                        icon: <FormatAlignRightRoundedIcon />,
                                    },
                                ]}
                                property={textAlign.property}
                                value={textAlign.value}
                                handleOnChange={handleSetStyleValue}
                            />
                        )}
                        {justifyContent && (
                            <ButtonGroupInput
                                buttons={[
                                    {
                                        value: 'flex-start',
                                        tooltip: t('editor:textTools.textAlign.top'),
                                        icon: <VerticalAlignTopRoundedIcon />,
                                    },
                                    {
                                        value: 'center',
                                        tooltip: t('editor:textTools.textAlign.center'),
                                        icon: <VerticalAlignCenterRoundedIcon />,
                                    },
                                    {
                                        value: 'flex-end',
                                        tooltip: t('editor:textTools.textAlign.bottom'),
                                        icon: <VerticalAlignBottomRoundedIcon />,
                                    },
                                ]}
                                property={justifyContent.property}
                                value={justifyContent.value}
                                handleOnChange={handleSetStyleValue}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        {textSizing && (
                            <ButtonSwitch
                                selectedButton={{
                                    value: 'fitting',
                                    tooltip: t('editor:textTools.textSizing.fitting'),
                                    icon: <FormatShapesRoundedIcon />,
                                }}
                                unselectedButton={{
                                    value: 'fixed',
                                    tooltip: t('editor:textTools.textSizing.fixed'),
                                    icon: <FormatShapesRoundedIcon />,
                                }}
                                property={textSizing.property}
                                value={textSizing.value}
                                handleOnChange={handleSetAttributeValue}
                            />
                        )}
                        {whiteSpace && (
                            <ButtonSwitch
                                selectedButton={{
                                    value: 'normal',
                                    tooltip: t('editor:textTools.whiteSpace.normal'),
                                    icon: <WrapTextRoundedIcon />,
                                }}
                                unselectedButton={{
                                    value: 'nowrap',
                                    tooltip: t('editor:textTools.whiteSpace.nowrap'),
                                    icon: <WrapTextRoundedIcon />,
                                }}
                                property={whiteSpace.property}
                                value={whiteSpace.value}
                                handleOnChange={handleSetStyleValue}
                            />
                        )}
                        {direction && (
                            <ButtonGroupInput
                                buttons={[
                                    {
                                        value: 'ltr',
                                        tooltip: t('editor:textTools.textDirection.ltr'),
                                        icon: <FormatTextdirectionLToRRoundedIcon />,
                                    },
                                    {
                                        value: 'rtl',
                                        tooltip: t('editor:textTools.textDirection.rtl'),
                                        icon: <FormatTextdirectionRToLRoundedIcon />,
                                    },
                                ]}
                                property={direction.property}
                                value={direction.value}
                                handleOnChange={handleSetStyleValue}
                            />
                        )}
                    </Grid>
                </Grid>
            }
        />
    )
}

export default TextAndFont
