import {
    rotateX,
    rotateY,
    rotateZ,
    scaleX,
    scaleY,
    translateX,
    translateY,
} from './groups/transform.styles'
import { imageSrc, opacity } from './simple/display.styles'
import { height, width } from './simple/size.styles'

export const sequence = (value: string): TweenT => ({
    name: 'sequence',
    type: 'set',
    from: [imageSrc(value)],
    to: [],
    isDynamic: false,
})

export const fadeIn: TweenT = {
    name: 'fadeIn',
    type: 'fromTo',
    from: [
        {
            ...opacity,
            value: 0,
        },
    ],
    to: [
        {
            ...opacity,
            value: 1,
        },
    ],
    isDynamic: false,
}

export const defaultTween: TweenT = fadeIn

export const fadeOut: TweenT = {
    name: 'fadeOut',
    type: 'fromTo',
    from: [
        {
            ...opacity,
            value: 1,
        },
    ],
    to: [
        {
            ...opacity,
            value: 0,
        },
    ],
    isDynamic: false,
}

export const zoomIn: TweenT = {
    name: 'zoomIn',
    type: 'fromTo',
    from: [
        {
            ...scaleX,
            value: 0,
        },
        {
            ...scaleY,
            value: 0,
        },
    ],
    to: [
        {
            ...scaleX,
            value: 1,
        },
        {
            ...scaleY,
            value: 1,
        },
    ],
    isDynamic: false,
}

export const zoomOut: TweenT = {
    name: 'zoomOut',
    type: 'fromTo',
    from: [
        {
            ...scaleX,
            value: 1,
        },
        {
            ...scaleY,
            value: 1,
        },
    ],
    to: [
        {
            ...scaleX,
            value: 0,
        },
        {
            ...scaleY,
            value: 0,
        },
    ],
    isDynamic: false,
}

export const rotateXRight: TweenT = {
    name: 'rotateXRight',
    type: 'fromTo',
    from: [
        {
            ...rotateX,
            value: 0,
        },
    ],
    to: [
        {
            ...rotateX,
            value: 360,
        },
    ],
    isDynamic: false,
}

export const rotateXLeft: TweenT = {
    name: 'rotateXLeft',
    type: 'fromTo',
    from: [
        {
            ...rotateX,
            value: 0,
        },
    ],
    to: [
        {
            ...rotateX,
            value: -360,
        },
    ],
    isDynamic: false,
}

export const rotateYRight: TweenT = {
    name: 'rotateYRight',
    type: 'fromTo',
    from: [
        {
            ...rotateY,
            value: 0,
        },
    ],
    to: [
        {
            ...rotateY,
            value: 360,
        },
    ],
    isDynamic: false,
}

export const rotateYLeft: TweenT = {
    name: 'rotateYLeft',
    type: 'fromTo',
    from: [
        {
            ...rotateY,
            value: 0,
        },
    ],
    to: [
        {
            ...rotateY,
            value: -360,
        },
    ],
    isDynamic: false,
}

export const rotateZRight: TweenT = {
    name: 'rotateZRight',
    type: 'fromTo',
    from: [
        {
            ...rotateZ,
            value: 0,
        },
    ],
    to: [
        {
            ...rotateZ,
            value: 360,
        },
    ],
    isDynamic: false,
}

export const rotateZLeft: TweenT = {
    name: 'rotateZLeft',
    type: 'fromTo',
    from: [
        {
            ...rotateZ,
            value: 0,
        },
    ],
    to: [
        {
            ...rotateZ,
            value: -360,
        },
    ],
    isDynamic: false,
}

export const slideFromLeft: TweenT = {
    name: 'slideFromLeft',
    type: 'fromTo',
    from: [
        {
            ...translateX,
            value: -100, // slideParams("outOfCanvasLeft")
        },
    ],
    to: [
        {
            ...translateX,
            value: 0,
        },
    ],
    isDynamic: true,
}

export const slideToLeft: TweenT = {
    name: 'slideToLeft',
    type: 'fromTo',
    from: [
        {
            ...translateX,
            value: 0,
        },
    ],
    to: [
        {
            ...translateX,
            value: -100,
        },
    ],
    isDynamic: true,
}

export const slideFromRight: TweenT = {
    name: 'slideFromRight',
    type: 'fromTo',
    from: [
        {
            ...translateX,
            value: 100, // slideParams("outOfCanvasRight")
        },
    ],
    to: [
        {
            ...translateX,
            value: 0,
        },
    ],
    isDynamic: true,
}

export const slideToRight: TweenT = {
    name: 'slideToRight',
    type: 'fromTo',
    from: [
        {
            ...translateX,
            value: 0,
        },
    ],
    to: [
        {
            ...translateX,
            value: 100,
        },
    ],
    isDynamic: true,
}

export const slideFromTop: TweenT = {
    name: 'slideFromTop',
    type: 'fromTo',
    from: [
        {
            ...translateY,
            value: -100,
        },
    ],
    to: [
        {
            ...translateY,
            value: 0,
        },
    ],
    isDynamic: true,
}

export const slideToTop: TweenT = {
    name: 'slideToTop',
    type: 'fromTo',
    from: [
        {
            ...translateY,
            value: 0,
        },
    ],
    to: [
        {
            ...translateY,
            value: -100,
        },
    ],
    isDynamic: true,
}

export const slideFromBottom: TweenT = {
    name: 'slideFromBottom',
    type: 'fromTo',
    from: [
        {
            ...translateY,
            value: 100,
        },
    ],
    to: [
        {
            ...translateY,
            value: 0,
        },
    ],
    isDynamic: true,
}

export const slideToBottom: TweenT = {
    name: 'slideToBottom',
    type: 'fromTo',
    from: [
        {
            ...translateY,
            value: 0,
        },
    ],
    to: [
        {
            ...translateY,
            value: 100,
        },
    ],
    isDynamic: true,
}

export const sizeIn: TweenT = {
    name: 'sizeIn',
    type: 'fromTo',
    from: [
        {
            ...width,
            value: 0,
        },
        {
            ...height,
            value: 0,
        },
    ],
    to: [
        {
            ...width,
            value: 200,
        },
        {
            ...height,
            value: 200,
        },
    ],
    isDynamic: false,
}

export const sizeOut: TweenT = {
    name: 'sizeOut',
    type: 'fromTo',
    from: [
        {
            ...width,
            value: 200,
        },
        {
            ...height,
            value: 200,
        },
    ],
    to: [
        {
            ...width,
            value: 0,
        },
        {
            ...height,
            value: 0,
        },
    ],
    isDynamic: false,
}

// const dynamicAnimationTweens = (object: ItemI, parent: ItemI | CanvasI): TweenT[] => {
// const slideParams = (
// 	param: "outOfCanvasLeft" | "outOfCanvasRight" | "outOfCanvasIop" | "outOfCanvasBottom"
// ) => {
// 	const objectLeft: number = Number(object.left) + Number(object.marginLeft)
// 	const ObjectIop: number = Number(object.top) + Number(object.marginTop)
// 	const objectRight: number = Number(parent.width) - objectLeft - Number(object.width)
// 	const objectBottom: number = Number(parent.height) - ObjectIop - Number(object.height)
// 	if (param === "outOfCanvasLeft") {
// 		const leftTranslatePercentage: number = roundTwoDecimals(
// 			(objectLeft / object.width) * 100
// 		)
// 		return "-" + (100 + leftTranslatePercentage) + "%"
// 	} else if (param === "outOfCanvasRight") {
// 		const rightTranslatePercentage: number = roundTwoDecimals(
// 			(objectRight / object.width) * 100
// 		)
// 		return 100 + rightTranslatePercentage + "%"
// 	} else if (param === "outOfCanvasIop") {
// 		const topTranslatePercentage: number = roundTwoDecimals(
// 			(ObjectIop / object.height) * 100
// 		)
// 		return "-" + (100 + topTranslatePercentage) + "%"
// 	} else if (param === "outOfCanvasBottom") {
// 		const bottomTranslatePercentage: number = roundTwoDecimals(
// 			(objectBottom / object.height) * 100
// 		)
// 		return 100 + bottomTranslatePercentage + "%"
// 	}
// }
