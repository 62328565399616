import { SelectChangeEvent } from '@mui/material'
import TextField from '@mui/material/TextField'
import { t } from 'i18next'
import { ChangeEvent } from 'react'
import { getFirstLetterUpper } from '../../../../helpers/string.helpers'
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux'
import { useTimelineActions } from '../../../../hooks/useTimelineActions'
import {
    EndState,
    InitState as InitStateEnum,
    setIsTimelineSettingChanged,
    setPreferencesAsync,
    Timeline,
} from '../../../../store/slices/editor.slice'
import SelectTimelineState from './SelectTimelineState'

interface Props {
    disabled: boolean
}

const InitState = ({ disabled }: Props) => {
    const dispatch = useAppDispatch()
    const timeline: TimelineI = useAppSelector((state) => state.timeline.value)
    const settingsTimeline = useAppSelector((state) => state.editor.value.settings.timeline)
    const { updateTimeline } = useTimelineActions()

    const handleUpdateTimeline = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updateTimeline(e.target.name, e.target.value)
    }

    const handleUpdateTimelinePreferences = (e: SelectChangeEvent<InitStateEnum | EndState>) => {
        const name = e.target.name as keyof Timeline
        if (settingsTimeline[name] !== e.target.value) {
            dispatch(setIsTimelineSettingChanged(true))
            dispatch(
                setPreferencesAsync('timeline', {
                    ...settingsTimeline,
                    [e.target.name]: e.target.value,
                })
            )
        }
    }

    return (
        <>
            <SelectTimelineState
                name="initState"
                labelTitle={t(
                    `editor:timeline.select${getFirstLetterUpper('initState')}.labelTitle`
                )}
                value={settingsTimeline.initState}
                onChange={handleUpdateTimelinePreferences}
                disabled={disabled}
                options={Object.values(InitStateEnum)}
                prefix={getFirstLetterUpper('initState')}
            />
            <SelectTimelineState
                name="endState"
                labelTitle={t(
                    `editor:timeline.select${getFirstLetterUpper('endState')}.labelTitle`
                )}
                value={settingsTimeline.endState}
                onChange={handleUpdateTimelinePreferences}
                disabled={disabled}
                options={Object.values(EndState)}
                prefix={getFirstLetterUpper('endState')}
            />
            <TextField
                type="number"
                label="repeats"
                name="repeat"
                value={timeline.repeat}
                onChange={handleUpdateTimeline}
                disabled={disabled}
                InputProps={{
                    inputProps: {
                        min: -1,
                        step: 1,
                    },
                }}
            />
        </>
    )
}
export default InitState
