import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
export type TextTicker = {
    parentId: string
    textObject: AnySceneObjectT
}
export const addTextTickerBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<TextTicker>
) => {
    const parentId = action.payload.parentId
    const textObject = action.payload.textObject

    state.value = {
        ...state.value,
        [parentId]: {
            ...state.value[parentId],
            childIds: [...state.value[parentId].childIds, textObject.id],
        },
        [textObject.id]: { ...textObject, parentId: parentId },
    }
    state.updatedAt = Date.now()
}
