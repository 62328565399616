import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'

export type UpdateTickerTextsAttribute = {
    textObjects: TextI[]
    property: any
    value: any
}
export const updateTickerTextsAttributeBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateTickerTextsAttribute>
) => {
    const property = action.payload.property
    const value = action.payload.value
    const textObjects = action.payload.textObjects

    const actualStateValue = { ...state.value }
    textObjects.forEach((textObject: TextI) => {
        const attribute: AttributeT | undefined = textObject.attributes.find(
            (style) => style.property === property
        )
        if (!attribute) return

        const restAttributes: AttributeT[] = textObject.attributes.filter(
            (style) => style.property !== property
        )
        const updatedAttributes: AttributeT[] = [
            ...restAttributes,
            {
                ...attribute,
                value: value,
            },
        ]
        actualStateValue[textObject.id] = {
            ...state.value[textObject.id],
            attributes: updatedAttributes,
        }
    })
    state.value = actualStateValue
}
