import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { selectCanRedo, selectCanUndo } from '../../../helpers/selector.helpers'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import { clear, setOpenDialogToClearScene } from '../../../store/slices/editor.slice'

interface Props {
    label: string
    onClick: () => void
}

function ClearScene({ label, onClick }: Props) {
    const dispatch = useAppDispatch()
    const canUndo = useAppSelector((state) => selectCanUndo(state))
    const canRedo = useAppSelector((state) => selectCanRedo(state))

    const handleClear = () => {
        onClick()
        if (canRedo || canUndo) {
            dispatch(setOpenDialogToClearScene(true))
        } else {
            dispatch(clear())
        }
    }

    return (
        <MenuItem key="clear" onClick={handleClear}>
            <ListItemIcon>
                <BackspaceRoundedIcon />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
        </MenuItem>
    )
}

export default ClearScene
