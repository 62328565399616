import { v4 as uuidv4 } from 'uuid'
import { imageSrc } from '../data/styles/simple/display.styles'
import { Timeline } from '../store/slices/editor.slice'
import { sumArray } from './array.helpers'
import { CRAWL_CONTINUES_DURATION, CRAWL_STAGGERED_DURATION } from './crawlTicker.helpers'
import { getElementWidth } from './html.helpers'
import { roundNumber } from './number.helpers'
import { EMPTY_IMAGE_SRC, processTween } from './object.helpers'

// ------------------------------------
// SERIALIZE ANIMATION
// ------------------------------------

const serializeAnimationStateForDownload = (animationStates: SimpleStyleT[]): string => {
    const numOfStates = animationStates.length
    let result: string = ''

    animationStates.forEach((animationState: SimpleStyleT, index: number) => {
        // Object.assign(result, { [animationState.property as string]: animationState.value })
        const keys = animationState.property.split('.')
        keys.forEach((key, index) => {
            if (index > 0) result += '{'
            result += key + ':'
            if (index === keys.length - 1)
                result += `'${animationState.value}${
                    animationState.unit === '%' ? animationState.unit : ''
                }'${'}'.repeat(index)}`
        })

        if (index < numOfStates - 1) result += ','
    })
    return result
}

const serializeAnimationEaseForDownload = (ease: EaseT): string => {
    let result: string = ''
    if (ease) {
        result = ease.easeStyle + '.' + ease.easeType
        const strength = ease.easeStrengthValues.find((s) => s.strength === ease.easeStrength)
        if (strength) result += strength.value
    }
    return result
}

// ------------------------------------
// INIT ANIMATION
// ------------------------------------

const initTweenForDownload = (animation: AnimationI, isPreview: boolean): string => {
    let gsapTween: string = ''

    if (animation?.tween.type === 'fromTo')
        gsapTween = `masterTimeline.fromTo("#${
            animation.objectId
        }", {${serializeAnimationStateForDownload(
            animation.tween.from
        )}}, {${serializeAnimationStateForDownload(animation.tween.to)}, duration:${
            animation.duration
        }, ease:"${serializeAnimationEaseForDownload(animation.ease)}"}, ${animation.delay});
            `
    else if (animation.tween.type === 'from')
        gsapTween = `masterTimeline.from("#${
            animation.objectId
        }", {${serializeAnimationStateForDownload(animation.tween.from)}, duration:${
            animation.duration
        }, ease:"${serializeAnimationEaseForDownload(animation.ease)}"}, ${animation.delay});
            `
    else if (animation.tween.type === 'to')
        gsapTween = `masterTimeline.to("#${
            animation.objectId
        }", {${serializeAnimationStateForDownload(animation.tween.to)}, duration:${
            animation.duration
        }, ease:"${serializeAnimationEaseForDownload(animation.ease)}"}, ${animation.delay});
            `
    else if (animation.tween.type === 'set') {
        let from = animation.tween.from
        if (animation.tween.name === 'sequence' && isPreview) {
            from = processTween(animation.tween.from)
        }

        gsapTween = `masterTimeline.set("#${
            animation.objectId
        }", {${serializeAnimationStateForDownload(from)}}, ${animation.delay});
            `
    }
    return gsapTween
}

// ------------------------------------
// INIT MASTER TIMELINE
// ------------------------------------

export const initMasterTimelineForDownload = (
    timeline: TimelineI,
    animations: AnimationI[],
    settingsTimeline: Timeline,
    objects: AnySceneObjectT[]
    // TO DO use timeline from editor.settings
): string => {
    const initializedMasterTimeline: string = `
        masterTimeline = new gsap.timeline({
            repeat: ${timeline.repeat},
        });
    `
    let masterTimelineOnInit: string = ''
    let masterTimelineOnEnd: string = ''
    if (objects.find((object) => object.type === 'ticker')) {
        objects.forEach((object) => {
            const parentObject = object.parentId
                ? objects.find((obj) => obj.id === object.parentId)
                : null

            if (object.type === 'ticker' || (object.parentId && parentObject?.type === 'ticker')) {
                masterTimelineOnInit =
                    masterTimelineOnInit +
                    `
                    masterTimeline.set("#${object.id}", { visibility: "${
                        object.isHidden ? 'hidden' : 'visible'
                    }" });
                    `
                masterTimelineOnEnd =
                    masterTimelineOnEnd +
                    `
                    masterTimeline.set("#${object.id}", { visibility: "${
                        object.isHidden ? 'hidden' : 'visible'
                    }" });
                    `
            } else {
                masterTimelineOnInit =
                    masterTimelineOnInit +
                    `
                    masterTimeline.set("#${object.id}", { visibility: "${
                        settingsTimeline.initState === 'hideAndPause' || object.isHidden
                            ? 'hidden'
                            : 'visible'
                    }" });
                    `
                masterTimelineOnEnd =
                    masterTimelineOnEnd +
                    `
                    masterTimeline.set("#${object.id}", { visibility: "${
                        settingsTimeline.endState === 'endAndClear' || object.isHidden
                            ? 'hidden'
                            : 'visible'
                    }" });
                    `
            }
        })
    } else {
        masterTimelineOnInit = `
            masterTimeline.set("#canvas", { visibility: "${
                settingsTimeline.initState === 'hideAndPause' ? 'hidden' : 'visible'
            }" });
        `

        // timeline.value.endState ???
        masterTimelineOnEnd = `
            masterTimeline.set("#canvas", { visibility: "${
                settingsTimeline.endState === 'endAndClear' ? 'hidden' : 'visible'
            }" });
        `
    }

    // init animations
    const initializedTweens = getInitializedTweens(animations, false)
    const createLabels: string =
        `
        window.timelineLabels = [` +
        timeline.labels
            ?.map((label) => {
                return (
                    '{' +
                    `title: '${label.title}',` +
                    `time: ${label.time},` +
                    `type: '${label.type}',` +
                    `jumpto: '${label.jumpto}'` +
                    '}'
                )
            })
            .join(',') +
        `];
    `

    const timelineOnUpdateCode: string = timeline.onUpdateCode
        ? `
    const timelineOnUpdate = (time, lasttime, duration) => {
        ${timeline.onUpdateCode.code.replaceAll('&apos', "'").replaceAll('\\"', '"')}
    }    
    `
        : `
    const timelineOnUpdate = (time, lasttime, duration) => {}
    `

    const labelsCode = (): string => {
        var result: string = ''

        timeline.labels.forEach((label) => {
            result += label.onLabelCode
                ? `
        const label_${label.title.replace(/\s+/g, '_')}_onLabel = () => {
            ${label.onLabelCode.code.replaceAll('&apos', "'").replaceAll('\\"', '"')}
        }
        `
                : `
        const label_${label.title.replace(/\s+/g, '_')}_onLabel = () => {
        }
        `
        })

        result += `const labelsOnLabel = (labeltitle) => {
        `

        timeline.labels.forEach((label) => {
            result += `if (labeltitle === '${label.title}') { label_${label.title.replace(
                /\s+/g,
                '_'
            )}_onLabel() }
        `
        })

        result += `}
        `

        return result
    }

    const initAnimationCode = (animation: AnimationI) => {
        let animationCode: string = ''

        if (animation.onInitCode?.code) {
            animationCode += `const animation_${animation.title.replace(
                /\s+/g,
                '_'
            )}_onInit = () => {
                ${animation.onInitCode.code.replaceAll('&apos', "'").replaceAll('\\"', '"')}
            }
            `
        }
        if (animation.onUpdateCode?.code) {
            animationCode += `const animation_${animation.title.replace(
                /\s+/g,
                '_'
            )}_onUpdate = (time, lasttime, duration) => {
                ${animation.onUpdateCode.code.replaceAll('&apos', "'").replaceAll('\\"', '"')}
            }
            `
        }
        return animationCode
    }

    const triggerAnimationCode = (animation: AnimationI) => {
        let animationCode: string = ''

        if (animation.onInitCode?.code) {
            animationCode += `if ((lasttime === 0 && ${
                animation.delay
            } <= time) || (lasttime > 0 && ${animation.delay} > lasttime && ${
                animation.delay
            } <= time)) animation_${animation.title.replace(/\s+/g, '_')}_onInit()
                `
        }
        if (animation.onUpdateCode?.code) {
            animationCode += `if (${animation.delay} <= time && time <= ${
                animation.delay + animation.duration
            }) animation_${animation.title.replace(/\s+/g, '_')}_onUpdate(time - ${
                animation.delay
            }, lasttime - ${animation.delay}, ${animation.duration})
                `
        }
        return animationCode
    }

    const animationsCode = (): string => {
        var result: string = ''

        result = animations.map((animation) => initAnimationCode(animation)).join('')

        result += `
            const triggerAnimationsCode = (time, lasttime) => {
                `
        result += animations.map((animation) => triggerAnimationCode(animation)).join('')

        result += `
            }
        `

        return result
    }

    const onUpdateTrigger: string = `
        let timelineTime = 0
        let lastTimelineTime = 0
        let jumpToTimelineTime = 0

        const updateTimeline = () => {
            lastTimelineTime = timelineTime
            timelineTime = masterTimeline.time()

            lastTimelineTime = (lastTimelineTime > timelineTime) ? jumpToTimelineTime : lastTimelineTime

            try {
                timelineOnUpdate(timelineTime, lastTimelineTime, masterTimeline.duration())
                const { shiftX, shiftY, scaleX, scaleY } = window.tweenly.getVideoPanelPreferences(timelineTime)
                window.sc.scaleVideo(scaleX, scaleY)
                window.sc.shiftVideo(shiftX, shiftY)
            }
            catch(error) {
                console.error(error)
            }

            if ((timelineTime >= lastTimelineTime)) {
                const labels = timelineLabels.filter(
                    (lbl) => {
                        return (lastTimelineTime > 0 && lbl.time > lastTimelineTime && lbl.time <= timelineTime) ||
                            (lastTimelineTime === 0 && lbl.time <= timelineTime)
                    })
                if ((labels !== undefined) && (labels.length > 0)) {
                    labels.forEach((label) => {
                        try {
                            labelsOnLabel(label.title)
                        }
                        catch(error) {}
                        
                        if (label.type === 'pause') {
                            pauseAnimation(label.time)
                        }
                        else if ((label.type === 'jump') && (lastTimelineTime < timelineTime))
                        {
                            const jumpToLabel = timelineLabels.find((lbl) => lbl.title === label.jumpto)
                            if (jumpToLabel) {
                                jumpToTimelineTime = jumpToLabel.time
                                seekAnimation(jumpToLabel.time)
                                try {
                                    labelsOnLabel(jumpToLabel.title)
                                }
                                catch(error) {}
                            }
                        }
                    })
                }
            }

            try {
                triggerAnimationsCode(timelineTime, lastTimelineTime)
            }
            catch(error) {}
        }

        masterTimeline.eventCallback('onUpdate', updateTimeline)
    `
    // prettier-ignore
    const timelineOnInitCode: string = timeline.onInitCode ? `const timelineOnInit = () => {${timeline.onInitCode.code.replaceAll("&apos", "'").replaceAll("\\\"", "\"")}}` : `const timelineOnInit = () => {}`

    return `
        window.masterTimeline = null;
        ${timelineOnInitCode}
        const initTimeline = () => {
            ${initializedMasterTimeline}
            ${masterTimelineOnInit}
            ${settingsTimeline.initState === 'initAndPlay' ? '' : 'masterTimeline.pause(0)'}
            ${initializedTweens}
            ${settingsTimeline.initState === 'initAndPlay' ? '' : 'masterTimeline.pause(0.000001)'}
            ${masterTimelineOnEnd}
            ${createLabels}
            ${timelineOnUpdateCode}
            ${labelsCode()}
            ${animationsCode()}
            ${onUpdateTrigger}
            try {timelineOnInit()} catch(error) {}
        };
    `
}

const getInitializedTweens = (animations: AnimationI[], isPreview: boolean) => {
    const initializedTweens: string[] = []
    animations.forEach((animation) => {
        if (animation.subTimeline) return

        const tween = initTweenForDownload(animation!, isPreview)
        initializedTweens.push(tween)

        if (animation.tween.name === 'sequence') {
            const nextDelay = roundNumber(animation.delay + animation.duration, 2)
            const nextAnimation = Object.values(animations).find(
                (a) =>
                    a &&
                    a.objectId === animation.objectId &&
                    roundNumber(a.delay, 2) === nextDelay &&
                    a.tween.name === 'sequence'
            )
            if (!nextAnimation) {
                const emptyTween = `masterTimeline.set("#${
                    animation.objectId
                }", {${serializeAnimationStateForDownload([
                    imageSrc(EMPTY_IMAGE_SRC),
                ])}}, ${nextDelay});
                `
                initializedTweens.push(emptyTween)
            }
        }
    })
    return initializedTweens.join(' ')
}

export const initMasterTimelineForThumbnailPreview = (
    animations: AnimationI[],
    objects: AnySceneObjectT[]
): string => {
    const initializedMasterTimeline: string = `
        const masterTimeline = new gsap.timeline({
            repeat: -1,
        });
    `

    const masterTimelineOnInit: string = `
        masterTimeline.set("#canvas", { visibility: "visible" });
    `

    const images: string[] = []
    animations
        .filter((animation) => animation.tween.name === 'sequence')
        .forEach((animation) => {
            processTween(animation.tween.from).forEach((f) => {
                if (f.property === 'attr.src') {
                    images.push(f.value)
                }
            })
        })
    const preload = `
        const images = ${JSON.stringify(images)};
        images.forEach(src => {
            const image = new Image()
            image.src = src
        });
    `
    let initializedTickerTimelines: string[] = []
    const groupedAnimations: { [key: string]: AnimationI[] } = animations
        .filter((animation) => animation.subTimeline === true)
        .reduce((acc, animation) => {
            if (!animation.parentId) {
                const { objectId } = animation
                if (!acc[objectId]) {
                    acc[objectId] = []
                }
                acc[objectId].push(animation)
            } else {
                const { parentId } = animation
                if (!acc[parentId]) {
                    acc[parentId] = []
                }
                acc[parentId].push(animation)
            }
            return acc
        }, {} as { [key: string]: AnimationI[] })

    // Iterate over each group and create subTimeline
    Object.keys(groupedAnimations).forEach((objectId, index) => {
        const animationGroup = groupedAnimations[objectId]

        const initializedTickerTimeline: string = `
                    const tickerTimeline${index} = gsap.timeline({
                        repeat: -1,
                    });
                    `
        initializedTickerTimelines.push(initializedTickerTimeline)

        animationGroup.forEach((animation) => {
            if (animation.subTimelineType?.startsWith('crawl')) {
                return
            }
            if (animation?.tween.type === 'fromTo')
                initializedTickerTimelines.push(`tickerTimeline${index}.fromTo("#${
                    animation.objectId
                }", {${serializeAnimationStateForDownload(
                    animation.tween.from
                )}}, {${serializeAnimationStateForDownload(animation.tween.to)}, duration:${
                    animation.duration
                }, ease:"${serializeAnimationEaseForDownload(animation.ease)}"}, ${
                    animation.delay
                });
                    `)
            else if (animation.tween.type === 'from')
                initializedTickerTimelines.push(`tickerTimeline${index}.from("#${
                    animation.objectId
                }", {${serializeAnimationStateForDownload(animation.tween.from)}, duration:${
                    animation.duration
                }, ease:"${serializeAnimationEaseForDownload(animation.ease)}"}, ${
                    animation.delay
                });
                    `)
            else if (animation.tween.type === 'to')
                initializedTickerTimelines.push(`tickerTimeline${index}.to("#${
                    animation.objectId
                }", {${serializeAnimationStateForDownload(animation.tween.to)}, duration:${
                    animation.duration
                }, ease:"${serializeAnimationEaseForDownload(animation.ease)}"}, ${
                    animation.delay
                });
                    `)
            else if (animation.tween.type === 'set') {
                let from = animation.tween.from

                initializedTickerTimelines.push(`tickerTimeline${index}.set("#${
                    animation.objectId
                }", {${serializeAnimationStateForDownload(from)}}, ${animation.delay});
                    `)
            }
        })

        if (animationGroup[0].subTimelineType === 'scrollDown') {
            const maskObject = objects.find((obj) => obj.id === objectId) as TickerI
            let SCROLL_TIME = maskObject.duration
            const textList1Node = document.getElementById(objectId)
            if (textList1Node) {
                const parent = textList1Node.parentNode
                if (parent) {
                    const textList2Node = textList1Node.cloneNode(true) as HTMLElement
                    textList2Node.id = 'i' + uuidv4()

                    parent.appendChild(textList2Node)
                    const cloneHeight = textList1Node.clientHeight
                    const cloneChildrenHeight = cloneHeight / textList1Node.children.length

                    //[NOTE]: using translateY with % not work, if use only number without unit then it work!
                    // move last to the top
                    initializedTickerTimelines.push(
                        `tickerTimeline${index}.fromTo("#${textList2Node.id}", {translateY:'0'}, {translateY:'${cloneChildrenHeight}', duration:${SCROLL_TIME}, ease:"none"}, 0);`
                    )
                    initializedTickerTimelines.push(
                        `tickerTimeline${index}.set("#${
                            textList2Node.id
                        }", {translateY:'${-cloneHeight}'},0);`
                    )
                }
            }
        } else if (animationGroup[0].subTimelineType === 'scrollUp') {
            const maskObject = objects.find((obj) => obj.id === objectId) as TickerI
            let SCROLL_TIME = maskObject.duration
            const textList1Node = document.getElementById(objectId)
            if (textList1Node) {
                const parent = textList1Node.parentNode
                if (parent) {
                    const textList2Node = textList1Node.cloneNode(true) as HTMLElement
                    textList2Node.id = 'i' + uuidv4()

                    parent.appendChild(textList2Node)
                    const cloneHeight = textList1Node.clientHeight
                    const cloneChildrenHeight = cloneHeight / textList1Node.children.length

                    //[NOTE]: using translateY with % not work, if use only number without unit then it work!
                    // move last to the top
                    initializedTickerTimelines.push(
                        `tickerTimeline${index}.fromTo("#${textList2Node.id}", {translateY:'${
                            -cloneHeight + cloneChildrenHeight
                        }'}, {translateY:'${-cloneHeight}', duration:${SCROLL_TIME}, ease:"none"}, 0);`
                    )
                    initializedTickerTimelines.push(
                        `tickerTimeline${index}.set("#${textList2Node.id}", {translateY:'${cloneChildrenHeight}'},0);`
                    )
                }
            }
        } else if (animationGroup[0].subTimelineType === 'crawlStaggered') {
            const maskObject = objects.find((obj) => obj.id === objectId) as TickerI
            let lastWidth: number = 0
            let newWidths: Record<string, number> = {}
            for (const childId of maskObject.childIds) {
                const element = document.getElementById(childId)
                if (element) {
                    newWidths[childId] = element.clientWidth
                }
            }
            const maskObjectElement = document.getElementById(maskObject.id)
            const maskWidth = maskObjectElement?.clientWidth ?? 0
            const textsWidth = sumArray(maskObject.childIds.map((x) => getElementWidth(x)))
            const duration: number =
                maskObject && maskObject?.duration ? maskObject?.duration : CRAWL_STAGGERED_DURATION
            const crawlSpeed = Number((maskWidth + textsWidth) / duration)

            const tickerObjectElement =
                maskObject.parentId && (document.getElementById(maskObject.parentId) as HTMLElement)
            if (tickerObjectElement) {
                initializedTickerTimelines.push(
                    `tickerTimeline${index}.fromTo("#${maskObject.id}",
                    {translateX: '${tickerObjectElement.clientWidth}px'},
                    {translateX: '-${textsWidth}px',
                    duration: ${crawlSpeed},
                    ease: "none"},
                    0);`
                )
                for (const childId of maskObject.childIds) {
                    if (lastWidth === 0) {
                        initializedTickerTimelines.push(
                            `tickerTimeline${index}.set("#${childId}",
                        {translateX: '0px'},
                        0);`
                        )
                        lastWidth = newWidths[childId]
                    } else {
                        initializedTickerTimelines.push(
                            `tickerTimeline${index}.set("#${childId}",
                        {translateX: '${lastWidth}px'},
                        0);`
                        )
                        lastWidth += newWidths[childId]
                    }
                }
            }
        } else if (animationGroup[0].subTimelineType === 'crawlContinuous') {
            const getElementWidth = (id: string) => {
                const element = document.getElementById(id)
                return element ? element.clientWidth : 0
            }
            const maskObject = objects.find((obj) => obj.id === objectId) as TickerI

            const parentObject = maskObject.parentId
                ? document.getElementById(maskObject.parentId)
                : null
            const parentObjectWidth = parentObject?.clientWidth ? parentObject?.clientWidth : 0
            const textList1Node = document.getElementById(objectId)
            const textsWidth = sumArray(maskObject.childIds.map((x) => getElementWidth(x))) //to do change to all inner texts
            let lastWidth: number = 0
            let newWidths: Record<string, number> = {}
            for (const childId of maskObject.childIds) {
                const element = document.getElementById(childId)
                if (element) {
                    newWidths[childId] = element.clientWidth
                }
            }
            const maskObjectDuration: number =
                maskObject && maskObject?.duration ? maskObject?.duration : CRAWL_CONTINUES_DURATION

            const crawlSpeed = Number(textsWidth / maskObjectDuration)
            const delay: number = crawlSpeed
            initializedTickerTimelines.push(
                `tickerTimeline${index}.fromTo("#${maskObject.id}",
                {translateX:'0px'},
                {translateX:'${-textsWidth}px',
                duration:${crawlSpeed},
                ease:"none"},
                0);`
            )

            if (textList1Node) {
                const parent = textList1Node.parentNode

                if (parent) {
                    const numberOfCopiesTextWrapper = Math.round(parentObjectWidth / textsWidth) + 1

                    for (let i = 0; i <= numberOfCopiesTextWrapper; i++) {
                        const textListNode = textList1Node.cloneNode(true) as HTMLElement
                        textListNode.id = objectId + '-copy-' + i
                        textListNode.className = `item-container-copy ${textListNode.id}`
                        parent.appendChild(textListNode)

                        initializedTickerTimelines.push(
                            `tickerTimeline${index}.fromTo("#${textListNode.id}",
                            {translateX:'${textsWidth * (i + 1)}px'},
                            {translateX:'${textsWidth * i}px',
                            duration:${crawlSpeed},
                            ease:"none"},
                            0);`
                        )

                        initializedTickerTimelines.push(
                            `tickerTimeline${index}.fromTo("#${textListNode.id}",
                            {translateX:'${textsWidth * i}px'},
                            {translateX:'${textsWidth * i - textsWidth}px',
                            duration:${crawlSpeed},
                            ease:"none"},
                            ${delay});`
                        )
                    }
                }
            }
            for (const childId of maskObject.childIds) {
                if (lastWidth === 0) {
                    initializedTickerTimelines.push(
                        `tickerTimeline${index}.set("#${childId}",
                        {translateX:'${0}px'},
                        0
                        );`
                    )
                    lastWidth = newWidths[childId]
                } else {
                    initializedTickerTimelines.push(
                        `tickerTimeline${index}.set("#${childId}",
                        {translateX:'${lastWidth}px'},
                        0
                        );`
                    )
                    lastWidth += newWidths[childId]
                }
            }
        }
    })
    const initTickerTimeline: string = initializedTickerTimelines.join(' ')

    const initializedTweens: string = getInitializedTweens(
        animations.filter((animation) => !animation.subTimeline),
        true
    )
    return (
        initTickerTimeline +
        initializedMasterTimeline +
        masterTimelineOnInit +
        initializedTweens +
        preload
    )
}

export const masterTimelineScripts = (): string => {
    return `
        const playAnimation = window.tweenly.playAnimation
        const pauseAnimation = window.tweenly.pauseAnimation
        const continueAnimation = window.tweenly.continueAnimation
        const seekAnimation = window.tweenly.seekAnimation
        const replayAnimation = window.tweenly.replayAnimation
        const seekAnimationLabel = window.tweenly.seekAnimationLabel
        const disableAnimationJumpLabels = window.tweenly.disableAnimationJumpLabels
        const disableAnimationPauseLabels = window.tweenly.disableAnimationPauseLabels
        const disableAnimationLabels = window.tweenly.disableAnimationLabels
    `
}
