import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import gsap from 'gsap'
import GSDevTools from 'gsap/GSDevTools'

gsap.registerPlugin(GSDevTools)

export type SubTimelineState = {
    value: Record<string, any> // Record<string, GSAPTimeline> ... not work because writeable
}
const initialState: SubTimelineState = {
    value: {},
}

export const subTimelinesSlice = createSlice({
    name: 'subTimelines',
    initialState,
    reducers: {
        initSubTimelineAction: (
            state,
            action: PayloadAction<{ id: string; timeline: GSAPTimeline }>
        ) => {
            const { id, timeline } = action.payload
            state.value[id]?.clear()
            state.value = {
                ...state.value,
                [id]: timeline,
            }
        },
        removeSubTimelineAction: (state, action: PayloadAction<{ id: string }>) => {
            delete state.value[action.payload.id]
        },
    },
})

export const { initSubTimelineAction, removeSubTimelineAction } = subTimelinesSlice.actions

export default subTimelinesSlice.reducer
