import _ from 'lodash'
import { useMemo } from 'react'
import { selectCanUndo } from '../helpers/selector.helpers'
import { useAppSelector } from './useRedux'

export const useCheckEditor = () => {
    const graphic = useAppSelector((state) => state.graphic)
    const assets = useAppSelector((state) => state.assets.data)
    const isTimelineSettingChanged = useAppSelector(
        (state) => state.editor.value.isTimelineSettingChanged
    )

    const { visibleSaveForm } = useAppSelector((state) => state.editor.value)
    const canUndo = useAppSelector((state) => selectCanUndo(state))

    const matchAssetsAndGraphicAssets = useMemo(() => {
        if (graphic.data.assetsRef) {
            return _.isEqual(
                graphic.data.assetsRef.map((ref) => ref.id),
                assets.map((asset) => asset._id)
            )
        } else {
            return false
        }
    }, [assets, graphic.data])

    const disabledShortSave = useMemo(() => {
        if (!matchAssetsAndGraphicAssets || isTimelineSettingChanged) {
            return false
        }
        return visibleSaveForm || !canUndo
    }, [canUndo, isTimelineSettingChanged, matchAssetsAndGraphicAssets, visibleSaveForm])

    return {
        matchAssetsAndGraphicAssets,
        disabledShortSave,
    }
}
