// pixels
export const OBJECT = {
    titleWidth: 120,
}

export const TIMELINE_ROW = {
    height: 30,
    borderRadius: 1,
    paddingBottom: 1,
    headingWidth: 195,
}

export const TIMELINE_TWEEN = {
    height: TIMELINE_ROW.height,
    borderRadius: 1,
    minWidth: 70,
}

export const calcTimelineRowWidth = (width: number, duration: number, zoom: number): number =>
    Math.max(
        (duration * TIMELINE_TWEEN.minWidth * zoom) / 100,
        (TIMELINE_TWEEN.minWidth * zoom) / 100
    )
